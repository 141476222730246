import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { CountryDropdown } from "react-country-region-selector";
import styled from "styled-components";

import { colors } from "../../../../utils/colors";
import { svg } from "../../../../utils/constants";

import "react-phone-input-2/lib/style.css";
import { ContinueButton } from "../../../../utils/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleToast } from "../../../../utils/toast/toast";
import { updateQuoteRoomIds } from "../../../../services/Reservation/hotelReservationSlice";

const PersonalInformation = ({
  setStepSelected,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  emailAddress,
  setEmailAddress,
  country,
  setCountry,
  phone,
  setPhone,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const quoteRooms = useSelector(
    (state) => state.hotelReservation.quoteRoomIds
  );

  const [rooms, setRooms] = useState(quoteRooms);

  const handleNamesChange = (roomIndex, detailIndex, i, newName) => {
    setRooms((prevRooms) => {
      return prevRooms.map((room, index) => {
        if (index === roomIndex) {
          return {
            ...room,
            customers: {
              ...room.customers,
              details: room.customers.details.map((detail, aIndex) => {
                if (aIndex === detailIndex) {
                  return [
                    ...detail.slice(0, i),
                    newName,
                    ...detail.slice(i + 1),
                  ];
                }
                return detail;
              }),
            },
          };
        }
        return room;
      });
    });
  };

  const isNamesValid = (rooms) => {
    for (var room of rooms) {
      for (var customer of room.customers.details) {
        if (customer.some((attr) => attr === "")) {
          return false;
        }
      }
    }
    return true;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleContinue = () => {
    if (
      !isNamesValid(rooms) ||
      firstName.length < 1 ||
      lastName.length < 1 ||
      emailAddress.length < 1 ||
      phone.length < 1 ||
      country.length < 1
    ) {
      handleToast(t("mustFillInAll"));
    } else if (!validateEmail(emailAddress)) {
      handleToast(t("enterValidEmail"));
    } else {
      dispatch(updateQuoteRoomIds(rooms));
      setStepSelected(2);
    }
  };

  return (
    <PersonalInformationContainer>
      <CircleOutlineDecoration />
      <BackgroundContainer />
      <PersonalInformationTitle>
        {t("personalInformation")}
      </PersonalInformationTitle>
      <PersonalInformationCard>
        <RectangleDecoration color={colors.blueGreen} />
        <CardTitle>{t("listOfPeople")}</CardTitle>
        <CardForm>
          {rooms &&
            rooms.map((room, roomIndex) => {
              if (room.customers && Array.isArray(room.customers.details)) {
                return room.customers.details.map((detail, detailIndex) => {
                  return (
                    <NamesContainer key={detailIndex}>
                      <NamesTitleContainer>
                        <label>
                          {t("roomsLabel")} <b>{"(" + (roomIndex + 1) + ")"}</b>{" "}
                          {detail[2] === "adult" ? t("adult") : t("child")}
                        </label>
                        <NamesLine />
                      </NamesTitleContainer>
                      <FormRow>
                        <FormItem>
                          <label>{t("firstName")}:</label>
                          <FormInput
                            value={detail[0]}
                            placeholder={t("enterFirstName")}
                            onChange={(e) =>
                              handleNamesChange(
                                roomIndex,
                                detailIndex,
                                0,
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                        <FormItem>
                          <label>{t("lastName")}:</label>
                          <FormInput
                            value={detail[1]}
                            placeholder={t("enterLastName")}
                            onChange={(e) =>
                              handleNamesChange(
                                roomIndex,
                                detailIndex,
                                1,
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </FormRow>
                    </NamesContainer>
                  );
                });
              }
            })}
        </CardForm>
      </PersonalInformationCard>
      <PersonalInformationCard>
        <RectangleDecoration color={colors.green} />
        <CardTitle>{t("enterYourDetails")}</CardTitle>
        <CardForm>
          <FormItem firstItem>
            {t("firstName")}:
            <FormInput
              value={firstName}
              placeholder={t("enterYourFirstName")}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormItem>
          <FormItem>
            {t("lastName")}:
            <FormInput
              value={lastName}
              placeholder={t("enterYourLastName")}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormItem>
          <FormItem>
            {t("emailAddress")}:
            <FormInput
              value={emailAddress}
              type={"email"}
              placeholder={t("enterYourEmail")}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </FormItem>
          <FormItem>
            {t("country")}:
            <CountryDropdownInput
              value={country}
              defaultOptionLabel={"Tunisia"}
              onChange={(val) => setCountry(val)}
            />
          </FormItem>
          <FormItem>
            {t("phoneNumber")}:
            <PhoneInputContainer
              placeholder={t("enterYourPhoneNumber")}
              country="tn"
              autoFormat={false}
              value={phone}
              onChange={setPhone}
            />
          </FormItem>
        </CardForm>
      </PersonalInformationCard>
      <ContinueButton onClick={() => handleContinue()}>
        {t("continue")}
      </ContinueButton>
    </PersonalInformationContainer>
  );
};

const PersonalInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 75px 35px;
  max-width: 620px;
  gap: 40px;
  align-self: center;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: auto;
    align-self: auto;
    align-items: inherit;
    padding: 75px 80px;
  }
`;

const PersonalInformationTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

const PersonalInformationCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${colors.white};
  padding: 40px;
  border: 1px solid ${colors.black4};
  border-radius: 20px;
  box-shadow: 0px 10px 20px ${colors.black6};
  gap: 40px;
  max-width: 215px;

  @media only screen and (min-width: 768px) {
    max-width: none;
  }
`;

const RectangleDecoration = styled.div`
  position: absolute;
  top: 0px;
  left: 40px;
  width: 50px;
  height: 6px;
  background-color: ${(props) => props.color};
`;

const CardTitle = styled.p`
  font-size: 22px;
  margin: 0;
  font-weight: 500;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  display: none;
  z-index: -1;
  top: 0;
  right: 0;
  width: 650px;
  height: 321px;
  background-color: ${colors.ocean8};

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`;

const CircleOutlineDecoration = styled(svg.circleOutlineDecoration)`
  position: absolute;
  top: 300px;
  right: 0;
`;

const CardForm = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 25px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: ${colors.black};
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-top: auto;
    margin-bottom: 0;
    width: 46%;
  }
`;

const FormInput = styled.input`
  background-color: ${(props) =>
    props.disabled ? colors.black3 : colors.white};
  border: 1px solid ${colors.black6};
  box-shadow: ${(props) =>
    props.disabled ? "none" : "0px 10px 20px " + colors.black3};
  padding: 16px 20px;
  border-radius: 10px;
  margin-top: 10px;
  outline: none;
`;

const CountryDropdownInput = styled(CountryDropdown)`
  background-color: ${(props) =>
    props.disabled ? colors.black3 : colors.white};
  border: 1px solid ${colors.black6};
  box-shadow: ${(props) =>
    props.disabled ? "none" : "0px 10px 20px " + colors.black3};
  padding: 16px 20px;
  border-radius: 10px;
  margin-top: 10px;
  outline: none;
`;

const PhoneInputContainer = styled(PhoneInput)`
  background-color: ${(props) =>
    props.disabled ? colors.black3 : colors.white};
  border: 1px solid ${colors.black6};
  box-shadow: ${(props) =>
    props.disabled ? "none" : "0px 10px 20px " + colors.black3};
  padding: 16px 20px;
  border-radius: 10px;
  margin-top: 10px;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content !important;

  && .react-tel-input {
    width: fit-content !important;
  }

  && .form-control {
    border: 0;
    outline: none;
    height: auto;
    line-height: 14px;
    width: 100%;
    background-color: transparent;
  }

  && .PhoneInputCountrySelectArrow {
    display: none;
  }

  && .flag-dropdown {
    position: absolute;
    background-color: transparent;
    border: none;
  }
`;

const NamesContainer = styled.div`
  width: 100%;
`;

const NamesTitleContainer = styled.div`
  gap: 20px;
  margin-bottom: 20px;
  color: ${colors.blueGreen};
`;

const NamesLine = styled.div`
  width: 100%;
  margin-top: 8px;
  height: 1px;
  background-color: ${colors.black8};
`;

export default PersonalInformation;
