import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ACCESS_TOKEN,
  ENDPOINT_URL,
  FILE_SERVER_URL,
} from "../../utils/constants";

const initialState = {
  data: null,
  cardData: null,
  loading: false,
};

export const getEvaluationCard = createAsyncThunk(
  "evaluation/getEvaluationCard",
  async (arg, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/evaluation?populate=deep&locale=" + language
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getEvaluations = createAsyncThunk(
  "evaluation/getEvaluations",
  async (arg, { rejectWithValue }) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/users/me?populate[0]=evaluations"
      );
      return res.data.evaluations;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const addEvaluation = createAsyncThunk(
  "evaluation/addEvaluation",
  async (data, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    const [points, userMe] = await axios.all([
      axios.get(ENDPOINT_URL + "/api/vip-card-config"),
      axios.get(
        ENDPOINT_URL + "/api/users/me?populate=deep&locale=" + language
      ),
    ]);
    const totalPoints =
      Number(userMe.data.vip_card.totalPoints) +
      Number(points.data.data.attributes.evaluationPoints);
    const vipCard = {
      data: {
        totalPoints: totalPoints,
        user: userMe.data.id,
      },
    };
    const vipCardResponse = await axios.put(
      ENDPOINT_URL + `/api/vip-cards/${userMe.data.vip_card.id}`,
      vipCard
    );
    const vipLog = {
      data: {
        title: "Riadh Palms points earned on create evaluation",
        date: Date.now(),
        rewardPoints: points.data.data.attributes.evaluationPoints,
        vip_card: vipCardResponse.data.data.id,
      },
    };
    axios.post(ENDPOINT_URL + "/api/vip-logs", vipLog);
    const evaluation = {
      data: {
        title: data.title,
        content: data.content,
        experience: data.expSelected.toString(),
        user: data.user.userInfo,
      },
    };
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/evaluation-items?populate=deep&locale=" + language,
        evaluation
      );
      const { attributes } = res.data.data;
      return attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const deleteEvaluation = createAsyncThunk(
  "evaluation/deleteEvaluation",
  async (data, { rejectWithValue }) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    try {
      const res = await axios.delete(
        ENDPOINT_URL + "/api/evaluation-items/" + data.id
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const evaluationSlice = createSlice({
  name: "evaluation",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Evaluations
    [getEvaluations.pending]: (state) => {
      state.loading = true;
    },
    [getEvaluations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getEvaluations.rejected]: (state) => {
      state.loading = false;
    },

    // Add Evaluation
    [addEvaluation.pending]: (state) => {
      state.loading = true;
    },
    [addEvaluation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = [...state.data, payload];
    },
    [addEvaluation.rejected]: (state) => {
      state.loading = false;
    },

    // Get Evaluation Card
    [getEvaluationCard.pending]: (state) => {
      state.loading = true;
    },
    [getEvaluationCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cardData = payload;
    },
    [getEvaluationCard.rejected]: (state) => {
      state.loading = false;
    },

    // Delete Evaluation
    [deleteEvaluation.pending]: (state) => {
      state.loading = true;
    },
    [deleteEvaluation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = state.data.filter((item) => item.id !== payload.data.id);
    },
    [deleteEvaluation.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default evaluationSlice.reducer;
