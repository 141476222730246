import styled from "styled-components";
import { colors } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const PassengersTable = ({ data }) => {
  const { t } = useTranslation();
  const rooms = Array.isArray(data) ? data : [data];

  return (
    <Table>
      <thead>
        <TableHeader>
          <HeaderItem>{t("voucherCustomerNames")}</HeaderItem>
          <HeaderItem>{t("voucherRoomType")}</HeaderItem>
          <HeaderItem>{t("voucherDiscounts")}</HeaderItem>
          <HeaderItem>{t("voucherArrangements")}</HeaderItem>
        </TableHeader>
      </thead>
      <tbody>
        {rooms.map((item) => {
          const passengers = Array.isArray(item.Passengers.Passenger)
            ? item.Passengers.Passenger
            : [item.Passengers.Passenger];
          return passengers.map((passenger, index) => {
            return (
              <BodyTr key={index}>
                <BodyTd>
                  {passenger.FirstName}
                  {passenger.LastName}{" "}
                  {passenger.Age && ` : ${passenger.Age} an(s)`}
                </BodyTd>
                <BodyTd>{item.Title}</BodyTd>
                <BodyTd>{passenger.Reduction && passenger.Reduction._}</BodyTd>
                <BodyTd>{item.Boarding.Title}</BodyTd>
              </BodyTr>
            );
          });
        })}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border: 1px solid ${colors.black15};
  margin: 10px 0;
  font-size: 14px;
`;

const TableHeader = styled.tr`
  color: white;
`;

const BodyTr = styled.tr`
  border-bottom: 1px solid ${colors.black15};
`;

const HeaderItem = styled.th`
  background-color: ${colors.black35};
  padding: 10px 20px;
  flex: 1;
`;

const BodyTd = styled.td`
  padding: 10px 20px;
`;

export default PassengersTable;
