import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL} from "../../utils/constants";

const initialState = {
  categories: null,
  category: null,
  loading: false,
  success: null,
};

export const getVideoCategories = createAsyncThunk(
  "videos/getVideoCategories",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/categorie-videos?populate[0]=videos&locale=" +
          language
      );
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getVideoCategory = createAsyncThunk(
  "videos/getVideoCategory",
  async (categoryId) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/categorie-videos/" +
          categoryId +
          "?populate[0]=videos&locale=" +
          language
      );
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const videosSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: {
    [getVideoCategories.pending]: (state) => {
      state.loading = true;
    },
    [getVideoCategories.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categories = payload;
      state.success = true;
    },
    [getVideoCategories.rejected]: (state) => {
      state.loading = false;
    },
    [getVideoCategory.pending]: (state) => {
      state.loading = true;
    },
    [getVideoCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.category = payload;
    },
    [getVideoCategory.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default videosSlice.reducer;
