import React from "react";

const LogoutIcon = ({fillColor}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 20 19"
    >
      <path
        id="logoutIcon"
        d="M3.5,13.949a.75.75,0,0,1-1.5,0V6.714A4.273,4.273,0,0,1,6.322,2.5h4.746A4.284,4.284,0,0,1,15.4,6.725v.884a.75.75,0,0,1-1.5,0V6.725a2.8,2.8,0,0,0-2.831-2.762H6.322A2.79,2.79,0,0,0,3.5,6.714Zm10.4,2.443a.75.75,0,0,1,1.5,0v.894A4.273,4.273,0,0,1,11.077,21.5H6.33A4.284,4.284,0,0,1,2,17.275a.75.75,0,0,1,1.5,0,2.8,2.8,0,0,0,2.83,2.762h4.747A2.79,2.79,0,0,0,13.9,17.286Zm8.045-4.671a.75.75,0,0,0-.693-.453H9.549a.732.732,0,1,0,0,1.464h9.885l-1.559,1.514a.719.719,0,0,0,0,1.035.763.763,0,0,0,1.061,0l2.846-2.764A.721.721,0,0,0,21.943,11.721Zm-2.822-1.06a.76.76,0,0,1-.528-.212l-.718-.693a.718.718,0,0,1,0-1.034.761.761,0,0,1,1.061-.006l.717.692a.719.719,0,0,1,.006,1.035A.759.759,0,0,1,19.122,10.661Z"
        transform="translate(-2 -2.5)"
        fill={fillColor}
        fillRule="evenodd"
        opacity="0.8"
      />
    </svg>
  );
};

export default LogoutIcon;
