// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCouMPV4q7vpkEJwGmJyVJnmWE2IV0nSVo",
  authDomain: "riadh-palms.firebaseapp.com",
  projectId: "riadh-palms",
  storageBucket: "riadh-palms.appspot.com",
  messagingSenderId: "942542440429",
  appId: "1:942542440429:web:66132c89458fcda7ba89e4",
  measurementId: "G-33H811YNQ8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const getDeviceToken = async () => {
  const hasFirebaseMessagingSupport = await isSupported();
  if (hasFirebaseMessagingSupport) {
    const messaging = getMessaging(app);
    return getToken(messaging, {
      vapidKey:
        "BEZEDatH3C3p2mz83U37Ok4gf-2MnLu5Fg0uZtq0MG-E6681OJPMZQsxVQL7yAcklKAtNYX8WaeNCGI4t73OmtM",
    })
      .then((currentToken) => {
        if (currentToken) {
          subscribeTokenToTopic(currentToken, "web_users");
          return currentToken;
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }
};

function subscribeTokenToTopic(token, topic) {
  const FCM_SERVER_KEY =
    "AAAA23PoR-0:APA91bGtrR-QtZOp4a9iqGPGX7aY9JQajyafa5V_H8yZTC3nS1sWfuF8OeQ8wLliY3CEe5H8C1hu25-p7Df52E-oPiAxbHXBspn9eiHz9C0CxstcRj37YcHfPVtTw5tFii0GpGKwx9oT";
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    method: "POST",
    headers: new Headers({
      Authorization: `key=${FCM_SERVER_KEY}`,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        console.log(response.status, response);
      }
      console.log(`User is subscribed`);
    })
    .catch((error) => {
      console.error(error.result);
    });
  return true;
}

export default app;
