import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";

import PrimaryHeader from "./components/header/PrimaryHeader";
import ChatBot from "./components/chatbot/ChatBot";
import { store } from "./services/store";
import { Toaster } from "react-hot-toast";
import ReactGA from "react-ga";
import "./App.css";
import Footer from "./components/footer/Footer";
import FirstVisitModal from "./pages/Home/components/FirstVisitModal";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import SocialFab from "./components/socialFab/SocialFab";
import Cursor from "./components/cursor/Cursor";
import CacheBuster from "react-cache-buster";
import packageJson from '../package.json';


const TRACKING_ID = "G-HT4ZN6MODG"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const persistor = persistStore(store);

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  const { version } = packageJson; 

  return (
    <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        reloadOnDowngrade={false} //Whether to also bust the cache and reload if the version fetched from the server is lower
      >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div
            className="App"
            style={{ position: "relative", overflowX: "hidden" }}
          >
            <Cursor />
            <Toaster />
            <PrimaryHeader />
            <FirstVisitModal />
            <AppRoutes />
            <Footer />
            <SocialFab />
            <ChatBot />
          </div>
        </Router>
      </PersistGate>
    </Provider>
    </CacheBuster>
  );
};

export default App;
