import styled from "styled-components";
import { svg } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";

const CoverIcons = ({ data }) => {
  const navigate = useNavigate();

  const handleBlogClick = () => {
    navigate("/blog");
  };

  return (
    <CoverIconsContainer>
      <CoverIcon onClick={handleBlogClick}>
        <svg.coverBlogIcon />
      </CoverIcon>
      <CoverIcon target="_blank" rel="noopener noreferrer" href={data[0].url}>
        <svg.coverInstagramIcon />
      </CoverIcon>
      <CoverIcon target="_blank" rel="noopener noreferrer" href={data[1].url}>
        <svg.coverFacebookIcon />
      </CoverIcon>
      <CoverIcon target="_blank" rel="noopener noreferrer" href={data[2].url}>
        <svg.coverYoutubeIcon />
      </CoverIcon>
    </CoverIconsContainer>
  );
};

const CoverIconsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 15px;

  @media only screen and (min-width: 768px) {
    right: 50px;
  }
`;

const CoverIcon = styled.a`
  margin: 0 12px;
  cursor: pointer;
`;

export default CoverIcons;
