import React from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { colors } from "../../../../utils/colors";
import { FILE_SERVER_URL, svg } from "../../../../utils/constants";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useTranslation } from "react-i18next";
import { getTestimonials } from "../../../../services/Testimonial/testimonialSlice";
import { useDispatch, useSelector } from "react-redux";

const Testimonials = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.testimonial.data);
  const language = useSelector((state) => state.language);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTestimonials());
  }, [dispatch, language]);

  //============= Animation ============//

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const animXY1 = {
    visible: { x: 0, opacity: 1, transition: { duration: 1.5 } },
    hidden: { x: -90, opacity: 0 },
  };

  const animXY2 = {
    visible: { y: 0, opacity: 1, transition: { duration: 1.5 } },
    hidden: { y: 40, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  //============= End Animation ============//
  return (
    <>
      {data && data.length !== 0 && (
        <Container>
          <LineDecoration />
          <BodyContainer>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={animXY1}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <BeginSubTitle>
                {t("testimonial")}
                <GreenLine />
              </BeginSubTitle>
              <BeginTitle>{t("testimonialSubtitle")}</BeginTitle>
            </motion.div>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={animXY2}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Cards>
                {data
                  .filter((item) => item.attributes.featured).slice(0, 1)
                  .map((item) => (
                    <TestimonialCard key={item.id}>
                      <Quotes>
                        <svg.quotesIcon />
                      </Quotes>
                      <CardImage
                        src={
                          item.attributes.user.data.attributes.profile.data
                            .attributes.pictureUrl !== null
                            ? item.attributes.user.data.attributes.profile.data
                                .attributes.pictureUrl
                            : "https://storage.googleapis.com/riadh-palms.appspot.com/uploads/small_placeholder_f48cb02059.png"
                        }
                      />
                      <CardUser>
                        {
                          item.attributes.user.data.attributes.profile.data
                            .attributes.firstName
                        }
                      </CardUser>
                      <CardTitle>{item.attributes.title}</CardTitle>
                      <CardDescription id="firstItem">
                        {item.attributes.content}
                      </CardDescription>
                      <Tooltip
                        style={{
                          width: "180px",
                          fontSize: "14px",
                          borderRadius: "8px",
                          zIndex: 4,
                          padding: 14,
                        }}
                        anchorId="firstItem"
                      >
                        <span>{item.attributes.content}</span>
                      </Tooltip>
                      <CardExperience
                        backgroundColor={
                          item.attributes.experience === "1"
                            ? colors.purple
                            : item.attributes.experience === "2"
                            ? colors.blueGreen
                            : item.attributes.experience === "3"
                            ? colors.orange
                            : colors.pink
                        }
                      >
                        {item.attributes.experience === "1"
                          ? t("fabulous")
                          : item.attributes.experience === "2"
                          ? t("good")
                          : item.attributes.experience === "3"
                          ? t("quiteWell")
                          : t("bad")}
                      </CardExperience>
                    </TestimonialCard>
                  ))}
                <GridContainer>
                  {data
                    .filter((item) => !item.attributes.featured).slice(0, 4)
                    .map((item) => (
                      <GridTestimonialCard
                        key={item.id}
                        backgroundColor={colors.lightBlack}
                      >
                        <GridCardTitle>{item.attributes.title}</GridCardTitle>
                        <GridCardDescription id={item.id}>
                          {item.attributes.content}
                        </GridCardDescription>
                        <Tooltip
                          style={{
                            width: "180px",
                            fontSize: "14px",
                            borderRadius: "8px",
                            zIndex: 4,
                            padding: 14,
                          }}
                          anchorId={item.id}
                        >
                          <span>
                            {item.attributes.content}
                            <span
                              style={{
                                color:
                                  item.attributes.experience === "1"
                                    ? colors.purple
                                    : item.attributes.experience === "2"
                                    ? colors.blueGreen
                                    : item.attributes.experience === "3"
                                    ? colors.orange
                                    : colors.pink,
                              }}
                            >
                              {" -"}
                              {item.attributes.experience === "1"
                                ? t("fabulous")
                                : item.attributes.experience === "2"
                                ? t("good")
                                : item.attributes.experience === "3"
                                ? t("quiteWell")
                                : t("bad")}
                            </span>
                          </span>
                        </Tooltip>
                        <GridUserContainer>
                          <GridCardImage
                            src={
                              item.attributes.user.data.attributes.profile.data
                                .attributes.pictureUrl !== null
                                ? item.attributes.user.data.attributes.profile
                                    .data.attributes.pictureUrl
                                : "https://storage.googleapis.com/riadh-palms.appspot.com/uploads/small_placeholder_f48cb02059.png"
                            }
                          />
                          <GridCardUser>
                            {
                              item.attributes.user.data.attributes.profile.data
                                .attributes.firstName
                            }
                          </GridCardUser>
                        </GridUserContainer>
                      </GridTestimonialCard>
                    ))}
                </GridContainer>
              </Cards>
            </motion.div>
          </BodyContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin: 0 35px;

  @media only screen and (min-width: 768px) {
    margin: 50px 150px;
  }
`;

const GreenLine = styled.div`
  width: 0;
  height: 6px;
  background-color: ${colors.green};
  margin: 4px 16px;
  display: none;

  @media only screen and (min-width: 768px) {
    width: 40px;
    display: flex;
  }
`;

const BeginSubTitle = styled.h1`
  display: flex;
  font-size: 20px;
  font-weight: 500;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const BeginTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin: 0;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const TestimonialCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  max-height: 300px;
  background-color: ${colors.white};
  padding: 40px;
  border: 1px solid ${colors.black4};
  border-radius: 20px;
  box-shadow: 0px 6px 30px ${colors.black3};
  gap: 16px;

  @media only screen and (min-width: 768px) {
    width: 200px;
  }
`;

const Quotes = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
`;

const CardImage = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const CardUser = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${colors.black};
`;

const CardTitle = styled.div`
  font-size: 14px;
  color: ${colors.black75};
`;

const CardDescription = styled.div`
  font-size: 14px;
  color: ${colors.black50};
  width: 200px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const CardExperience = styled.div`
  font-size: 14px;
  border-radius: 100px;
  background-color: ${(props) => props.backgroundColor};
  padding: 8px 20px;
  color: ${colors.white};
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
`;

const GridTestimonialCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90px;
  max-height: 140px;
  background-color: ${(props) => props.backgroundColor};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 6px 30px ${colors.black3};
  gap: 4px;

  @media only screen and (min-width: 768px) {
    width: 100px;
  }
`;

const GridUserContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 8px;
  width: 100%;
`;

const GridCardImage = styled.img`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  object-fit: cover;
`;

const GridCardUser = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GridCardTitle = styled.div`
  font-size: 12px;
  color: ${colors.white80};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const GridCardDescription = styled.div`
  font-size: 12px;
  color: ${colors.white50};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const LineDecoration = styled.div`
  position: absolute;
  display: none;
  left: -40px;
  top: 100px;
  width: 150px;
  height: 450px;
  border: 2px dashed ${colors.black8};
  border-radius: 40px;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export default Testimonials;
