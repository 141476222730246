import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import Dropdown from "react-dropdown";

import { colors } from "../../../../../utils/colors";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";

const PeopleModal = ({
  openPeopleModal,
  setOpenPeopleModal,
  roomIds,
  setRoomIds,
  adults,
  setAdults,
  children,
  setChildren,
  rooms,
  setRooms,
}) => {
  const { t } = useTranslation();
  const [formValid, setFormValid] = useState(true);

  useEffect(() => {
    // Update formValid based on child age selection
    let isValid = true;

    for (const room of roomIds) {
      if (room.child.number > 0) {
        // If there are children, check if all ages are specified
        if (
          room.child.age.length === 0 ||
          room.child.age.some((age) => age === 0) ||
          room.child.age.length !== room.child.number
        ) {
          isValid = false;
          break;
        }
      }
    }

    setFormValid(isValid);
  }, [roomIds, roomIds.child]);

  useEffect(() => {}, [roomIds]);

  const customStyles = {
    overlay: {
      backgroundColor: colors.black65,
      zIndex: 3,
    },

    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "1px solid " + colors.black8,
      borderRadius: "10px",
    },
  };

  const options = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const defaultOption = options[0];

  const handleChildren = (event, index, i) => {
    let ageArr = [...roomIds[index].child.age];
    ageArr[i] = event.value;
    let updatedRoomIds = [...roomIds];
    updatedRoomIds[index] = {
      ...updatedRoomIds[index],
      child: {
        ...updatedRoomIds[index].child,
        age: ageArr,
      },
    };
    setRoomIds(updatedRoomIds);
   
  };

  return (
    <Modal
      isOpen={openPeopleModal}
      onRequestClose={() => setOpenPeopleModal(false)}
      style={customStyles}
      ariaHideApp={false}
    >
      <PeopleModalContainer>
        <PeopleItemContainer>
          <PeopleSubTitle>{t("roomsLabel")}:</PeopleSubTitle>
          <CounterContainer>
            <CounterButton
              onClick={() => {
                if (roomIds.length > 0) {
                  setRoomIds(roomIds.slice(0, -1));
                }
              }}
            >
              -
            </CounterButton>
            {roomIds.length}
            <CounterButton
              onClick={() => {
                if (roomIds.length < 10) {
                  setRoomIds([
                    ...roomIds,
                    {
                      adult: 1,
                      child: {
                        age: [],
                        number: 0,
                      },
                    },
                  ]);
                }
              }}
            >
              +
            </CounterButton>
          </CounterContainer>
        </PeopleItemContainer>
        {[...Array(roomIds.length)].map((_, index) => (
          <PeopleFormRoom key={index}>
            {t("roomLabel")} {index + 1}:
            <PeopleItemContainer>
              <PeopleSubTitle>{t("adults")}:</PeopleSubTitle>
              <CounterContainer>
                <CounterButton
                  onClick={() => {
                    if (roomIds[index].adult > 1) {
                      const updatedRooms = [...roomIds];
                      const updatedRoom = { ...updatedRooms[index] };
                      updatedRoom.adult -= 1;
                      updatedRooms[index] = updatedRoom;
                      setRoomIds(updatedRooms);
                    }
                  }}
                >
                  -
                </CounterButton>
                {roomIds[index].adult}
                <CounterButton
                  onClick={() => {
                    if (roomIds[index].adult < 10) {
                      const updatedRooms = [...roomIds];
                      const updatedRoom = { ...updatedRooms[index] };
                      updatedRoom.adult += 1;
                      updatedRooms[index] = updatedRoom;
                      setRoomIds(updatedRooms);
                    }
                  }}
                >
                  +
                </CounterButton>
              </CounterContainer>
            </PeopleItemContainer>
            <ChildrenContainer>
              <PeopleItemContainer>
                <PeopleSubTitle>{t("children")}:</PeopleSubTitle>
                <CounterContainer>
                  <CounterButton
                    onClick={() => {
                      if (roomIds[index].child.number > 0) {
                        setRoomIds((prevRoomIds) => {
                          const updatedRoomIds = [...prevRoomIds];
                          const lastChildAgeIndex =
                            updatedRoomIds[index].child.age.length - 1;

                          updatedRoomIds[index] = {
                            ...updatedRoomIds[index],
                            child: {
                              ...updatedRoomIds[index].child,
                              number: updatedRoomIds[index].child.number - 1,
                              age: updatedRoomIds[index].child.age.slice(
                                0,
                                lastChildAgeIndex
                              ),
                            },
                          };
                          return updatedRoomIds;
                        });
                      }
                    }}
                  >
                    -
                  </CounterButton>
                  {roomIds[index].child.number}
                  <CounterButton
                    onClick={() => {
                      if (roomIds[index].child.number < 10) {
                        setRoomIds((prevRoomIds) => {
                          const updatedRoomIds = [...prevRoomIds];
                          updatedRoomIds[index] = {
                            ...updatedRoomIds[index],
                            child: {
                              ...updatedRoomIds[index].child,
                              number: updatedRoomIds[index].child.number + 1,
                              age: [...updatedRoomIds[index].child.age, 0],
                            },
                          };
                          return updatedRoomIds;
                        });
                      
                      }
                    }}
                  >
                    +
                  </CounterButton>
                </CounterContainer>
              </PeopleItemContainer>
              {roomIds[index].child.number > 0 ? (
                <AgesContainer>
                  <Ages>
                    {roomIds[index].child.age.map((age, i) => (
                      <ChildAge
                        key={i}
                        options={options}
                        onChange={(event) => handleChildren(event, index, i)}
                         // Set the value prop to the age
                        
                        placeholder={` ${age}` }

                      />
                    ))}
                  </Ages>
                  <Info>{t("childrenAgeInfo")}</Info>
                </AgesContainer>
              ) : null}
            </ChildrenContainer>
          </PeopleFormRoom>
        ))}
        <PeopleDoneButton
          disabled={!formValid || roomIds.length < 1}
          onClick={() => setOpenPeopleModal(false)}
        >
          {t("done")}
        </PeopleDoneButton>
      </PeopleModalContainer>
    </Modal>
  );
};

const PeopleModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  max-height: 600px;
  padding: 20px;
  gap: 20px;
`;

const PeopleItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PeopleSubTitle = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black};
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  padding: 0px;
  font-weight: 500;
  gap: 20px;
`;

const CounterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: monospace;
  background-color: ${colors.ocean8};
  color: ${colors.ocean};
  padding: 10px;
  font-size: 24px;
  font-weight: 500;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${colors.ocean20};
    color: ${colors.ocean};
  }
`;

const PeopleDoneButton = styled.button`
  display: block;
  border: none;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  background-color: ${colors.ocean};
  color: ${colors.white};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: ${({ disabled }) =>
    disabled ? colors.gray : colors.ocean};

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? colors.lightOcean : colors.ocean};
    color: ${colors.white};
  }
`;

const PeopleFormRoom = styled.div`
  background-color: ${colors.purple10};
  padding: 10px;
  border-radius: 10px;
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
  gap: 10px;
`;

const Info = styled.p`
  font-size: 14px;
  color: ${colors.black65};
  margin: 0;
`;

const Ages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ChildAge = styled(Dropdown)`
  border-radius: 10px;
  width: 46%;

  && .Dropdown-placeholder {
    font-size: 14px;
  }

  && .Dropdown-placeholder.is-selected {
    font-size: 14px;
  }
`;

export default PeopleModal;
