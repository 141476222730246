import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";

import { colors } from "../../utils/colors";
import { CircleDecoration } from "../../utils/styles";
import SeoHelmet from "../../utils/seo";

import ImagesCarousel from "../GenericPage/components/imagesCarousel";
import { getBars } from "../../services/Bars/barsSlice";
import { Tooltip } from "react-tooltip";

const Bars = () => {
  const dispatch = useDispatch();
  const bars = useSelector((state) => state.bars.data);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getBars());
  }, [dispatch, language]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Container>
      {bars && bars.seo && <SeoHelmet props={bars} url={"/bars"}></SeoHelmet>}
      {bars && (
        <>
          <CircleDecoration color={colors.pink} top="35px" right="150px" />
          <RectangleDecoration />
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <BodyContainer>
              <TextContainer>
                <Title color={colors.black}>{bars.title}</Title>
                <Items>
                  {bars.barSchedule.map((item) => (
                    <Item key={item.id}>
                      <ItemTitle id={item.id}>{item.title}</ItemTitle>
                      <ItemSchedule>
                        {item.schedule != null ? parse(item.schedule) : ""}
                      </ItemSchedule>
                      
                    </Item>
                  ))}
                </Items>
              </TextContainer>
              <ImageContainer>
                <Paragraph color={colors.black}>
                  {parse(bars.description)}
                </Paragraph>
                <ImagesCarousel data={bars} />
              </ImageContainer>
            </BodyContainer>
          </motion.div>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin: 0 35px;

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 60px;
  }
`;

const RectangleDecoration = styled.div`
  display: none;
  position: absolute;
  top: 0px;
  bottom: 50px;
  right: 10px;
  height: 456px;
  width: 337px;
  background-color: ${colors.black3};
  z-index: -1;

  @media only screen and (min-width: 1200px) {
    display: flex;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media only screen and (min-width: 1200px) {
    padding-right: 100px;
    width: auto;
    align-items: flex-start;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media only screen and (min-width: 1200px) {
    align-items: flex-start;
    margin-top: 0px;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

const Paragraph = styled.div`
  font-size: 16px;
  max-width: 450px;
  width: 100%;
  color: ${(props) => props.color};
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
  align-items: flex-start;

  @media only screen and (min-width: 710px) {
    align-items: center;
  }

  @media only screen and (min-width: 1200px) {
    align-items: flex-start;
  }
`;

const Item = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
`;

const ItemTitle = styled.div`
  min-width: 120px;
  max-width: 120px;
  padding: 20px 30px;
  background-color: ${colors.white};
  color: ${colors.purple};
  font-weight: 500;
  text-align: center;
 
  white-space: nowrap;
  overflow: hidden;
`;

const ItemSchedule = styled.div`
  min-width: 145px;
  max-width: 145px;
  padding: 20px 50px;
  background-color: ${colors.purple8};
  color: ${colors.black};
  text-align: center;

  p {
    margin: 0;
  }
`;

export default Bars;
