import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  deferredPrompt: null,
  nbInstall: 0,
};

export const incrementNbInstall = createAsyncThunk(
  "nbInstall/getNbInstall",
  async () => {
    try {
      const TotalNbInstall = await axios.get(ENDPOINT_URL + "/api/nb-install");
      console.log("TotalNbInstall", TotalNbInstall.data.data.attributes.nbInstall);

      // Increment the value
      const currentNbInstall = Number(TotalNbInstall.data.data.attributes.nbInstall);
      const newNbInstall = currentNbInstall + 1;

      // Prepare the data to be sent in the POST request
      const nbInstal = {
        data: {
          nbInstall: newNbInstall,
        },
      };

      // Send the POST request
      const res = await axios.put(ENDPOINT_URL + "/api/nb-install", nbInstal);
      console.log(res);
      // Return the updated value
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const installButtonSlice = createSlice({
  name: "installButton",
  initialState,
  reducers: {
    setDeferredPrompt: (state, action) => {
      state.deferredPrompt = action.payload;
    },
  },
  extraReducers: {
    [incrementNbInstall.fulfilled]: (state, { payload }) => {
      state.nbInstall = payload;
    },
  },
});
export const { setDeferredPrompt } = installButtonSlice.actions;
export default installButtonSlice.reducer;
