import React from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

import { FILE_SERVER_URL } from "../../../utils/constants";
import { BlurContainer, BlurContainerText } from "../../../utils/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { colors } from "../../../utils/colors";

const ImagesCarousel = ({ data }) => {
  return data.featuredImage.data.length > 1 ? (
    <CarouselParent>
      <Carousel
        showThumbs={false}
        showArrows={false}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        emulateTouch={true}
      >
        {data.featuredImage.data.map((image, index) => (
          <div key={index}>
            <ImageMask>
              <Image url={FILE_SERVER_URL + image.attributes.url} />
            </ImageMask>
            <BlurContainer>
              <BlurContainerText>{image.attributes.name}</BlurContainerText>
            </BlurContainer>
          </div>
        ))}
      </Carousel>
    </CarouselParent>
  ) : (
    <ImageOnly>
      <ImageMask>
        <Image
          url={FILE_SERVER_URL + data.featuredImage.data[0].attributes.url}
        />
      </ImageMask>
      <BlurContainer>
        <BlurContainerText>
          {data.featuredImage.data[0].attributes.name}
        </BlurContainerText>
      </BlurContainer>
    </ImageOnly>
  );
};

const ImageOnly = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 400px;
`;

const ImageMask = styled.div`
  overflow: hidden;
  border-radius: 12px;
  width: 100%;
  margin-top: 30px;
`;

const Image = styled.div`
  width: 100%;
  max-width: 400px;
  height: 320px;
  background-image: url(${(props) => props.url});
  background-position-y: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  transition: transform 2s;

  &:hover {
    transform: scale(1.2);
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
    max-width: 416px;
    height: 400px;
  }
`;

const CarouselParent = styled.div`
  max-width: 320px;
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 100%;
    max-width: 416px;
  }

  && .carousel-slider {
    display: flex;
    flex-direction: column-reverse;
  }

  && .control-dots {
    padding: 35px !important;
    position: relative;
  }

  && .dot {
    box-shadow: none !important;
    background-color: ${colors.black65}!important;
    width: 12px !important;
    height: 12px !important;
  }

  && .dot.selected {
    background-color: ${colors.green}!important;
    width: 24px !important;
    border-radius: 6px;
  }
`;

export default ImagesCarousel;
