import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { colors } from "../../../../utils/colors";
import { handleToast } from "../../../../utils/toast/toast";

const Steps = ({ stepSelected, setStepSelected }) => {
  const { t } = useTranslation();

  return (
    <StepsContainer>
      <SmallRectangleDecoration backgroundColor={colors.ocean} />
      <StepButton
        backgroundColor={stepSelected === -1 ? colors.ocean : colors.white}
        color={stepSelected === -1 ? colors.white : colors.black75}
        onClick={() => setStepSelected(-1)}
      >
        1. {t("reservationSelection")}
      </StepButton>
      <VerticalLine />
      <StepButton
        backgroundColor={stepSelected === 0 ? colors.ocean : colors.white}
        color={stepSelected === 0 ? colors.white : colors.black75}
        onClick={() => setStepSelected(0)}
      >
        2. {t("roomSelection")}
      </StepButton>
      <VerticalLine />
      <StepButton
        backgroundColor={stepSelected === 1 ? colors.ocean : colors.white}
        color={stepSelected === 1 ? colors.white : colors.black75}
        onClick={() =>
          stepSelected > 1
            ? setStepSelected(1)
            : handleToast(t("chooseOptionBeforeContinue"))
        }
      >
        3. {t("personalInformation")}
      </StepButton>
      <VerticalLine />
      <StepButton
        backgroundColor={stepSelected === 2 ? colors.ocean : colors.white}
        color={stepSelected === 2 ? colors.white : colors.black75}
        onClick={() => handleToast(t("paymentToast"))}
      >
        4. {t("confirmation")}
      </StepButton>
    </StepsContainer>
  );
};

const StepsContainer = styled.div`
  position: relative;
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${colors.ocean8};
  padding: 48px 42px;
  overflow-x: auto;
  white-space: nowrap;

  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
  }
`;

const StepButton = styled.button`
  padding: 16px 40px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 10px 10px 20px ${colors.black2};
  border: 0;
  border-radius: 10px;
  color: ${(props) => props.color};
  font-weight: 600;
  cursor: pointer;
`;

const VerticalLine = styled.div`
  @media only screen and (max-width: 991px) {
    border-top: 2px dashed ${colors.black15};
    width: 26px;
    margin: 0 8px;
  }

  @media only screen and (min-width: 992px) {
    border-left: 2px dashed ${colors.black15};
    height: 26px;
    margin: 8px 0;
  }
`;

const SmallRectangleDecoration = styled.div`
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  height: 50px;
  width: 6px;
  background-color: ${(props) => props.backgroundColor};

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`;

export default Steps;
