import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    lang:
      JSON.parse(localStorage.getItem("language")) ??
      localStorage.setItem("language", JSON.stringify("fr")) ??
      "fr",
  },
  reducers: {
    updateLanguage: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { updateLanguage } = languageSlice.actions;

export default languageSlice.reducer;
