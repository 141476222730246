import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogoutIcon from "../../pages/UserSpace/components/SideBar/icons/LogoutIcon";
import ProfileIcon from "../../pages/UserSpace/components/SideBar/icons/ProfileIcon";
import { getUserDetails, logout } from "../../services/User/userSlice";
import { colors } from "../colors";
import { FILE_SERVER_URL } from "../constants";
import { handleToast } from "../toast/toast";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      dispatch(getUserDetails());
    }
  }, [user.userToken, dispatch]);

  return (
    <Dropdown>
      <Settings
        onClick={() =>
          user && user.userInfo.profile ? handleOpen() : navigate("/signin")
        }
      >
        <Profile
          src={
            user &&
            user.userInfo.profile &&
            user.userInfo.profile.pictureUrl !== null
              ? user.userInfo.profile.pictureUrl
              : user &&
                user.userInfo.profile &&
                user.userInfo.profile.pictureUrl === null
              ? "https://storage.googleapis.com/riadh-palms.appspot.com/uploads/small_placeholder_f48cb02059.png"
              : "https://storage.googleapis.com/riadh-palms.appspot.com/uploads/profile_placeholder-plus.jpg"
          }
        />
      </Settings>
      {open && user ? (
        <Menu>
          <MenuItem onClick={() => navigate("/user-space")}>
            <ProfileIcon fillColor={colors.black50} />
            {t("userSpace")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(logout());
              handleToast(t("loggedOut"));
              handleOpen();
            }}
          >
            <LogoutIcon fillColor={colors.black50} /> {t("logout")}
          </MenuItem>
        </Menu>
      ) : null}
    </Dropdown>
  );
};

const Dropdown = styled.div`
  position: relative;
`;

const Settings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gold};
  border-radius: 60px;
  box-shadow: 6px 6px 10px ${colors.black4};
  margin-left: 24px;
  cursor: pointer;
`;

const Profile = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 34px;
  object-fit: cover;
  box-shadow: 6px 6px 10px ${colors.black3};
  padding: 2px;
`;

const Menu = styled.ul`
  position: absolute;
  right: 0px;
  list-style-type: none;
  border-radius: 8px;
  border: 1px solid ${colors.black4};
  box-shadow: none;
  margin-top: 10px;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black75};
  padding: 0;
  z-index: 4;
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
  gap: 10px;
  padding: 12px 20px;
  border-bottom: 1px solid ${colors.black4};

  &:hover {
    background-color: ${colors.black3};
  }
`;

export default ProfileSettings;
