import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import styled from "styled-components";
import parse from "html-react-parser";
import {
  addMsgToMsgList,
  resetChat,
  sendChatbotMsg,
} from "../../services/ChatBot/chatbotSlice";
import { colors } from "../../utils/colors";
import { svg } from "../../utils/constants";
import { Link } from "react-router-dom";
import chatAnimation from "./buble_chat_lottie.json";
import ChatBotReservationForm from "./ChatBotReservationForm";
import LanguagesChatBot from "../../utils/languages/languagesChatBot";


const ChatBot = () => {
  const dispatch = useDispatch();
  const [displayed, setDisplayed] = useState(false);
  const language = useSelector((state) => state.language);
  const loading = useSelector((state) => state.chatbot.loading);
  let firstTime = true;

  const initialText =
    language.lang === "fr"
      ? "Salutations"
      : language.lang === "de"
      ? "hallo"
      : language.lang === "ru"
      ? "приветик"
      : "Hello";
  const [text, setText] = useState(initialText);
  const chatbot = useSelector((state) => state.chatbot);
  

  useEffect(() => {
    
      if (firstTime) {
        dispatch(resetChat())
        firstTime = false;
        dispatch(sendChatbotMsg({ language, text : initialText }));
        setText("");
      }
  }, [language]);

  useEffect(() => {}, [language]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(addMsgToMsgList(text));
      dispatch(sendChatbotMsg({ language, text }));
      setText("");
    }
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  return (
    <>
      <ChatBotContainer isOpen={displayed}>
        <ChatHeader >
          <ChatHeaderLeft>
            <ChatBotPicture>
              <ConnectedIcon />
              <svg.palmIcon width={28} />
            </ChatBotPicture>
            <ChatBotTitles>
              <ChatBotTitle>Palma</ChatBotTitle>
              <ChatBotConnected>Online</ChatBotConnected>
            </ChatBotTitles>
          </ChatHeaderLeft>
          <ChatBotCloseContainer>
          <LanguagesChatBot languageColor={colors.black} />
            <ChatBotClose onClick={() => setDisplayed(false)}>
              <svg.closeIcon />
            </ChatBotClose> 
          </ChatBotCloseContainer>
        </ChatHeader>
        <ChatBotMessages>
          {loading && (
            <div style={{ width: 100, height: 70 }}>
              <Lottie animationData={chatAnimation} loop={true} />
            </div>
          )}
          {chatbot &&
            chatbot.msgList &&
            chatbot.msgList
              .map((item, index) =>
                item.sender === "You" ? (
                  <UserMessageItem key={index}>
                    <UserName>{item.sender}</UserName>
                    <UserMessage>{item.msg}</UserMessage>
                  </UserMessageItem>
                ) : item.type === "text" ? (
                  <HotelMessageItem key={index}>
                    <HotelName>{item.sender}</HotelName>
                    {item.intent !== "hotel-reservation" ? (
                      <HotelMessage>{parse(item.msg)}</HotelMessage>
                    ) : null}
                    <ChatBotReservationForm
                      item={item}
                      setDisplayed={setDisplayed}
                    />
                  </HotelMessageItem>
                ) : (
                  <OptionsMessageItems key={index}>
                    {item.items.chips &&
                      item.items.chips[0] &&
                      item.items.chips[0].map((option, index) => (
                        <Option
                          key={index}
                          onClick={() =>
                            dispatch(sendChatbotMsg({ language, option }))
                          }
                        >
                          {option}
                        </Option>
                      ))}
                    {item.items.images &&
                      item.items.images.map((image, index) => (
                        <Image key={index} src={image} />
                      ))}
                    {item.items.videos &&
                      item.items.videos.map((video, index) => {
                        const videoIdRegex = /v=([A-Za-z0-9_-]{11})/;
                        const videoId = video.match(videoIdRegex);
                        return (
                          <IFrame
                            key={index}
                            loading="lazy"
                            src={`https://www.youtube.com/embed/${videoId[1]}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          />
                        );
                      })}
                    {item.items.links &&
                      item.items.links.map((link, index) => {
                        const urlArray = link.split("/"); // split the URL into an array of strings
                        const thirdSegment = urlArray.slice(3, 4)[0];
                        return (
                          <SeeMoreLink key={index} to={thirdSegment}>
                            {"See More >"}
                          </SeeMoreLink>
                        );
                      })}
                  </OptionsMessageItems>
                )
              )
              .reverse()}
        </ChatBotMessages>
        <ChatBar>
          <ChatBarInput
            type="text"
            placeholder="Type your message here .."
            name="chatInput"
            value={text}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <SendIcon
            onClick={() => {
              dispatch(addMsgToMsgList(text));
              dispatch(sendChatbotMsg({ language, text }));
              setText("");
            }}
          >
            <svg.sendIcon />
          </SendIcon>
        </ChatBar>
      </ChatBotContainer>
      <ChatBotIcon onClick={() => setDisplayed(!displayed)}>
        <svg.palmIcon height={30} />
      </ChatBotIcon>
    </>
  );
};

const ChatBotIcon = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  bottom: 25px;
  height: 58px;
  width: 58px;
  border-radius: 70px;
  background-color: ${colors.gold};
  z-index: 2;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    right: 40px;
    bottom: 30px;
  }
`;

const ChatBotContainer = styled.div`
  display: ${(props) => (props.isOpen === true ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  min-height: 400px;
  max-height: 580px;
  width: 85%;
  background-color: #f7f7f7;
  border: 1px solid ${colors.black6};
  box-shadow: 0px 10px 30px ${colors.black6};
  right: 30px;
  bottom: 50px;
  border-radius: 12px;
  z-index: 3;

  @media only screen and (min-width: 768px) {
    width: 340px;
    right: 50px;
    bottom: 50px;
  }
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: auto;
  padding: 18px 25px;
  background-color: #fcfcfc;
  border-radius: 12px 12px 0 0;
  
`;

const ChatHeaderLeft = styled.div`
  display: flex;
  gap: 20px;
`;

const ChatBotCloseContainer = styled.div`
  display: flex;
  gap: 10px;
  height: 20px;
  
`;

const ChatBotClose = styled.div`
  display: flex;
 
  cursor: pointer;
`;

const ChatBotPicture = styled.div`
  display: flex;
  position: relative;
  background-color: ${colors.gold};
  height: 52px;
  width: 52px;
  border-radius: 70px;
  justify-content: center;
  align-items: center;
`;

const ConnectedIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #29ef4a;
  border: 4px solid ${colors.white};
`;

const ChatBotTitles = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatBotTitle = styled.div`
  color: ${colors.black};
  font-weight: 500;
  font-size: 16px;
`;

const ChatBotConnected = styled.div`
  color: ${colors.black35};
  font-weight: 600;
  font-size: 14px;
`;

const ChatBar = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 25px;
  background-color: #fcfcfc;
  border-radius: 0 0 12px 12px;
  gap: 20px;
  width: auto;
`;

const ChatBarInput = styled.input`
  background-color: ${colors.black3};
  outline-color: rgba(0, 0, 0, 0.08);
  border: none;
  padding: 14px 25px;
  border-radius: 100px;
  width: -webkit-fill-available;

  ::placeholder {
    color: ${colors.black35};
  }
`;

const SendIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

const ChatBotMessages = styled.div`
  display: flex;
  position: relative;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column-reverse;
  padding: 30px 25px;
  gap: 10px;
`;

const HotelMessageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 12px 20px;
  background-color: ${colors.white};
  border-radius: 12px;
  max-width: 60%;
`;

const HotelName = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const HotelMessage = styled.div`
  font-size: 14px;
  overflow-wrap: break-word;
`;

const UserMessageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding: 12px 20px;
  background-color: ${colors.green};
  border-radius: 12px;
  max-width: 60%;
  color: ${colors.white};
`;
const UserName = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
const UserMessage = styled.div`
  font-size: 14px;
  overflow-wrap: break-word;
`;

const OptionsMessageItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Option = styled.div`
  background-color: ${colors.purple};
  color: ${colors.white};
  cursor: pointer;
  border-radius: 60px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
`;

const Image = styled.img`
  border-radius: 14px;
  max-width: 100%;
`;

const IFrame = styled.iframe`
  border-radius: 14px;
  max-width: 100%;
`;

const SeeMoreLink = styled(Link)`
  padding: 10px 20px;
  border-radius: 10px;
  background-color: ${colors.orange};
  color: ${colors.white};
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
`;

export default ChatBot;
