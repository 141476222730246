import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL} from "../../utils/constants";

const initialState = {
  title: null,
  questions: [],
  data: null,
  loading: false,
};

export const getFaq = createAsyncThunk("faq/getFaq", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(
      ENDPOINT_URL + "/api/faq?populate=deep&locale=" + language
    );
    return res.data.data.attributes;
  } catch (err) {
    console.log(err);
  }
});

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: {
    [getFaq.pending]: (state) => {
      state.loading = true;
    },
    [getFaq.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.title = payload.title;
      state.questions = payload.question;
      state.data = payload;
    },
    [getFaq.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default faqSlice.reducer;
