import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import { colors } from "../../../utils/colors";
import { FILE_SERVER_URL, svg } from "../../../utils/constants";
import { CircleDecoration } from "../../../utils/styles";

const RoomsAndSuits = ({ myRef, data }) => {
  //============= Animation ============//

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const animXY = {
    visible: { y: 0, opacity: 1, transition: { duration: 1.5 } },
    hidden: { y: -90, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  //============= End Animation ============//

  return (
    <Container ref={myRef}>
      <BodyContainer>
        <ImageDiv>
          <ImageMask>
            <Image
              url={
                FILE_SERVER_URL +
                data.roomsSection.featuredImage.data.attributes.url
              }
            />
          </ImageMask>
          <ImageContainer>
            <ImageContainerItem>
              <svg.safeBoxIcon />
              <span style={{ paddingLeft: 15 }}>
                {parse(data.roomsSection.roomsFeaturesCard.featuredText1)}
              </span>
            </ImageContainerItem>
            <ImageContainerItem>
              <svg.climIcon />
              <span style={{ paddingLeft: 15 }}>
                {parse(data.roomsSection.roomsFeaturesCard.featuredText2)}
              </span>
            </ImageContainerItem>
            <ImageContainerItem>
              <svg.wifiIcon />
              <span style={{ paddingLeft: 15 }}>
                {parse(data.roomsSection.roomsFeaturesCard.featuredText3)}
              </span>
            </ImageContainerItem>
            <ImageContainerItem>
              <svg.barIcon />
              <span style={{ paddingLeft: 15 }}>
                {parse(data.roomsSection.roomsFeaturesCard.featuredText4)}
              </span>
            </ImageContainerItem>
          </ImageContainer>
        </ImageDiv>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={animXY}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextContainer>
            <CircleDecoration color={colors.gold} top="25px" right="150px" />
            <CategoryTitle>{data.roomsSection.title}</CategoryTitle>
            <Title color={colors.black}>{data.roomsSection.subTitle}</Title>
            <Paragraph color={colors.black}>
              {parse(data.roomsSection.description)}
            </Paragraph>
            <ReadMoreBtn to={"/chambres"}>
              {data.roomsSection.readMoreButton}
            </ReadMoreBtn>
          </TextContainer>
        </motion.div>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin: 0 35px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    margin: 100px 60px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  flex-direction: column;
  margin-top: 30px;
  max-width: 600px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    padding-left: 100px;
  }
`;

const CategoryTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 0;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: ${(props) => props.color};
  margin: 10px 0;
`;

const Paragraph = styled.div`
  font-size: 16px;
  color: ${(props) => props.color};
  margin-bottom: 30px;
`;

const ImageContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 58px;
  padding: 30px 20px;
  border-radius: 10px;
  background-color: ${colors.white};
  box-shadow: 0px 6px 30px ${colors.black6};

  @media only screen and (min-width: 768px) {
    display: flex;
    right: -40px;
  }
  @media only screen and (min-width: 1400px) {
    left: -100px;
    right: auto;
  }
`;

const ImageContainerItem = styled.div`
  display: flex;
  color: ${colors.black};
  font-size: 13px;
  margin: 4px 0;

  && p {
    margin: 0;
  }
`;

const ImageMask = styled.div`
  overflow: hidden;
  display: flex;
  position: relative;
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
`;

const ImageDiv = styled.div`
  display: flex;
  position: relative;
  max-width: 400px;
  width: 100%;
`;

const Image = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform 2s;

  &:hover {
    transform: scale(1.2);
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
    max-width: 425px;
    height: 410px;
  }
`;

const ReadMoreBtn = styled(Link)`
  background-color: ${colors.green};
  color: ${colors.white};
  border-radius: 10px;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;

export default RoomsAndSuits;
