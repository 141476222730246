import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getDeviceToken } from "../../firebase";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";
import { handleToast } from "../../utils/toast/toast";
// const userToken = localStorage.getItem("userToken")
//   ? localStorage.getItem("userToken")
//   : null;
import i18n from "i18next";
import { getCountryByCode } from "../../utils/countries";
// ...
const { t } = i18n;
const initialState = {
  loading: false,
  userInfo: {
    firstName: null,
    lastName: null,
    picture: {},
  },
  userInfoLabels: null,
  userToken: null,
  userEmail: null,
  error: null,
  success: false,
  users: null,
};
export const registerUser = createAsyncThunk(
  "user/register",
  async (data, { rejectWithValue }) => {
    const deviceToken = await getDeviceToken();

    const user = {
      username: data.data.email,
      email: data.data.email,
      password: data.data.password,
      deviceToken: deviceToken,
    };

    try {
      axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
      const res = await axios.post(
        ENDPOINT_URL + "/api/auth/local/register",
        user
      );
      const profile = {
        data: {
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          country: getCountryByCode(data.selectedCountry),
          user: res.data.user.id,
        },
      };

      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.jwt;
      await axios.post(ENDPOINT_URL + "/api/profiles", profile);

      localStorage.setItem("userToken", res.data.jwt);
      localStorage.setItem("userEmail", data.data.email)
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    const user = {
      identifier: data.email,
      password: data.password,
    };
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.post(ENDPOINT_URL + "/api/auth/local", user);
      localStorage.setItem("userToken", res.data.jwt);
      data.data.email("userEmail", data.email);
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getUserInfosLabels = createAsyncThunk(
  "user/getUserInfoLabels",
  async (arg, { rejectWithValue }) => {
    try {
      var language = JSON.parse(localStorage.getItem("language"));
      axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
      const res = await axios.get(
        ENDPOINT_URL + "/api/user-info?locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (arg, { rejectWithValue }) => {
    try {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("userToken");
      const res = await axios.get(
        ENDPOINT_URL + "/api/users/me?populate[0]=profile.picture"
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "user/updateUserDetails",
  async (data, { rejectWithValue }) => {
    try {
      const profile = {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      };
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("userToken");
      const res = await axios.put(
        ENDPOINT_URL + `/api/profiles/${data.user.userInfo.profile.id}`,
        profile
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const uploadAvatarImage = createAsyncThunk(
  "user/uploadAvatarImage",
  async (data, { rejectWithValue }) => {
    try {
      const profile = {
        data: {
          pictureUrl: data.image,
        },
      };

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("userToken");

      const res = await axios.put(
        ENDPOINT_URL +
          `/api/profiles/${data.user.userInfo.profile.id}?populate=deep`,
        profile
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error.message) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const uploadImage = createAsyncThunk(
  "user/uploadImage",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("files", data.event.target.files[0]);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("userToken");
      axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

      const uploadResult = await axios.post(
        ENDPOINT_URL + "/api/upload",
        formData
      );
      const profile = {
        data: {
          picture: uploadResult.data[0],
        },
      };

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("userToken");
      axios.defaults.headers.put["Content-Type"] = "multipart/form-data";

      const res = await axios.put(
        ENDPOINT_URL +
          `/api/profiles/${data.user.userInfo.profile.id}?populate=deep`,
        profile
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error.message) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const googleLogin = createAsyncThunk(
  "user/googleLogin",
  async (idToken, { rejectWithValue }) => {
    try {
      const deviceToken = await getDeviceToken();
      const res = await axios.get(
        ENDPOINT_URL + "/api/auth/google/callback/" + idToken
      );
      // Extracting user email from the response
      const userEmail = res.data.user.email;


      await axios.put(ENDPOINT_URL + "/api/users/" + res.data.user.id, {
        deviceToken: deviceToken,
      });

      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.jwt;
      const userMe = await axios.get(
        ENDPOINT_URL + "/api/users/me?populate=deep"
      );
      const points = await axios.get(ENDPOINT_URL + "/api/vip-card-config");

      if (!userMe.data.profile) {
        await axios.post(ENDPOINT_URL + "/api/google-auth", res);
        handleToast(
          t("registrationPointsPart1") +
            points.data.data.attributes.inscriptionPoints +
            t("registrationPointsPart2")
        );
      }
      localStorage.setItem("userToken", res.data.jwt);
      localStorage.setItem("userEmail", userEmail)
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data, { rejectWithValue }) => {
    const details = {
      code: data.code,
      password: data.data.newPassword,
      passwordConfirmation: data.data.confirmNewPassword,
    };

    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/auth/reset-password?locale=" + language,
        details
      );
      localStorage.setItem("userToken", res.data.jwt);
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/users" 
      );
     // console.log("users", res.data)
      return res.data;
      
    } catch (err) {
      console.log(err)
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken"); // deletes token from storage
      localStorage.removeItem("userEmail");
      state.loading = false;
      state.userInfo = {
        firstName: null,
        lastName: null,
        picture: {},
      };
      state.userEmail = null;
      state.userToken = null;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: {
    // User Register

    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.user;
      state.userToken = payload.jwt;
      state.success = true;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // User Login

    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.user;
      state.userToken = payload.jwt;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // User Info Labels

    [getUserInfosLabels.pending]: (state) => {
      state.loading = true;
    },
    [getUserInfosLabels.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfoLabels = payload;
    },
    [getUserInfosLabels.rejected]: (state) => {
      state.loading = false;
    },

    // User Details

    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
    },
    [getUserDetails.rejected]: (state) => {
      state.loading = false;
    },

    // Update User Details

    [updateUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo.profile.firstName = payload.data.attributes.firstName;
      state.userInfo.profile.lastName = payload.data.attributes.lastName;
    },
    [updateUserDetails.rejected]: (state) => {
      state.loading = false;
    },

    // Upload User Avatar Image

    [uploadAvatarImage.pending]: (state) => {
      state.loading = true;
    },
    [uploadAvatarImage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo.profile.pictureUrl = payload.data.attributes.pictureUrl;
    },
    [uploadAvatarImage.rejected]: (state) => {
      state.loading = false;
    },

    // Upload User Image

    [uploadImage.pending]: (state) => {
      state.loading = true;
    },
    [uploadImage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo.profile.picture =
        payload.data.attributes.picture.data.attributes;
    },
    [uploadImage.rejected]: (state) => {
      state.loading = false;
    },

    // Google Login

    [googleLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [googleLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.user;
      state.userToken = payload.jwt;
      state.success = true;
    },
    [googleLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Reset Password & Login

    [resetPassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.user;
      state.userToken = payload.jwt;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    //getUSers
    [getUsers.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.users = payload;
      state.success = true;
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
