import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { colors } from "../../../utils/colors";
import { FILE_SERVER_URL, svg } from "../../../utils/constants";

const HomeFooter = ({ data }) => {
  //============= Animation ============//

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const animXY = {
    visible: { y: 0, opacity: 1, transition: { duration: 1.8 } },
    hidden: { y: -150, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  //============= End Animation ============//
  return (
    <Container>
      <FooterCover
        url={FILE_SERVER_URL + data.endPageSection.coverImage.data.attributes.url}
      >
        <FooterCoverFade>
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={animXY}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title>{data.endPageSection.featuredText}</Title>
            <SubTitle>{data.endPageSection.subText}</SubTitle>
            {/* <TelephoneContainer>
              <svg.telephoneIcon /> {data.endPageSection.phoneNumber}
            </TelephoneContainer> */}
          </motion.div>
        </FooterCoverFade>
      </FooterCover>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  flex-direction: column;
  text-align: center;
  margin: 10px;
`;

const FooterCover = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 400px;
  width: 100%;
  object-fit: cover;
  background-image: url(${(props) => props.url});
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  background-repeat: no-repeat;
  transition: transform 2s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const FooterCoverFade = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  border-radius: 10px;
  background-color: ${colors.black50};
  color: ${colors.white};
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  padding: 0 20px;
`;

const SubTitle = styled.h3`
  font-size: 16px;
  font-weight: lighter;
  margin: 55px 0 0 0;
`;

const TelephoneContainer = styled.h4`
  font-size: 30px;
  font-weight: 500;
  margin: 10px 0 0 0;
`;

export default HomeFooter;
