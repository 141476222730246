import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MAP_EMBED, getLanguageId, images } from "../../utils/constants";
import { colors } from "../../utils/colors";
import QRCode from "react-qr-code";
import PassengersTable from "./PassengersTable";
import RoomsTable from "./RoomsTable";
import CancellationTable from "./CancellationTable";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { getHotelBooking } from "../../services/Reservation/hotelReservationSlice";
import moment from "moment";
import { postReservation } from "../../services/Reservation/reservationSlice";
import { getUsers } from "../../services/User/userSlice";

const Voucher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const hotelBooking = useSelector(
    (state) => state.hotelReservation.hotelBooking
  );
  
  let roomData;
  
  if(hotelBooking) {
     roomData = hotelBooking.HotelBookingCreationRES.OrderDetails.Rooms.Room;
  }
  
  let roomsArray;  
  if ( Array.isArray(roomData)) {
    // If roomData is already an array, use it as is
    roomsArray = roomData;
  } else {
    // If roomData is a single object, convert it into an array
    roomsArray = [roomData];
  }
  


  const bookingDetails = useSelector(
    (state) => state.hotelReservation.bookingDetails
  );

  const hotelQuotes = useSelector(
    (state) => state.hotelReservation.hotelQuotes
  );

  const language = useSelector((state) => state.language);

  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("orderId");
  const apiUrl = `https://ipay.clictopay.com/payment/rest/getOrderStatus.do?orderId=${orderId}&language=fr&password=Ua8zEu5N5&userName=0362616518`;

  const [activeOrderId, setActiveOrderId] = useState(false);
  const [orderNumber, setOrderNumber] = useState();
  const [hotelBookingRequested, setHotelBookingRequested] = useState(false);

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.OrderStatus === 2 && data.ErrorCode === "0") {
          setActiveOrderId(true);
          setOrderNumber(data.OrderNumber);
          if (!hotelBookingRequested && hotelBooking == null) {
            setHotelBookingRequested(true);
            dispatch(
              getHotelBooking({
                fromDate: hotelQuotes.HotelQuoteRES.FromDate,
                toDate: hotelQuotes.HotelQuoteRES.ToDate,
                quoteId: hotelQuotes.HotelQuoteRES.Hotel.QuoteId,
                rooms: bookingDetails.rooms,
                client: bookingDetails.client,
                language: getLanguageId(language.lang.toString()),
                price: bookingDetails.total,
              })
            );
          }
        } else {
          setActiveOrderId(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [orderId, hotelBookingRequested]);

  useEffect(() => {
    //console.log(hotelBooking);
  }, [language, hotelBooking]);

  return (
    <Container>
      {hotelBooking && hotelBooking.HotelBookingCreationRES && (
        <>
          <ReactToPrint
            trigger={() => <PrintButton>{t("print")}</PrintButton>}
            content={() => componentRef.current}
          />
          <Printing ref={componentRef}>
            <HeaderContainer>
              <LogoImg src={images.riadhPalmsDarkLogo} width={140} alt="Logo" />
              Bon voucher N°
              {/* {hotelBooking.HotelBookingCreationRES.OrderDetails.$.Id} */}
              <HorizontalLine />
              <CheckInContainer>
                <a href={hotelBooking.HotelBookingCreationRES.HotelCheckinUrl}>
                  {hotelBooking.HotelBookingCreationRES.HotelCheckinUrl && (
                    <QRCode
                      size={65}
                      style={{ height: "65", maxWidth: "100%", width: "100%" }}
                      value={
                        hotelBooking.HotelBookingCreationRES.HotelCheckinUrl
                      }
                      viewBox={`0 0 65 65`}
                    />
                  )}
                </a>
                <CheckIn>
                  {t("voucherCheckInFrom")}
                  {" " +
                    hotelBooking.HotelBookingCreationRES
                      .HotelCheckinStartingDate}
                </CheckIn>
                <CheckInUrl
                  href={hotelBooking.HotelBookingCreationRES.HotelCheckinUrl}
                >
                  {hotelBooking.HotelBookingCreationRES.HotelCheckinUrl}
                </CheckInUrl>
              </CheckInContainer>
            </HeaderContainer>
            <BodyContainer>
              <HotelInfos>
                <HotelTitle>Riadh Palms Resort & Spa</HotelTitle>
                <HotelSubTitle>
                  {hotelBooking.HotelBookingCreationRES.Category +
                    " " +
                    t("voucherHotelCategory")}
                </HotelSubTitle>
                <HotelInfoItem>
                  {t("paymentRef")}
                  <ReservationDate>{orderNumber}</ReservationDate>
                </HotelInfoItem>
                <HotelInfoItem>
                  {t("total")}:
                  <ReservationDate>
                    {bookingDetails.total + " DT"}
                  </ReservationDate>
                </HotelInfoItem>
                <HotelInfoItem>
                  {t("amountDue")}
                  <ReservationDate>
                    {bookingDetails.amountDue + " DT"}
                  </ReservationDate>
                </HotelInfoItem>
                <HotelInfoItem>
                  {t("voucherFrom")}
                  <ReservationDate>
                    {hotelBooking.HotelBookingCreationRES.FromDate}
                  </ReservationDate>
                </HotelInfoItem>
                <HotelInfoItem>
                  {t("voucherTo")}
                  <ReservationDate>
                    {hotelBooking.HotelBookingCreationRES.ToDate}
                  </ReservationDate>
                </HotelInfoItem>
                <HotelInfoItem>
                  {t("voucherReference")}
                  <strong>{t("voucherRefInfo")}</strong>
                </HotelInfoItem>
                <HotelInfoItem>
                  {t("voucherConfidentialCode")}
                  <strong>
                    {/* {
                      hotelBooking.HotelBookingCreationRES.OrderDetails.$
                        .Password
                    } */}
                  </strong>
                </HotelInfoItem>
                <HotelInfoItem>
                  {t("voucherFrom")}
                  <ReservationDate>
                    {hotelBooking.HotelBookingCreationRES.Started.GMT}
                  </ReservationDate>
                </HotelInfoItem>
              </HotelInfos>
              <RoomsTable
                data={
                  hotelBooking.HotelBookingCreationRES.OrderDetails.Rooms.Room
                }
              />
              <GoldText>{t("voucherPeopleList")}</GoldText>
              <PassengersTable
                data={
                  hotelBooking.HotelBookingCreationRES.OrderDetails.Rooms.Room
                }
              />
              <CancellationTable
                data={
                  hotelBooking.HotelBookingCreationRES.CancellationPolicy
                    .FromDate
                }
              />
              <IFrame loading="lazy" src={MAP_EMBED}></IFrame>
              <HotelInfoItem>
                <strong>{t("voucherAdresse")} :</strong> Avenue 14 Janvier -
                Sousse
              </HotelInfoItem>
              <HotelInfoItem>
                <strong>{t("voucherTelephone")} :</strong> +21629999001
              </HotelInfoItem>
              <HotelInfoItem>
                <strong>{t("voucherFax")} :</strong>
                +21673228347
              </HotelInfoItem>
              <HotelInfoItem>
                <strong>{t("voucherWebsite")} :</strong>
                www.hotelriadhpalms.com
              </HotelInfoItem>
              <HotelInfoItem>
                <strong>{t("voucherEmail")} :</strong>
                resa2@hotelriadhpalms.com - reception@hotelriadhpalms.com -
                resa@hotelriadhpalms.com - main.courante@hotelriadhpalms.com
              </HotelInfoItem>
              <HotelInfoItem>
                <GoldText>{t("voucherPaimentMode")} :</GoldText>
                {t("voucherBankCard")}
              </HotelInfoItem>
              {t("voucherMerci")}
            </BodyContainer>
          </Printing>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Printing = styled.div`
  position: relative;
  padding: 40px 60px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  gap: 10px;
  margin-bottom: 20px;
`;

const LogoImg = styled.img`
  align-self: flex-end;
`;

const HorizontalLine = styled.div`
  border-top: 1px solid ${colors.black15};
  margin: 20px 0px;
  width: 100%;
`;

const CheckInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckIn = styled.div`
  font-size: 13px;
`;

const CheckInUrl = styled.a`
  color: ${colors.black65};
  font-size: 13px;
  font-weight: normal;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  gap: 10px;
`;

const HotelInfos = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 10px;
  margin-bottom: 20px;
`;

const HotelTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const HotelSubTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const HotelInfoItem = styled.div`
  display: flex;
  gap: 8px;
`;

const ReservationDate = styled.div`
  color: ${colors.gold};
  font-weight: 500;
`;

const GoldText = styled.div`
  color: ${colors.gold};
`;

const IFrame = styled.iframe`
  border-radius: 14px;
  height: 250px;
  width: 100%;
  margin: 20px 0;
  @media print {
    display: none;
  }
`;

const PrintButton = styled.button`
  display: flex;
  padding: 15px 18px;
  border: none;
  border-radius: 10px;
  background-color: ${colors.green};
  color: ${colors.white};
  font-weight: 500;
  margin: 40px 60px 0px 60px;
  gap: 8px;
  cursor: pointer;
`;

export default Voucher;
