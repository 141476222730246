import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";
import { slide as Menu } from "react-burger-menu";
import ReactGA from "react-ga";

import { colors } from "../../utils/colors";
import { FILE_SERVER_URL, svg } from "../../utils/constants";
import SeoHelmet from "../../utils/seo";

import CoverHeader from "./components/CoverHeader";
import BodyHeader from "./components/BodyHeader";
import RoomAndSuits from "./components/RoomsAndSuits";
import MeetingsAndEvents from "./components/MeetingsAndEvents";
import BeginExperience from "./components/BeginExperience";
import ReservationBar from "./components/HomeHeader/ReservationBar";
import CoverIcons from "./components/HomeHeader/CoverIcons";

import { getHome } from "../../services/Home/homeSlice";
import PrimaryMobileMenu from "../../components/header/PrimaryMobileMenu";
import InitialAnimation from "./components/InitialAnimation";
import HomeFooter from "./components/HomeFooter";
import Testimonials from "./components/Testimonials";
import InitialRegister from "./components/InitialRegister";
import ReservationBarWeb from "./components/HomeHeader/ReservationBarWeb";
import moment from "moment";
import InstallButton from "../../components/installButton/InstallButton";
import { trackInstallButtonClicked } from "../../Analytics";
import { incrementNbInstall, setDeferredPrompt } from "../../services/InstallButton/installButtonSlice";
import { getUsers } from "../../services/User/userSlice";

const Home = () => {
  const dispatch = useDispatch();
  const deferredPrompt = useSelector((state) => state.installButton.deferredPrompt);
  
  useEffect(() => {
    dispatch(getUsers());
  },[])
  const allUsers = useSelector((state) => state.user.users);
  
 
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      // Dispatch the action to set deferredPrompt in the Redux store
      dispatch(setDeferredPrompt(e));
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [dispatch, deferredPrompt]);

  
 
 

  const hotelReservation = useSelector((state) => state.hotelReservation);

  const [roomIds, setRoomIds] = useState([
    {
      adult: 2,
      child: {
        age: [],
        number: 0,
      },
    },
  ]);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  let arrivalDatee;
  if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
    // If it's Monday to Friday, set arrivalDate to Friday of this week
    arrivalDatee = moment().day(5); // 5 = Friday
  } else {
    // If it's Saturday or Sunday, set arrivalDate to Friday of next week
    arrivalDatee = moment().add(1, "weeks").day(5); // Add 1 week and set to Friday
  }

  const [arrivalDate, setArrivalDate] = useState(arrivalDatee);
  const [departureDate, setDepartureDate] = useState(
    moment(arrivalDate).add(2, "days")
  );

  const [initialArrivalDate, setInitialArrivalDate] = useState(arrivalDatee);
  const [initialDepartureDate, setInitialDepartureDate] = useState(
    moment(arrivalDate).add(2, "days")
  );
  const [initialAdults, setInitialAdults] = useState(2);
  const [initialRooms, setInitialRooms] = useState(1);
  const [initialRoomIds, setInitialRoomIds] = useState([
    {
      adult: 2,
      child: {
        age: [0],
        number: 0,
      },
    },
  ]);

  useEffect(() => {
    setInitialAdults(2);
    setInitialRooms(1);
    setInitialArrivalDate(arrivalDatee);
    setInitialDepartureDate(moment(arrivalDate).add(2, "days"));
    setInitialRoomIds([
      {
        adult: 2,
        child: {
          age: [0],
          number: 0,
        },
      },
    ]);
  }, []);

  
  const data = useSelector((state) => state.home.data);
  const language = useSelector((state) => state.language);
  const mainMenu = useSelector((state) => state.menu.mainMenu);
  const user = useSelector((state) => state.user.userInfo);

  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);

  const executeScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    dispatch(getHome());
  }, [dispatch, language]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return data !== null ? (
    <div style={{ marginTop: -10, paddingTop: 10 }}>
      <InitialAnimation isLoading={isLoading} />
      {user && user.profile == null && <InitialRegister />}

      {mainMenu && (
        <>
          <Menu
            right
            width={"264px"}
            isOpen={responsiveMenuVisible}
            onClose={() => setResponsiveMenuVisible(false)}
            customBurgerIcon={false}
            disableAutoFocus
          >
            <PrimaryMobileMenu
              pathname={null}
              mainMenu={mainMenu}
              mainColorValue={colors.black}
              secondaryColorValue={colors.lightBlack}
            ></PrimaryMobileMenu>
          </Menu>
        </>
      )}

      {data && data.seo && <SeoHelmet props={data} url={"/"}></SeoHelmet>}
      {!isLoading && (
        <Container>
          <Cover>
            <CoverVideo
              autoPlay
              muted
              loop
              src={FILE_SERVER_URL + data.hero.heroVideo.data.attributes.url}
            />
            <CoverFade>
              <CoverHeader
                setResponsiveMenuVisible={setResponsiveMenuVisible}
              />
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 1 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CoverTitle>
                  {data.hero.heroText}
                  
                </CoverTitle>
                
                <CoverSubTitle>
                  {data.hero.heroSubText}
                  <FourStars>
                    <svg.stars />         
                  </FourStars> 
                  
                  
                </CoverSubTitle>
              </motion.div>
              <ReservationBar
                roomIds={roomIds}
                adults={adults}
                children={children}
                rooms={rooms}
                arrivalDate={arrivalDate}
                departureDate={departureDate}
                setRoomIds={setRoomIds}
                setAdults={setAdults}
                setChildren={setChildren}
                setRooms={setRooms}
                setArrivalDate={setArrivalDate}
                setDepartureDate={setDepartureDate}
              />
              <CoverIcons data={data.hero.socialButtons} />
            </CoverFade>
          </Cover>
          <BodyHeader
            executeScroll={executeScroll}
            firstRef={firstRef}
            secondRef={secondRef}
            thirdRef={thirdRef}
          />
          <ReservationBarWeb
            roomIds={roomIds}
            adults={adults}
            children={children}
            rooms={rooms}
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            setRoomIds={setRoomIds}
            setAdults={setAdults}
            setChildren={setChildren}
            setRooms={setRooms}
            setArrivalDate={setArrivalDate}
            setDepartureDate={setDepartureDate}
          />
          <RoomAndSuits myRef={firstRef} data={data} />
          <BeginExperience myRef={secondRef} data={data} />
          <MeetingsAndEvents myRef={thirdRef} data={data} />
          <Testimonials />
          <HomeFooter data={data} />
        </Container>
      )}
    </div>
  ) : null;
};

const Container = styled.div`
  position: relative;
  flex: 1;
`;

const Cover = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 10px;
  height: 660px;
  background-image: url(${(props) => props.url});
  background-position-y: 0px;
  background-size: cover;
  background-repeat: no-repeat;
`;

const CoverVideo = styled.video`
  position: relative;
  display: flex;
  border-radius: 10px;
  height: 660px;
  width: 100%;
  object-fit: cover;
`;

const CoverFade = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: ${colors.black35};
`;

const CoverTitle = styled.h1`
  font-size: 36px;
  color: ${colors.white};
  font-weight: 600;
  margin: 0;
  margin-top: 60px;

  @media only screen and (min-width: 768px) {
    font-size: 62px;
    margin-top: 90px;
  }
`;

const CoverSubTitle = styled.h4`
  display: flex;
  font-size: 24px;
  color: ${colors.white};
  font-weight: 300;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 20px;

  @media only screen and (min-width: 1300px) {
    margin-bottom: 90px;
    flex-direction: row;
  }
`;

const FourStars = styled.div`
  margin-left: 12px;
`;

export default Home;
