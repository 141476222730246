import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { ENDPOINT_URL } from "../../utils/constants";
import { currOptions } from "../../pages/Reservation/components/Currencies/CurrenciesOptions";

const API_KEY = "cur_live_ncbykQWkev7Mh1vvRT4DGkeEj9mVGzqMfMSSbp2V";
const API_URL = `https://api.currencyapi.com/v3/latest?apikey=${API_KEY}&base_currency=TND&`;

const today = new Date();
const currentDayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
let arrivalDatee;

if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
  // If it's Monday to Friday, set arrivalDate to Friday of this week
  arrivalDatee = moment().day(5); // 5 = Friday
} else {
  // If it's Saturday or Sunday, set arrivalDate to Friday of next week
  arrivalDatee = moment().add(1, "weeks").day(5); // Add 1 week and set to Friday
}

const initialState = {
  hotelRooms: null,
  hotelQuotes: null,
  hotelBooking: null,
  bookingDetails: {
    total: null,
    amountDue: null,
    rooms: null,
    client: null,
  },
  defaultCurrency: { value: "1", label: "Dinar Tunisien", code: "TND" },
  currencyValue: 1.0,
  arrivalDate: arrivalDatee,
  departureDate: moment(arrivalDatee).add(2, "days"),
  roomIds: [
    {
      adult: 2,
      child: {
        age: [],
        number: 0,
      },
    },
  ],
  quoteRoomIds: [],
  loading: false,
  error: null,
};

export const getHotelRooms = createAsyncThunk(
  "hotelReservation/getHotelRooms",
  async (hotelData, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/hotel-search",
        hotelData
      );
      console.log(res.data);
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getHotelQuote = createAsyncThunk(
  "hotelReservation/getHotelQuote",
  async (quoteData, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/hotel-quote",
        quoteData
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getHotelBooking = createAsyncThunk(
  "hotelReservation/getHotelBooking",
  async (bookingData, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/hotel-booking",
        bookingData
      );
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const fetchCurrency = createAsyncThunk(
  "hotelReservation/fetchCurrency",
  async (currencyData, { rejectWithValue }) => {
    try {
      const res = await axios.get(API_URL);
      const data = res.data.data;

      if (typeof currencyData === "object") {
        return {
          currency: {
            ...data[currencyData.code],
            label: currencyData.label,
          },
        };
      } else {
        // Reservation with Google Params
        const currentCurrency = currOptions.find(
          (item) => item.code === currencyData
        );

        if (!currentCurrency) {
          throw new Error("Currency code not found in options");
        }

        return {
          currency: {
            ...data[currentCurrency.code],
            label: currentCurrency.label,
          },
        };
      }
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const hotelReservationSlice = createSlice({
  name: "hotelReservation",
  initialState,
  reducers: {
    updateReservationInfos: (state, { payload }) => {
      state.arrivalDate = payload.arrivalDate.format("DD/MM/YYYY");
      state.departureDate = payload.departureDate.format("DD/MM/YYYY");
      state.roomIds = payload.roomIds;
    },
    updateQuoteRoomIds: (state, { payload }) => {
      state.quoteRoomIds = payload;
    },
    updateBookingDetails: (state, { payload }) => {
      const { total, amountDue, rooms, client } = payload.bookingDetails;

      state.bookingDetails = {
        total: total,
        amountDue: amountDue,
        rooms: rooms,
        client: client,
      };
    },
    initializeHotelBooking: (state) => {
      state.hotelBooking = null;
    },
    initializeHotelRooms: (state) => {
      state.hotelRooms = null;
    },
  },
  extraReducers: {
    [getHotelRooms.pending]: (state) => {
      state.loading = true;
    },
    [getHotelRooms.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hotelRooms = payload;
    },
    [getHotelRooms.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [getHotelQuote.pending]: (state) => {
      state.loading = true;
    },
    [getHotelQuote.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hotelQuotes = payload;
    },
    [getHotelQuote.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [getHotelBooking.pending]: (state) => {
      state.loading = true;
    },
    [getHotelBooking.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hotelBooking = payload;
    },
    [getHotelBooking.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [fetchCurrency.pending]: (state) => {
      state.loading = true;
    },
    [fetchCurrency.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.defaultCurrency = payload.currency;
      state.currencyValue = payload.currency.value.toFixed(4);
    },
    [fetchCurrency.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  updateReservationInfos,
  updateBookingDetails,
  initializeHotelBooking,
  initializeHotelRooms,
  updateQuoteRoomIds,
} = hotelReservationSlice.actions;

export default hotelReservationSlice.reducer;
