import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ru";

import { colors } from "../../../../utils/colors";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { updateReservationInfos } from "../../../../services/Reservation/hotelReservationSlice";
import { useState } from "react";

const Details = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const [roomIds, setRoomIds] = useState([
    {
      adult: 2,
      child: {
        age: [0],
        number: 0,
      },
    },
  ]);
  const hotelReservation = useSelector((state) => state.hotelReservation);


  useEffect(() => {
    const isInvalidDates =
      !moment(hotelReservation.arrivalDate, "DD/MM/YYYY").isValid() ||
      !moment(hotelReservation.departureDate, "DD/MM/YYYY").isValid();

    if (
      isInvalidDates) {
      const today = new Date();
      const currentDayOfWeek = today.getDay();

      let arrivalDate;
      if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
        // If it's Monday to Friday, set arrivalDate to Friday of this week
        arrivalDate = moment().day(5); // 5 = Friday
      } else {
        // If it's Saturday or Sunday, set arrivalDate to Friday of next week
        arrivalDate = moment().add(1, "weeks").day(5); // Add 1 week and set to Friday
      }

      const departureDate = moment(arrivalDate).add(2, "days");

      // Dispatch the action to update the Redux state
      dispatch(
        updateReservationInfos({
          arrivalDate: arrivalDate,
          departureDate: departureDate,
          roomIds: roomIds,
        })
      );
    }
  }, []);

  return (
    <>
      {hotelReservation && (
        <DetailsContainer>
          <DetailsItem>
            <DetailTitle>{t("arrivalLabel")}:</DetailTitle>
            <DetailValue>
              {moment(hotelReservation.arrivalDate, "DD/MM/YYYY")
                .locale(language.lang)
                .format("DD MMM YYYY")}
            </DetailValue>
          </DetailsItem>
          <DetailsItem>
            <DetailTitle>{t("leavingLabel")}:</DetailTitle>
            <DetailValue>
              {moment(hotelReservation.departureDate, "DD/MM/YYYY")
                .locale(language.lang)
                .format("DD MMM YYYY")}
            </DetailValue>
          </DetailsItem>
          <DetailsItem>
            <DetailTitle>{t("peopleLabel")}:</DetailTitle>
            <DetailValue>
              {hotelReservation.roomIds.reduce((acc, person) => {
                return acc + person.adult;
              }, 0)}{" "}
              {t("adults")},{" "}
              {hotelReservation.roomIds.reduce((acc, person) => {
                return acc + person.child.number;
              }, 0)}{" "}
              {t("children")}
            </DetailValue>
          </DetailsItem>
        </DetailsContainer>
      )}
    </>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 30px 35px;
  gap: 50px;

  @media only screen and (min-width: 992px) {
    padding: 50px;
    flex-direction: column;
    gap: 0px;
  }
`;

const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailTitle = styled.p`
  margin: 0 0 4px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.black};
`;

const DetailValue = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${colors.black};
  border-bottom: 0;

  @media only screen and (min-width: 992px) {
    margin: 0 0 14px 0;
    border-bottom: 1px solid ${colors.black4};
    padding-bottom: 14px;
  }
`;

const TotalValue = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
`;

const TotalValueSpan = styled.span`
  margin-right: 24px;
  height: 20px;
  width: 4px;
  background-color: ${colors.purple};
`;

export default Details;
