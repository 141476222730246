import styled from "styled-components";
import { colors } from "../colors";

export const CircleDecoration = styled.div`
  display: none;
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 6px 6px 10px ${colors.black8};
  background-color: ${(props) => props.color};

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const BlurContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  background-color: rgba(265, 265, 265, 0.35);
  backdrop-filter: blur(10px);
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const BlurContainerText = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.white};
`;

export const ContinueButton = styled.button`
  padding: 18px 80px;
  border-radius: 10px;
  border: 0;
  background-color: ${colors.purple};
  color: ${colors.white};
  width: min-content;
  align-self: flex-end;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const Loader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyText = styled.div`
  color: ${colors.black50};
`;

export const TitleTyping = styled.div`
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: ${colors.pink};
    }
  }
`;
