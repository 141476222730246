export const colors = {
  black: "#101010",
  lightBlack: "#2C2C2C",
  lightBlack50: "rgba(44, 44, 44, 0.5)",
  beige: "#bcb1a6",
  black0: "rgba(0, 0, 0, 0)",
  black2: "rgba(0, 0, 0, 0.02)",
  black3: "rgba(0, 0, 0, 0.03)",
  black4: "rgba(0, 0, 0, 0.04)",
  black6: "rgba(0, 0, 0, 0.06)",
  black8: "rgba(0, 0, 0, 0.08)",
  black15: "rgba(0, 0, 0, 0.15)",
  black35: "rgba(0, 0, 0, 0.35)",
  black50: "rgba(0, 0, 0, 0.5)",
  black65: "rgba(0, 0, 0, 0.65)",
  black75: "rgba(0, 0, 0, 0.75)",
  white: "#ffffff",
  lightWhite: "#f0f0f0",
  white8: "rgba(255,255,255, 0.08)",
  white50: "rgba(255,255,255, 0.5)",
  white80: "rgba(255,255,255, 0.8)",
  gray: "#a8a8a8",
  green: "#3FBBAE",
  green6: "#3FBBAE06",
  green8: "#3FBBAE08",
  green10: "#3FBBAE10",
  greenHover: "#37B3A6",
  pink: "#EA486E",
  pink10: "#EA486E10",
  pinkHover: "#EA4874",
  purple: "#7966C4",
  purple6: "#7966C406",
  purple8: "#7966C408",
  purple10: "#7966C410",
  purple35: "#7966C435",
  purpleHover: "#7966B6",
  gold: "#c9b200",
  goldHover: "#d0bb14",
  blue: "#29A2FF",
  ocean: "#629BBC",
  ocean6: "#629BBC06",
  ocean8: "#629BBC08",
  ocean10: "#629BBC10",
  ocean20: "#629BBC20",
  blueGreen: "#3FAABB",
  blueGreen6: "#3FAABB06",
  blueGreen10: "#3FAABB10",
  orange: "#EF9865",
  orange10: "#EF986510",
};
