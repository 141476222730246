import { useLocation } from "react-router-dom";
import Reservation from "../pages/Reservation";
import queryString from "query-string";

const ReservationWithQuery = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
  
    return <Reservation {...queryParams} />;
  };

  export default ReservationWithQuery;