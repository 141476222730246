import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getFirstVisitModal = createAsyncThunk(
  "firstVisitModal/getFirstVisitModal",
  async (arg, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/first-visit-modal?populate=deep&locale=" + language
      );
      return res.data.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const firstVisitModalSlice = createSlice({
  name: "firstVisitModal",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Testimonials
    [getFirstVisitModal.pending]: (state) => {
      state.loading = true;
    },
    [getFirstVisitModal.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getFirstVisitModal.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default firstVisitModalSlice.reducer;
