import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import HomeGalleryModal from "./HomeGalleryModal";
import { FILE_SERVER_URL } from "../../../../utils/constants";

function HomeGallery({ data }) {
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const GalleryImages = [
    {
      name: data.galleryImage1.data.attributes.name,
      uri: FILE_SERVER_URL + data.galleryImage1.data.attributes.url,
    },
    {
      name: data.galleryImage2.data.attributes.name,
      uri: FILE_SERVER_URL + data.galleryImage2.data.attributes.url,
    },
    {
      name: data.galleryImage3.data.attributes.name,
      uri: FILE_SERVER_URL + data.galleryImage3.data.attributes.url,
    },
    {
      name: data.galleryImage4.data.attributes.name,
      uri: FILE_SERVER_URL + data.galleryImage4.data.attributes.url,
    },
  ];

  function getSelectedIndex(uri) {
    return GalleryImages.findIndex((obj) => obj.uri === uri);
  }

  return (
    <>
      {selectedImg && (
        <HomeGalleryModal
          galleryImages={GalleryImages}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
        />
      )}
      <Container>
        <Images>
          {GalleryImages.map((image, index) => (
            <motion.div
              layout  
              whileHover={{
                opacity: 1,
                scale: 1.1,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                transition: {
                  scale: { duration: 1, ease: "easeInOut" },
                },
              }}
              alt={image.name}
              src={image.uri}
              transition={{ delay: 0.1 + index * 0.1 }}
              key={image.name}
              onClick={() => {
                setSelectedIndex(getSelectedIndex(image.uri));
                setSelectedImg(image.uri);
              }}
            >
              <Image
                alt={image.name}
                src={image.uri}
                initial={{ opacity: 0 , x: "100%" }} 
                animate={{opacity: 1,  x: 0 }} 
                exit={{ x: "100%" }}
                transition={{ ease: "easeIn", duration: 1, delay: 2  + index * 0.5 }}
              />
            </motion.div>
          ))}
        </Images>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;

  @media only screen and (max-width: 315px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Image = styled(motion.img)`
  display: flex;
  height: 140px;
  width: 140px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
  

  @media only screen and (min-width: 768px) {
    height: 160px;
    width: 160px;
  }

  @media only screen and (min-width: 992px) {
    height: 246px;
    width: 246px;
  }
  @media only screen and (max-width: 315px) {
    height: 246px;
    width: 246px;
  }
`;

export default HomeGallery;
