import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { colors } from "../../../../../utils/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleStop,
  faCoffee,
  faHamburger,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { handleToast } from "../../../../../utils/toast/toast";
import { useSelector } from "react-redux";

const RoomCard = ({ hotel, room, setCheckedRooms }) => {
  const { t } = useTranslation();
  const reservationData = useSelector((state) => state.hotelReservation);

  const [duration, setDuration] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (room.Available["@code"] === "0" || room.Available["@code"] === "200") {
      if (isChecked) {
        setCheckedRooms((prevCheckedRooms) =>
          prevCheckedRooms.filter((checkedRoom) => {
            return (
              checkedRoom["room@id"] !== room["room@id"] ||
              checkedRoom["@id"] !== room["@id"]
            );
          })
        );
      } else {
        setCheckedRooms((prevCheckedRooms) => [...prevCheckedRooms, room]);
      }
      setIsChecked(!isChecked);
    } else {
      handleToast(t("chambreIndisponible"));
    }
  };

  const adults = Array.from(
    { length: room.roomAdult },
    (_, index) => index + 1
  );
  const children = Array.from(
    { length: room.roomChild["#text"] },
    (_, index) => index + 1
  );

  useEffect(() => {
    setDuration(
      Math.round(
        (new Date(hotel.ToDate.split("/").reverse().join("-")).getTime() -
          new Date(hotel.FromDate.split("/").reverse().join("-")).getTime()) /
          (1000 * 3600 * 24)
      )
    );
  }, []);

  const promotionPercent = Math.round(
    ((parseFloat(room.RateWithoutPromotion["#text"]) -
      parseFloat(room.Rate["#text"])) /
      parseFloat(room.RateWithoutPromotion["#text"])) *
      100
  );

  return (
    <RoomCardContainer
      backgroundColor={isChecked ? colors.beige : colors.white}
      onClick={handleCheckboxChange}
    >
      <TopContainer>
        <TopRowContainer>
          <RoomImage src="https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/269402277.jpg?k=7a9caefdc3ec80268ef426b3ab81c177886c365b3c1ebcaec9c5bd25fb7ce481&o=&hp=1" />
          <RoomDetails>
            <RoomTitle color={isChecked ? colors.white : colors.black}>
              {room.roomTitle}
            </RoomTitle>
            <RoomDescription
              color={
                isChecked
                  ? colors.white80
                  : room.NonRefundable === "False"
                  ? colors.green
                  : colors.pink
              }
            >
              {room.NonRefundable === "False" ? (
                <span>
                  {t("cancellationPolicy")}{" "}
                  <strong>{room.CancellationPolicy.FromDate}</strong>
                </span>
              ) : (
                t("nonAnnulable")
              )}
            </RoomDescription>
            <RoomServicesDesktop>
              <ServiceContainer
                backgroundColor={isChecked ? colors.white8 : colors.blueGreen10}
                color={isChecked ? colors.white80 : colors.blueGreen}
              >
                <FamilyNumberContainer>
                  {adults.map((_, index) => (
                    <FontAwesomeIcon key={index} icon={faUser} />
                  ))}
                  {children.map((_, index) => (
                    <FontAwesomeIcon
                      key={index}
                      icon={faUser}
                      size="xs"
                      opacity={0.65}
                    />
                  ))}
                  {room["room@count"] > 1 && `x${room["room@count"]}`}
                </FamilyNumberContainer>
              </ServiceContainer>
              <ServiceContainer
                backgroundColor={isChecked ? colors.white8 : colors.ocean10}
                color={isChecked ? colors.white80 : colors.ocean}
              >
                <FontAwesomeIcon
                  icon={room["@id"] === "2" ? faCoffee : faHamburger}
                />{" "}
                {room.Title}
              </ServiceContainer>
              {room.Available["@code"] !== "0" &&
              room.Available["@code"] !== "200" &&
              room.Available["@status"] === "ss" ? (
                <ServiceContainer
                  backgroundColor={isChecked ? colors.white8 : colors.pink}
                  color={isChecked ? colors.white80 : colors.white}
                >
                  <FontAwesomeIcon icon={faCircleStop} /> STOP SALE
                </ServiceContainer>
              ) : null}
            </RoomServicesDesktop>
          </RoomDetails>
        </TopRowContainer>
        <RoomServicesMobile>
          <ServiceContainer
            backgroundColor={isChecked ? colors.white8 : colors.blueGreen10}
            color={isChecked ? colors.white80 : colors.blueGreen}
          >
            <FamilyNumberContainer>
              {adults.map((_, index) => (
                <FontAwesomeIcon key={index} icon={faUser} />
              ))}
              {children.map((_, index) => (
                <FontAwesomeIcon
                  key={index}
                  icon={faUser}
                  size="xs"
                  opacity={0.65}
                />
              ))}
              {room["room@count"] > 1 && `x${room["room@count"]}`}
            </FamilyNumberContainer>
          </ServiceContainer>
          <ServiceContainer
            backgroundColor={isChecked ? colors.white8 : colors.ocean8}
            color={isChecked ? colors.white80 : colors.ocean}
          >
            <FontAwesomeIcon
              icon={room["@id"] === "2" ? faCoffee : faHamburger}
            />{" "}
            {room.Title}
          </ServiceContainer>
          {room.Available["@code"] !== "0" &&
          room.Available["@code"] !== "200" &&
          room.Available["@status"] === "ss" ? (
            <ServiceContainer
              backgroundColor={isChecked ? colors.white8 : colors.pink}
              color={isChecked ? colors.white80 : colors.white}
            >
              <FontAwesomeIcon icon={faCircleStop} /> STOP SALE
            </ServiceContainer>
          ) : null}
        </RoomServicesMobile>
      </TopContainer>
      <BottomContainer>
        <CircleDecoration
          color={
            room.Available["@code"] === "0" || room.Available["@code"] === "200"
              ? colors.green
              : colors.pink
          }
        />
        <RoomPrices>
          {promotionPercent !== 0 && (
            <RoomOldPriceContainer>
              <RoomOldPrice color={isChecked ? colors.white : colors.pink}>
                {(
                  room.RateWithoutPromotion["#text"] *
                  reservationData.currencyValue
                ).toFixed(3) +
                  " " +
                  reservationData.defaultCurrency.code}
              </RoomOldPrice>
              <PromotionPercent color={isChecked ? colors.white : colors.pink}>
                {Math.round(
                  ((parseFloat(room.RateWithoutPromotion["#text"]) -
                    parseFloat(room.Rate["#text"])) /
                    parseFloat(room.RateWithoutPromotion["#text"])) *
                    100
                ) + "%"}
              </PromotionPercent>
            </RoomOldPriceContainer>
          )}
          <RoomPrice color={isChecked ? colors.white : colors.black}>
            {(room.Rate["#text"] * reservationData.currencyValue).toFixed(3) +
              " " +
              reservationData.defaultCurrency.code}
          </RoomPrice>
        </RoomPrices>
        <RoomNights color={isChecked ? colors.white : colors.black}>
          {t("for")} <b>{duration}</b> {t("nights")}
        </RoomNights>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => null}
          style={{
            transform: "scale(1.5)",
          }}
        />
      </BottomContainer>
    </RoomCardContainer>
  );
};

const RoomCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${colors.black6};
  border-radius: 20px;
  max-width: 620px;
  cursor: pointer;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
`;

const TopRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
`;

const RoomImage = styled.img`
  height: 100px;
  width: 102px;
  object-fit: cover;
  border-radius: 12px;
  align-self: flex-start;

  @media only screen and (min-width: 768px) {
    height: 138px;
    width: 140px;
  }
`;

const RoomDetails = styled.div`
  display: flex;
  flex-direction: column;
`;
const RoomTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 0 0 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const RoomDescription = styled.p`
  margin: 0 0 16px 0;
  color: ${(props) => props.color};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    -webkit-line-clamp: 1;
  }
`;
const RoomServicesDesktop = styled.div`
  display: none;
  flex-wrap: wrap;
  gap: 12px;
  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

const RoomServicesMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  padding: 14px 34px;
  background-color: ${colors.purple8};
  border-radius: 0px 0px 20px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
`;

const RoomPrices = styled.div`
  display: flex;
  flex-direction: column;
`;

const RoomPrice = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 0;
`;

const RoomOldPriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const RoomOldPrice = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 0;
  text-decoration: line-through;
`;

const PromotionPercent = styled.div`
  border: 1px solid ${(props) => props.color};
  border-radius: 8px;
  padding: 0px 4px;
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.color};
  height: fit-content;
`;

const RoomNights = styled.p`
  font-size: 13px;
  margin: 0;
  color: ${(props) => props.color};
`;

const ServiceContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
`;

const CircleDecoration = styled.div`
  display: flex;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 6px 6px 10px ${colors.black8};
  background-color: ${(props) => props.color};
`;

const FamilyNumberContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export default RoomCard;
