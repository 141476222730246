import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getGenericPage = createAsyncThunk(
  "genericPage/getGenericPage",
  async (pageId) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/pages?filters[customId][$eq]=" + pageId + "&populate=deep&locale=" + language
      );
      return res.data.data[0].attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const genericPageSlice = createSlice({
  name: "genericPage",
  initialState,
  reducers: {},
  extraReducers: {
    [getGenericPage.pending]: (state) => {
      state.loading = true;
    },
    [getGenericPage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getGenericPage.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default genericPageSlice.reducer;
