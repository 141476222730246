import ReactGA from 'react-ga';

export const trackInstallPromptShown = () => {
  ReactGA.event({
    category: 'PWA',
    action: 'InstallPromptShown',
    label: 'AutomaticInstall',
  });
};

export const trackInstallButtonClicked = () => {
  ReactGA.event({
    category: 'PWA',
    action: 'InstallButtonClicked',
    label: 'InstallPrompt',
  });
};
