import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { colors } from "../../../../utils/colors";
import { getFirstVisitModal } from "../../../../services/FirstVisitModal/firstVisitModalSlice";

const customStyles = {
  overlay: {
    backgroundColor: colors.black65,
    zIndex: 199,
  },

  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-35%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    padding: 0,
  },
};

const FirstVisitModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstVisitModal = useSelector((state) => state.firstVisitModal.data);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getFirstVisitModal());
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setTimeout(() => {
        setFirstModal(true);
        localStorage.setItem("hasVisited", "true");
      }, 80000);
    }
  }, [dispatch, firstVisitModal != null, language]);

  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [itIsYes, setItIsYes] = useState(false);
  const closeFirstModal = () => {
    setFirstModal(false);
  };
  const closeSecondModal = () => {
    setSecondModal(false);
  };

  const handleYesNoButtons = (value) => {
    setItIsYes(value);
    closeFirstModal();
    setSecondModal(true);
  };

  const handleOtherButtons = () => {
    closeSecondModal();
    navigate("/videos");
  };

  return (
    <>
      {firstVisitModal && (
        <>
          <Modal
            isOpen={firstModal}
            onRequestClose={closeFirstModal}
            style={customStyles}
            ariaHideApp={false}
          >
            <Card
              backgroundImage={
                firstVisitModal.attributes.image.data.attributes.url
              }
            >
              <BlurContainer>
                <SubTitle>{firstVisitModal.attributes.title}</SubTitle>
              </BlurContainer>
              {/* <Image
                src={firstVisitModal.attributes.image.data.attributes.url}
              /> */}
              <Buttons>
                <NoBtn
                  backgroundColor={colors.purple10}
                  color={colors.purple}
                  onClick={() => handleYesNoButtons(false)}
                >
                  {firstVisitModal.attributes.noButtonLabel}
                </NoBtn>
                <YesBtn
                  backgroundColor={colors.purple10}
                  color={colors.purple}
                  onClick={() => handleYesNoButtons(true)}
                >
                  {firstVisitModal.attributes.yesButtonLabel}
                </YesBtn>
              </Buttons>
            </Card>
          </Modal>
          <Modal
            isOpen={secondModal}
            onRequestClose={closeSecondModal}
            style={customStyles}
            ariaHideApp={false}
          >
            <Card backgroundImage={
                firstVisitModal.attributes.image.data.attributes.url
              }>
                <BlurContainer>
              <SubTitle>
                {itIsYes
                  ? firstVisitModal.attributes.yesChoiceText
                  : firstVisitModal.attributes.noChoiceText}
              </SubTitle></BlurContainer>
              {/* <Image
                src={firstVisitModal.attributes.image.data.attributes.url}
              /> */}
              <Buttons>
                <YesBtn
                  backgroundColor={colors.purple10}
                  color={colors.purple}
                  onClick={() => handleOtherButtons()}
                >
                  {itIsYes
                    ? firstVisitModal.attributes.yesChoiceButton
                    : firstVisitModal.attributes.noChoiceButton}
                </YesBtn>
              </Buttons>
            </Card>
          </Modal>
        </>
      )}
    </>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${colors.white};
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  padding: 40px;
  border: 1px  ${colors.black4};
  border-radius: 20px;
  text-align: center;
  gap: 20px;

  @media screen and (min-width: 768px) {
    padding: 40px 65px;
    max-width: 420px;
  }
`;

const SubTitle = styled.p`
  font-size: 18px;
  color: ${colors.white};
  margin: 10px 0;
  max-width: 300px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`;

const YesBtn = styled.a`
  background-color: ${colors.blueGreen};
  color: ${colors.white};
  border-radius: 10px;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;

const NoBtn = styled.a`
  background-color: ${colors.white};
  color: ${colors.blueGreen};
  border-radius: 10px;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;

const BlurContainer = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  background-color: rgba(265, 265, 265, 0.35);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default FirstVisitModal;
