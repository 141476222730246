import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getHome = createAsyncThunk("home/getHome", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(
      ENDPOINT_URL + "/api/home?populate=deep&locale=" + language
    );
    return res.data.data.attributes;
  } catch (err) {
    console.log(err);
  }
});

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    [getHome.pending]: (state) => {
      state.loading = true;
    },
    [getHome.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getHome.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default homeSlice.reducer;
