import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { colors } from "../../../utils/colors";
import { FILE_SERVER_URL, svg } from "../../../utils/constants";
import { CircleDecoration } from "../../../utils/styles";

const MeetingsAndEvents = ({ myRef, data }) => {
  //============= Animation ============//

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const animXY = {
    visible: { y: 0, opacity: 1, transition: { duration: 1.8 } },
    hidden: { y: -150, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  //============= End Animation ============//

  return (
    <Container ref={myRef}>
      <BodyContainer>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={animXY}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <TextContainer>
            <CircleDecoration color={colors.green} top="160px" right="150px" />
            <CategoryTitle>{data.meetingSpaceSection.title}</CategoryTitle>
            <Title color={colors.black}>
              {data.meetingSpaceSection.subTitle}
            </Title>
            <Paragraph color={colors.black}>
              {data.meetingSpaceSection.description}
            </Paragraph>
            <SvgMeeting>
              <svg.meeting />
            </SvgMeeting>
          </TextContainer>
        </motion.div>
        <ImageMask>
          <Image
            url={
              FILE_SERVER_URL +
              data.meetingSpaceSection.featuredImage.data.attributes.url
            }
          ></Image>
        </ImageMask>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    margin: 0px 10px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  background-color: ${colors.black3};
  padding: 50px 35px;

  @media only screen and (min-width: 768px) {
    padding: 200px 70px 200px 140px;
  }
`;

const CategoryTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 0;
`;

const Title = styled.h1`
  max-width: 600px;
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  color: ${(props) => props.color};
  margin: 10px 0;
`;

const Paragraph = styled.p`
  font-size: 16px;
  max-width: 600px;
  width: 100%;
  color: ${(props) => props.color};
  margin-bottom: 30px;
`;
const SvgMeeting = styled.div`
  position: absolute;
  bottom: auto;
  right: 35px;
  top: 40px;

  @media only screen and (min-width: 768px) {
    right: 80px;
    bottom: 120px;
    top: auto;
  }
`;

const ImageMask = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
`;

const Image = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform 2s;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
    height: 535px;
  }
`;
export default MeetingsAndEvents;
