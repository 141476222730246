import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL,  } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getContact = createAsyncThunk("contact/getContact", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(
      ENDPOINT_URL + "/api/contact?populate=deep&locale=" + language
    );
    return res.data.data.attributes;
  } catch (err) {
    console.log(err);
  }
});

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: {
    [getContact.pending]: (state) => {
      state.loading = true;
    },
    [getContact.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getContact.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default contactSlice.reducer;
