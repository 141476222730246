import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getRestaurants = createAsyncThunk(
  "restaurants/getRestaurants",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/restaurant?populate=deep&locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const restaurantsSlice = createSlice({
  name: "restaurants",
  initialState,
  reducers: {},
  extraReducers: {
    [getRestaurants.pending]: (state) => {
      state.loading = true;
    },
    [getRestaurants.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getRestaurants.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default restaurantsSlice.reducer;
