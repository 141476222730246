import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownLong,
  faFileDownload,
  faCloudDownload,
  faCloudDownloadAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../utils/colors";
import { trackInstallPromptShown } from "../../Analytics";
import { incrementNbInstall } from "../../services/InstallButton/installButtonSlice";
import { useDispatch } from "react-redux";
const InstallButton = ({ deferredPrompt }) => {
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    if (!deferredPrompt) {
      setShowButton(false); // If deferredPrompt is not available, hide the button
    }
  }, [deferredPrompt]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the installation prompt
      deferredPrompt.prompt();
      trackInstallPromptShown();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          dispatch(incrementNbInstall());
          setShowButton(false);
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
      });

      // Hide the button after the prompt is shown
    }
  };

  return (
    <button
      id="installButton"
      style={{
        display: showButton ? "flex" : "none",
        cursor: "pointer",
        background: "transparent",
        width: "40px",
        height: "30px",
        boxShadow: "none",
        marginLeft: "10px",
        border: "0",
        
      }}
      onClick={handleInstallClick}
    >
      <FontAwesomeIcon
        style={{
          width: "30px",
          height: "25px",
        }}
        icon={faDownload}
        color={colors.white}
      />
    </button>
  );
};

export default InstallButton;
