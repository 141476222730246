import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { colors } from "../../../../utils/colors";
import { getWelcomeModal } from "../../../../services/WelcomeModal/welcomeModalSlice";
import { FILE_SERVER_URL } from "../../../../utils/constants";

const customStyles = {
  overlay: {
    backgroundColor: colors.black65,
    zIndex: 199,
  },

  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-35%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    padding: 0,
  },
};

const InitialRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const welcomeModal = useSelector((state) => state.welcomeModal.data);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getWelcomeModal());
    const hasSeen = localStorage.getItem("hasSeen");
    if (!hasSeen) {
      setTimeout(() => {
        setModal(true);
        localStorage.setItem("hasSeen", "true");
      }, 20000);
    }
  }, [dispatch, welcomeModal != null, language]);

  const [modal, setModal] = useState(false);
  const closeModal = () => {
    setModal(false);
  };

  const handleRegisterButton = (welcomeModal) => {
    closeModal();
    navigate(welcomeModal.attributes.buttonLink);
  };

  return (
    <>
      {welcomeModal && (
        <Modal
          isOpen={modal}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
        >
          <Card>
            <Title>{welcomeModal.attributes.title}</Title>
            <SubTitle>{welcomeModal.attributes.description}</SubTitle>
            <Image
              src={
                FILE_SERVER_URL +
                welcomeModal.attributes.image.data[0].attributes.url
              }
            />
            <RegisterBtn
              backgroundColor={colors.purple10}
              color={colors.purple}
              onClick={() => handleRegisterButton(welcomeModal)}
            >
              {welcomeModal.attributes.button}
            </RegisterBtn>
          </Card>
        </Modal>
      )}
    </>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${colors.white};
  padding: 40px;
  border: 1px solid ${colors.black4};
  border-radius: 20px;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding: 40px 65px;
    max-width: 420px;
  }
`;

const Title = styled.p`
  font-size: 24px;
  color: ${colors.black};
  font-weight: 600;
  margin: 0;
`;

const SubTitle = styled.p`
  font-size: 18px;
  color: ${colors.black};
  margin: 10px 0;
`;

const RegisterBtn = styled.a`
  background-color: ${colors.blueGreen};
  color: ${colors.white};
  border-radius: 10px;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;

const Image = styled.img`
  max-height: 140px;
  width: auto;
  object-fit: cover;
  align-self: center;
  margin: 10px 0 20px 0;
  border-radius: 20px;
`;

export default InitialRegister;
