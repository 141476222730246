import React, { useState, useEffect } from 'react'
import styled from "styled-components";

const Cursor = () => {
    const [cursorXY, setCursorXY] = useState({ x: -100, y: -100 })
    useEffect(() => {
        const moveCursor = (e) => {
            const x = e.clientX - 16
            const y = e.clientY - 16
            setCursorXY({ x, y })
         }
        window.addEventListener('mousemove', moveCursor)
        return () => {
          window.removeEventListener('mousemove', moveCursor)
        }
      }, [])
      
  return (
    <Mousecursor 
    style={{
        transform: `translate3d(${cursorXY.x}px, ${cursorXY.y}px, 0)`,
      }}
      />
  )
}

const Mousecursor = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  border-radius: 16px;
  background-color: #EAE6E0;
  transition: transform 0.3s cubic-bezier(0.2, 1, 0.3, 1);
  z-index: 5;
`

export default Cursor