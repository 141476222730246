import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ENDPOINT_URL } from "../../utils/constants";
import { handleToast } from "../../utils/toast/toast";
import i18n from "i18next";
const { t } = i18n;
// const userToken = localStorage.getItem("userToken")
//   ? localStorage.getItem("userToken")
//   : null;

const initialState = {
  vipLogs: null,
  vipTotalPoints: null,
  loading: false,
  promotionalText: null,
};

export const getVipCard = createAsyncThunk(
  "vipCard/getVipCard",
  async (arg, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/users/me?populate[0]=vip_card.vip_logs&locale=" +
          language
      );
      return res.data.vip_card;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const createVipCard = createAsyncThunk(
  "vipCard/createVipCard",
  async (data, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    const points = await axios.get(ENDPOINT_URL + "/api/vip-card-config");
    const vipCard = {
      data: {
        totalPoints: points.data.data.attributes.inscriptionPoints,
        user: data.user.userInfo.id,
      },
    };
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/vip-cards?populate=deep&locale=" + language,
        vipCard
      );
      handleToast(
        t("registrationPointsPart1") +
          points.data.data.attributes.inscriptionPoints +
          t("registrationPointsPart2")
      );

      const vipLog = {
        data: {
          title: "Riadh Palms points earned on create account",
          date: Date.now(),
          rewardPoints: points.data.data.attributes.inscriptionPoints,
          vip_card: res.data.data.id,
        },
      };
      await axios.post(
        ENDPOINT_URL + "/api/vip-logs?populate=deep&locale=" + language,
        vipLog
      );
      return res.data.data.attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const addVipLog = createAsyncThunk(
  "vipCard/addVipLog",
  async (data, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    const vipLog = {
      data: {
        title: data.title,
        date: data.date,
        rewardPoints: data.rewardPoints,
        vip_card: data.vipCard.id,
      },
    };
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/vip-logs?populate=deep&locale=" + language,
        vipLog
      );
      return res.data.data.attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getPromotionalText = createAsyncThunk(
  "vipCard/getPromotionalText",
  async (arg, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/vip-card-config?populate=deep&locale=" + language
      );
      console.log(res);
      return res.data.data.attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const vipCardSlice = createSlice({
  name: "vipCard",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Vip Card
    [getVipCard.pending]: (state) => {
      state.loading = true;
    },
    [getVipCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vipTotalPoints = payload.totalPoints;
      state.vipLogs = payload.vip_logs;
    },
    [getVipCard.rejected]: (state) => {
      state.loading = false;
    },

    // Create Vip Card
    [createVipCard.pending]: (state) => {
      state.loading = true;
    },
    [createVipCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vipTotalPoints = payload.totalPoints;
    },
    [createVipCard.rejected]: (state) => {
      state.loading = false;
    },

    // Get Promotional Text
    [getPromotionalText.pending]: (state) => {
      state.loading = true;
    },
    [getPromotionalText.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.promotionalText = payload.promotionalText;
    },
    [getPromotionalText.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default vipCardSlice.reducer;
