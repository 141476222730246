import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getWelcomeModal = createAsyncThunk(
  "welcomeModal/getWelcomeModal",
  async (arg, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/welcome-modal?populate=deep&locale=" + language
      );
      return res.data.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const welcomeModalSlice = createSlice({
  name: "welcomeModal",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Testimonials
    [getWelcomeModal.pending]: (state) => {
      state.loading = true;
    },
    [getWelcomeModal.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getWelcomeModal.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default welcomeModalSlice.reducer;
