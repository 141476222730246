import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  categories: null,
  category: null,
  loading: false,
  success: null,
};

export const getGalleries = createAsyncThunk(
  "gallery/getGalleries",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/galleries?populate=deep&locale=" + language
      );
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getGallery = createAsyncThunk(
  "gallery/getGallery",
  async (categoryId) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/galleries/" +
          categoryId +
          "?populate=deep&locale=" +
          language
      );
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const galleriesSlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {},
  extraReducers: {
    [getGalleries.pending]: (state) => {
      state.loading = true;
    },
    [getGalleries.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categories = payload;
      state.success = true;
    },
    [getGalleries.rejected]: (state) => {
      state.loading = false;
    },
    [getGallery.pending]: (state) => {
      state.loading = true;
    },
    [getGallery.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.category = payload;
    },
    [getGallery.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default galleriesSlice.reducer;
