import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  msgList: [],
  loading: false,
};

const sessionIdValue = Math.random().toString(36).substring(2, 8);

export const sendChatbotMsg = createAsyncThunk(
  "chatbot/sendChatbotMsg",
  async (data, { rejectWithValue }) => {
    const dataToSend = {
      languageCode: data.language.lang,
      queryText: data.text ?? data.option,
      sessionId: sessionIdValue,
    };
    try {
      const res = await axios.post(ENDPOINT_URL + "/api/chatbot", dataToSend);
      return res.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    addMsgToMsgList: (state, { payload }) => {
      state.msgList = [
        ...state.msgList,
        { sender: "You", type: "text", msg: payload },
      ];
    },
    resetChat: (state) => {
      state.msgList = [];
    },
  },
  extraReducers: {
    [sendChatbotMsg.pending]: (state) => {
      state.loading = true;
    },
    [sendChatbotMsg.fulfilled]: (state, { payload }) => {
      state.loading = false;
      for (var i = 0; i <= payload.length - 1; i++) {
        if (payload[i] !== null) {
          state.msgList = [
            ...state.msgList,
            {
              sender: "Palma",
              type: payload[i].message,
              intent: payload[i].intent,
              msg: payload[i].text ? payload[i].text : null,
              items: {
                chips:
                  payload[i].chips && payload[i].chips.length > 0
                    ? payload[i].chips
                    : null,
                images:
                  payload[i].images && payload[i].images.length > 0
                    ? payload[i].images
                    : null,
                videos:
                  payload[i].video && payload[i].video.length > 0
                    ? payload[i].video
                    : null,
                links:
                  payload[i].links && payload[i].links.length > 0
                    ? payload[i].links
                    : null,
              },
            },
          ];
        }
      }
    },
    [sendChatbotMsg.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { addMsgToMsgList, resetChat } = chatbotSlice.actions;

export default chatbotSlice.reducer;
