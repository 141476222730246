import React from "react";
import { Container, Button, Link } from "react-floating-action-button";
import { colors } from "../../utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const getContainerStyles = () => ({
  right: window.innerWidth >= 768 ? "24px" : "4px",
  bottom: window.innerWidth >= 768 ? "90px" : "80px",
});

const socialLinks = [
  {
    href: "https://www.tiktok.com/@riadhpalmshotel",
    background: "linear-gradient(45deg, #000000, #444444)",
    icon: faTiktok,
  },
  {
    href: "https://www.instagram.com/hotelriadhpalms",
    background: "linear-gradient(45deg, #8a3ab9, #ff5151)",
    icon: faInstagram,
  },
  {
    href: "https://www.facebook.com/hotelriadhpalms",
    background: "linear-gradient(45deg, #1877f2, #4267B2)",
    icon: faFacebookF,
  },

  {
    href: "https://www.linkedin.com/company/hotel-riadh-palms",
    background: "linear-gradient(45deg, #00A0DC, #4267B2)",
    icon: faLinkedinIn,
  },
];

const SocialFab = () => {
  const containerStyles = getContainerStyles();

  return (
    <Container styles={containerStyles}>
      {socialLinks.map((link, index) => (
        <Link
          key={index}
          href={link.href}
          styles={{
            background: link.background,
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <FontAwesomeIcon
            icon={link.icon}
            color={colors.white}
            style={{ height: 21 }}
          />
        </Link>
      ))}
      <Button
        rotate={true}
        styles={{
          height: 58,
          width: 58,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
          background: "linear-gradient(45deg, #8a3ab9, #ff5151)",
          color: colors.white,
        }}
      >
        <FontAwesomeIcon
          icon={faHeart}
          color={colors.white}
          style={{ height: 29 }}
        />
      </Button>
    </Container>
  );
};

export default SocialFab;
