import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: [],
  loading: false,
};

export const getRoom = createAsyncThunk(
  "room/getRoom",
  async (slug, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/rooms?filters[slug][$eq]=" +
          slug +
          "&populate=deep&locale=" +
          language
      );

      return res.data.data[0].attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {},
  extraReducers: {
    [getRoom.pending]: (state) => {
      state.loading = true;
    },
    [getRoom.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getRoom.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default roomSlice.reducer;
