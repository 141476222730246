import { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-date-picker";
import moment from "moment/moment";
import Dropdown from "react-dropdown";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateReservationInfos } from "../../services/Reservation/hotelReservationSlice";

const ChatBotReservationForm = ({ item, setDisplayed }) => {
  const { t } = useTranslation();
  const [formValid, setFormValid] = useState(true);

  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const hotelReservation = useSelector((state) => state.hotelReservation);
  let arrivalDatee;
  if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
    arrivalDatee = moment().day(5);
  } else {
    arrivalDatee = moment().add(1, "weeks").day(5);
  }

  const [arrivalDate, setArrivalDate] = useState(arrivalDatee);
  const [departureDate, setDepartureDate] = useState(
    moment(arrivalDate).add(2, "days")
  );
  const [roomIds, setRoomIds] = useState([
    {
      adult: 2,
      child: {
        age: [],
        number: 0,
      },
    },
  ]);
  useEffect(() => {
    const roomIdsInfo = hotelReservation.roomIds;
    setRoomIds(roomIdsInfo);
  }, [hotelReservation.roomIds]);
  useEffect(() => {
    // Update formValid based on child age selection
    let isValid = true;

    for (const room of roomIds) {
      if (room.child.number > 0) {
        // If there are children, check if all ages are specified
        if (
          room.child.age.length === 0 ||
          room.child.age.some((age) => age === 0) ||
          room.child.age.length !== room.child.number
        ) {
          isValid = false;
          break;
        }
      }
    }

    setFormValid(isValid);
  }, [roomIds, roomIds.child]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const options = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const defaultOption = options[0];

  const minDate = arrivalDate.toDate();
  minDate.setDate(minDate.getDate() + 1);
  const maxDepartureDate = moment(arrivalDate).add(15, "days").toDate();

  const handleChildren = (event, index, i) => {
    let ageArr = [...roomIds[index].child.age];
    ageArr[i] = event.value;
    let updatedRoomIds = [...roomIds];
    updatedRoomIds[index] = {
      ...updatedRoomIds[index],
      child: {
        ...updatedRoomIds[index].child,
        age: ageArr,
      },
    };
    setRoomIds(updatedRoomIds);
  };

  // useEffect(() => {
  //   //console.log(children);
  // }, [roomIds]);

  return item.intent === "hotel-reservation" ? (
    <>
      <ReservationFormQuestion>{t("arrivalDate")}</ReservationFormQuestion>
      <DatePickerCustom
        isOpen={false}
        minDate={new Date()}
        clearIcon={false}
        value={arrivalDate.toDate()}
        format="dd/MM/yyyy"
        onChange={(e) => {
          const selectedDate = moment(e);
          if (selectedDate.toDate() > departureDate.toDate()) {
            setDepartureDate(moment(selectedDate).add(3, "days"));
          }
          setArrivalDate(selectedDate);
        }}
      />
      <ReservationFormQuestion>{t("departureDate")}</ReservationFormQuestion>
      <DatePickerCustom
        isOpen={false}
        minDate={minDate}
        maxDate={maxDepartureDate}
        clearIcon={false}
        value={departureDate.toDate()}
        format="dd/MM/yyyy"
        onChange={(e) => {
          const selectedDate = moment(e);
          setDepartureDate(selectedDate);
        }}
      />

      <ReservationFormQuestion>{t("rooms")}</ReservationFormQuestion>
      <CounterContainer>
        <CounterButton
          onClick={() => {
            if (roomIds.length > 0) {
              setRoomIds(roomIds.slice(0, -1));
            }
          }}
        >
          -
        </CounterButton>
        {roomIds.length}
        <CounterButton
          onClick={() => {
            if (roomIds.length < 3) {
              setRoomIds([
                ...roomIds,
                {
                  adult: 1,
                  child: {
                    age: [],
                    number: 0,
                  },
                },
              ]);
            }
          }}
        >
          +
        </CounterButton>
      </CounterContainer>
      {[...Array(roomIds.length)].map((_, index) => (
        <ReservationFormRoom key={index}>
          <ReservationFormQuestion>
            {t("people")} <strong>{index + 1}</strong> ?
          </ReservationFormQuestion>
          <CounterContainer>
            <CounterButton
              onClick={() => {
                if (roomIds[index].adult > 1) {
                  const updatedRooms = [...roomIds]; 
                   const updatedRoom = { ...updatedRooms[index] }; 
                   updatedRoom.adult -= 1; 
                   updatedRooms[index] = updatedRoom; 
                   setRoomIds(updatedRooms); 
                 }
              }}
            >
              -
            </CounterButton>
            {roomIds[index].adult}
            <CounterButton
              onClick={() => {
                if (roomIds[index].adult < 10) {
                  const updatedRooms = [...roomIds]; 
                  const updatedRoom = { ...updatedRooms[index] }; 
                  updatedRoom.adult += 1; 
                  updatedRooms[index] = updatedRoom;
                  setRoomIds(updatedRooms);
                }
              }}
            >
              +
            </CounterButton>
          </CounterContainer>
          <ReservationFormQuestion>
            {t("kids")} <strong>{index + 1}</strong> ?
          </ReservationFormQuestion>
          <CounterContainer>
            <CounterButton
              onClick={() => {
                if (roomIds[index].child.number > 0) {
                setRoomIds((prevRoomIds) => {
                  const updatedRoomIds = [...prevRoomIds];
                  const lastChildAgeIndex = updatedRoomIds[index].child.age.length - 1;
                  updatedRoomIds[index] = {
                    ...updatedRoomIds[index],
                    child: {
                      ...updatedRoomIds[index].child,
                      number: updatedRoomIds[index].child.number - 1,
                      age: updatedRoomIds[index].child.age.slice(
                        0,
                        lastChildAgeIndex
                      ),
                    },
                  };
                  return updatedRoomIds;
                });
              }}}
            >
              -
            </CounterButton>
            {roomIds[index].child.number}
            <CounterButton
              onClick={() => {
                if (roomIds[index].child.number < 10) {
                  setRoomIds((prevRoomIds) => {
                    const updatedRoomIds = [...prevRoomIds];
                    updatedRoomIds[index] = {
                      ...updatedRoomIds[index],
                      child: {
                        ...updatedRoomIds[index].child,
                        number: updatedRoomIds[index].child.number + 1,
                        age: [...updatedRoomIds[index].child.age, 0],
                      },
                    };
                    return updatedRoomIds;
                  });
                }
              }}
            >
              +
            </CounterButton>
          </CounterContainer>
          {roomIds[index].child.number > 0 ? (
            <AgesContainer>
              <Ages>
                    {roomIds[index].child.age.map((age, i) => (
                      <ChildAge
                        key={i}
                        options={options}
                        onChange={(event) => handleChildren(event, index, i)}
                         // Set the value prop to the age
                        
                        placeholder={` ${age}` }

                      />
                    ))}
                </Ages>
              <Info>{t("childrenAgeInfo")}</Info>
            </AgesContainer>
          ) : null}
        </ReservationFormRoom>
      ))}
      <PeopleDoneButton
        disabled={!formValid || roomIds.length < 1}
        onClick={() => {
          dispatch(
            updateReservationInfos({
              arrivalDate,
              departureDate,
              roomIds,
            })
          );
          setRoomIds([
            {
              adult: 2,
              child: {
                age: [0],
                number: 0,
              },
            },
          ]);
          setDisplayed(false);
          navigate("/reservation");
        }}
      >
        {t("done")}
      </PeopleDoneButton>
    </>
  ) : null;
};

const ReservationFormRoom = styled.div`
  background-color: ${colors.purple10};
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
`;

const ReservationFormQuestion = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;

const DatePickerCustom = styled(DatePicker)`
  && .react-date-picker__wrapper {
    border-radius: 10px;
    padding: 6px 10px;
    border: none;
    border: 1px solid ${colors.purple35};
    color: ${colors.purple};
    background-color: ${colors.purple10};
  }

  && .react-date-picker__inputGroup__input {
    color: ${colors.purple};
  }

  && .react-date-picker__button svg {
    stroke: ${colors.purple};
  }

  && .react-date-picker__calendar--open {
    margin-top: 20px;
    z-index: 3;
    position: absolute;
  }

  && .react-date-picker__calendar--closed {
    position: absolute;
  }

  && .react-calendar {
    border: 1px solid ${colors.black8};
    border-radius: 10px;
  }
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  padding: 0px;
  font-weight: 600;
  gap: 20px;
`;

const CounterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: monospace;
  background-color: ${colors.purple8};
  color: ${colors.purple};
  padding: 10px;
  font-size: 24px;
  font-weight: 500;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${colors.purple35};
    color: ${colors.purple};
  }
`;

const PeopleDoneButton = styled.button`
  display: block;
  border: none;
  margin: 14px 0;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  background-color: ${colors.purple};
  color: ${colors.white};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const AgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
  gap: 10px;
`;

const Info = styled.p`
  font-size: 14px;
  color: ${colors.black65};
  margin: 0;
`;

const Ages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const ChildAge = styled(Dropdown)`
  border-radius: 10px;
  width: 48%;

  && .Dropdown-placeholder {
    font-size: 14px;
  }

  && .Dropdown-placeholder.is-selected {
    font-size: 14px;
  }
`;

export default ChatBotReservationForm;
