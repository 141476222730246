import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { getPages } from "../services/Routes";
import Home from "../pages/Home";
import { ProtectedRoute, ProtectedRouteWithUser } from "../utils/routes";
import Bars from "../pages/Bars";
import Voucher from "../pages/Voucher";
import ReservationWithQuery from "./ReservationWithQuery";

const GenericPage = lazy(() => import("../pages/GenericPage"));
const SignIn = lazy(() => import("../pages/SignIn"));
const SignUp = lazy(() => import("../pages/SignUp"));
const GoogleAuthCallback = lazy(() => import("../pages/GoogleAuth"));
const FAQ = lazy(() => import("../pages/FAQ"));
const InstagramFeed = lazy(() => import("../pages/InstagramFeed"));
const FacebookFeed = lazy(() => import("../pages/FacebookFeed"));
const Gallery = lazy(() => import("../pages/Gallery/images"));
const Videos = lazy(() => import("../pages/Gallery/videos"));
const SportAndRecreation = lazy(() =>
  import("../pages/HotelServices/SportAndRecreation")
);
const UserSpace = lazy(() => import("../pages/UserSpace"));
const Blog = lazy(() => import("../pages/Blog"));
const Article = lazy(() => import("../pages/Blog/Article"));
const Restaurants = lazy(() => import("../pages/Restaurants"));
const ProSpace = lazy(() => import("../pages/ProSpace"));
const BestOffers = lazy(() => import("../pages/BestOffers"));
const Situation = lazy(() => import("../pages/Situation"));
const Contact = lazy(() => import("../pages/Contact"));
const AllInclusive = lazy(() => import("../pages/AllInclusive"));
const GenericRoom = lazy(() =>
  import("../pages/GenericPage/components/GenericRoom")
);
const Reservation = lazy(() => import("../pages/Reservation"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));

const AppRoutes = () => {
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.pages);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getPages());
  }, [dispatch, language]);

  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "false") {
      localStorage.removeItem("userToken");
    }
  }, []);

  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          path="/api/auth/google/callback"
          element={<GoogleAuthCallback />}
        />
        {pages.data !== null
          ? pages.data.map((page) => (
              <Route
                key={page.id}
                path={`/${
                  page.attributes.route.data &&
                  page.attributes.route.data.attributes.path
                }`}
                element={<GenericPage pageId={page.attributes.customId} />}
              />
            ))
          : null}
        <Route path="/faq" element={<FAQ />} />
        <Route
          path="/signin"
          element={
            <ProtectedRouteWithUser destination={"/"}>
              <SignIn />
            </ProtectedRouteWithUser>
          }
        />
        <Route
          path="/reset-password"
          element={
            <ProtectedRouteWithUser destination={"/"}>
              <ResetPassword />
            </ProtectedRouteWithUser>
          }
        />
        <Route
          path="/signup"
          element={
            <ProtectedRouteWithUser destination={"/"}>
              <SignUp />
            </ProtectedRouteWithUser>
          }
        />
        <Route path="/instagram-feed" element={<InstagramFeed />} />
        <Route path="/facebook-feed" element={<FacebookFeed />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/voucher" element={<Voucher />} />
        <Route path="/sport-and-recreation" element={<SportAndRecreation />} />
        <Route path="/restaurants" element={<Restaurants />} />
        <Route path="/bars" element={<Bars />} />
        <Route path="/pro-space" element={<ProSpace />} />
        <Route path="/best-offers" element={<BestOffers />} />
        <Route path="/situation" element={<Situation />} />
        <Route path="/chambres/:slug" element={<GenericRoom />} />
        <Route path="/allInclusive" element={<AllInclusive />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<Article />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/user-space"
          element={
            <ProtectedRoute destination={"/"}>
              <UserSpace />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<>Loading..</>} />
        <Route path="/reservation" element={<Reservation />} />
        <Route
          path="/fr/google"
          element={<ReservationWithQuery />}
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
