import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

import { BlurContainer, BlurContainerText } from "../../../../utils/styles";
import { colors } from "../../../../utils/colors";
import { useTranslation } from "react-i18next";

function HomeGalleryModal({
  galleryImages,
  selectedImg,
  setSelectedImg,
  selectedIndex,
  setSelectedIndex,
}) {
  const { t } = useTranslation();
  const handleClick = (e) => {
    if (e.target.classList.contains("backdrop")) {
      setSelectedImg(null);
    }
  };

  return (
    <Backdrop
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="backdrop"
      onClick={handleClick}
    >
      <BackdropImageAndText>
        <BackdropImage
          initial={{ y: "-100vh" }}
          animate={{ y: 0 }}
          src={selectedImg}
          alt="enlarged pic"
          style={{ backgroundColor: "#fff" }}
          onClick={() => {
            if (selectedIndex + 1 < galleryImages.length) {
              setSelectedIndex(selectedIndex + 1);
              setSelectedImg(galleryImages[selectedIndex + 1].uri);
            } else {
              setSelectedIndex(0);
              setSelectedImg(galleryImages[0].uri);
            }
          }}
        />

        <BlurContainer>
          <BlurContainerText>
            {galleryImages[selectedIndex].name}
          </BlurContainerText>
        </BlurContainer>
        <TapImage>{t("tapImage")}</TapImage>
      </BackdropImageAndText>
    </Backdrop>
  );
}

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
`;

const BackdropImageAndText = styled.div`
  display: block;
  position: relative;
  width: 90%;
  height: 40%;
  margin: 200px auto;
  border: 2px solid white;
  border-radius: 20px;

  @media only screen and (min-width: 768px) {
    width: 70%;
    height: 65%;
    margin: 80px auto;
  }

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`;

const BackdropImage = styled(motion.img)`
  display: flex;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const TapImage = styled.div`
  text-align: center;
  margin-top: 20px;
  color: ${colors.white50};
`;

export default HomeGalleryModal;
