import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/colors";

const NavMenuItem = ({
  itemTitle,
  itemTo,
  subItems,
  activestyle,
  mainColor,
  secondaryColor,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [sortedSubMenu, setSortedSubMenu] = useState([]);

  useEffect(() => {
    setSortedSubMenu([...subItems].sort((a, b) => a.attributes.order - b.attributes.order));
  }, []);

  const handleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleMenu = useCallback(
    (to) => {
      setOpen(false);
      navigate("/" + to);
    },
    [navigate]
  );

  return (
    <Dropdown onMouseEnter={handleOpen} onMouseLeave={() => setOpen(false)}>
      <LinkTo
        to={itemTo}
        activestyle={activestyle}
        maincolor={mainColor}
        secondarycolor={secondaryColor}
      >
        {itemTitle}
      </LinkTo>
      {open && sortedSubMenu.length > 0 ? (
        <MenuContainer>
          <Menu>
            {sortedSubMenu.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenu(item.attributes.url)}
              >
                {item.attributes.title}
              </MenuItem>
            ))}
          </Menu>
        </MenuContainer>
      ) : null}
    </Dropdown>
  );
};

const Dropdown = styled.div`
  position: relative;
`;

const LinkTo = styled(Link)`
  font-size: 15px;
  color: ${(props) =>
    props.activestyle === "true" ? props.maincolor : props.secondarycolor};
  font-weight: ${(props) => (props.activestyle === "true" ? 600 : 400)};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 20px;
  cursor: pointer;
  text-align: center;
`;

const MenuContainer = styled.div`
  position: absolute;
  padding-top: 20px;
  z-index: 4;
`;

const Menu = styled.ul`
  left: 0px;
  list-style-type: none;
  border-radius: 8px;
  border: 1px solid ${colors.black8};
  box-shadow: none;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  color: ${colors.black75};
  background-color: #f9f9f9;
  padding: 0;

  @media screen and (min-width: 768px) {
    border: 1px solid ${colors.black4};
    background-color: white;
    font-size: 14px;
    font-weight: 500;
  }
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
  gap: 10px;
  padding: 12px 20px;

  &:hover {
    background-color: ${colors.black3};
  }
`;

export default NavMenuItem;
