import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import NavMenuItem from "./NavMenuItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PrimaryMenu = ({
  mainMenu,
  pathname,
  mainColorValue,
  secondaryColorValue,
}) => {
  const language = useSelector((state) => state.language);
  
  const [sortedMenu, setSortedMenu] = useState([]);
  useEffect(() => {
    setSortedMenu(
      [...mainMenu].sort((a, b) => a.attributes.order - b.attributes.order)
    );
  }, [language, mainMenu]);
  

  return (
    <>
      <NavMenu>
        {sortedMenu.map(
          (item) =>
            item.attributes.isButton === false && (
              <NavMenuItem
                key={item.id}
                itemTitle={item.attributes.title}
                itemTo={item.attributes.url}
                subItems={item.attributes.sub_menus.data}
                activestyle={
                  pathname === item.attributes.url ? "true" : "false"
                }
                mainColor={mainColorValue}
                secondaryColor={secondaryColorValue}
              />
            )
        )}
      </NavMenu>
      <NavBtn>
        {mainMenu.map(
          (item) =>
            item.attributes.isButton && (
              <NavBtnLink key={item.id} to={item.attributes.url}>
                {item.attributes.title}
              </NavBtnLink>
            )
        )}
      </NavBtn>
    </>
  );
};

const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;

const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const NavBtnLink = styled(Link)`
  font-size: 15px;
  border-radius: 6px;
  background: ${colors.gold};
  padding: 12px 26px;
  color: ${colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    background: ${colors.goldHover};
  }
`;

export default PrimaryMenu;
