import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import NavMenuItem from "./NavMenuItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PrimaryMobileMenu = ({
  pathname,
  mainMenu,
  mainColorValue,
  secondaryColorValue,
}) => {
  const language = useSelector((state) => state.language);
  const [sortedMenu, setSortedMenu] = useState([]);
  useEffect(() => {
    setSortedMenu(
      [...mainMenu].sort((a, b) => a.attributes.order - b.attributes.order)
    );
  }, [language, mainMenu]);

  return (
    <PrimaryMobileMenuContainer>
      <Header>
        Menu
        <Separator />
      </Header>
      <SpaceBetween>
        <NavMenu>
          {sortedMenu.map(
            (item) =>
              item.attributes.isButton === false && (
                <NavMenuItem
                  key={item.id}
                  itemTitle={item.attributes.title}
                  itemTo={item.attributes.url}
                  subItems={item.attributes.sub_menus.data}
                  activestyle={
                    pathname === item.attributes.url ? "true" : "false"
                  }
                  mainColor={mainColorValue}
                  secondaryColor={secondaryColorValue}
                />
              )
          )}
        </NavMenu>
        <NavBtn>
          {mainMenu.map(
            (item) =>
              item.attributes.isButton && (
                <NavBtnLink key={item.id} to={item.attributes.url}>
                  {item.attributes.title}
                </NavBtnLink>
              )
          )}
        </NavBtn>
      </SpaceBetween>
    </PrimaryMobileMenuContainer>
  );
};

const PrimaryMobileMenuContainer = styled.div`
  display: flex;
  position: fixed;
  background-color: ${colors.white};
  flex-direction: column;
  height: 100vh;
  padding: 40px 40px;
  align-items: center;
  overflow: scroll;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 100%;
`;

const Separator = styled.div`
  height: 8px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.02);
`;

const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-bottom: 80px;
`;

const NavBtnLink = styled(Link)`
  font-size: 15px;
  border-radius: 6px;
  background: ${colors.gold};
  padding: 12px 26px;
  color: ${colors.white};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    background: ${colors.goldHover};
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export default PrimaryMobileMenu;
