import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  blogDescriptionData: null,
  articles: null,
  categories: null,
  article: null,
  loading: false,
};

export const getBlogDescription = createAsyncThunk(
  "blog/getBlogDescription",
  async (args, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/blog-description?populate[0]=seo.metaSocial.image&locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getBlogArticles = createAsyncThunk(
  "blog/getBlogArticles",
  async (args, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/blogs?populate[0]=blogCategory&populate[1]=cover&locale=" +
          language
      );
      console.log("blogArticle", res.data.data)
      return res.data.data;
      
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getBlogCategories = createAsyncThunk(
  "blog/getBlogCategories",
  async (args, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/blog-categories?locale=" + language
      );
      return res.data.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getArticlesByCategory = createAsyncThunk(
  "blog/getArticlesByCategory",
  async (categoryId, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/blog-categories/" +
          categoryId +
          "?populate[0]=blogs.blogCategory&populate[1]=blogs.cover&locale=" +
          language
      );
      return res.data.data.attributes.blogs.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getBlogArticle = createAsyncThunk(
  "blog/getBlogArticle",
  async (slug, { rejectWithValue }) => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(

        ENDPOINT_URL +
          "/api/blogs?filters[slug][$eq]=" +
          slug +
          "&populate[0]=blogCategory&populate[1]=cover&populate[2]=seo.metaSocial.image&locale=" +
          language
      );
      return res.data.data[0].attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: {
    // Get blog description (header)
    [getBlogDescription.pending]: (state) => {
      state.loading = true;
    },
    [getBlogDescription.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.blogDescriptionData = payload;
    },
    [getBlogDescription.rejected]: (state) => {
      state.loading = false;
    },

    // Get blog articles
    [getBlogArticles.pending]: (state) => {
      state.loading = true;
    },
    [getBlogArticles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.articles = payload;
    },
    [getBlogArticles.rejected]: (state) => {
      state.loading = false;
    },

    // Get blog categories
    [getBlogCategories.pending]: (state) => {
      state.loading = true;
    },
    [getBlogCategories.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categories = payload;
    },
    [getBlogCategories.rejected]: (state) => {
      state.loading = false;
    },

    // Get blog article
    [getBlogArticle.pending]: (state) => {
      state.loading = true;
    },
    [getBlogArticle.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.article = payload;
    },
    [getBlogArticle.rejected]: (state) => {
      state.loading = false;
    },

    // Get articles by category
    [getArticlesByCategory.pending]: (state) => {
      state.loading = true;
    },
    [getArticlesByCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.articles = payload;
    },
    [getArticlesByCategory.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default blogSlice.reducer;
