import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { colors } from "../../utils/colors";
import ReactGA from "react-ga";

import Details from "./components/Details";
import Payment from "./components/Payment";
import PersonalInformation from "./components/PersonalInformation";
import RoomSelection from "./components/RoomSelection";
import Steps from "./components/Steps/Steps";
import ReservationSelection from "./components/ReservationSelection";

import "react-dropdown/style.css";
import { useDispatch } from "react-redux";
import {
  fetchCurrency,
  updateReservationInfos,
} from "../../services/Reservation/hotelReservationSlice";
import moment from "moment";
import { updateLanguage } from "../../services/Language/languageSlice";
import i18n from "../../i18n";

const Reservation = ({
  curr,
  ilng,
  hotel,
  checkindate,
  nights,
  adults,
  childs,
}) => {
  const dispatch = useDispatch();

  const [stepSelected, setStepSelected] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [country, setCountry] = useState("Tunisia");
  const [phone, setPhone] = useState("");
  const [adultsCount, setAdultsCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [adultsNames, setAdultsNames] = useState([]);
  const [childrenNames, setChildrenNames] = useState([]);
  const [childrenAges, setChildrenAges] = useState([]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  // Reservation with Google Params
  useEffect(() => {
    if (curr) {
      const arrivalDate = moment(checkindate, "DD/MM/YYYY");
      const departureDate = moment(checkindate, "DD/MM/YYYY").add(nights, "days");
      const roomIds = [
        {
          adult: adults,
          child: {
            age: [],
            number: childs,
          },
        },
      ];

      dispatch(fetchCurrency(curr));
      dispatch(
        updateReservationInfos({
          arrivalDate,
          departureDate,
          roomIds,
        })
      );

      if (["fr", "en", "de", "ru"].includes(ilng)) {
        localStorage.setItem("language", JSON.stringify(ilng));
        dispatch(updateLanguage(ilng));
        i18n.changeLanguage(ilng);
      }
    }
  }, [dispatch, curr, ilng, checkindate, nights, adults, childs]);

  return (
    <Container>
      <LeftContainer>
        <motion.div
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 0, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Steps
            stepSelected={stepSelected}
            setStepSelected={setStepSelected}
          />
          <Details />
        </motion.div>
      </LeftContainer>
      <RightContainer>
        {stepSelected === 2 ? (
          <Payment
            firstName={firstName}
            lastName={lastName}
            emailAddress={emailAddress}
            country={country}
            phone={phone}
            adultsCount={adultsCount}
            childrenCount={childrenCount}
            adultsNames={adultsNames}
            childrenNames={childrenNames}
            childrenAges={childrenAges}
          />
        ) : stepSelected === 1 ? (
          <PersonalInformation
            setStepSelected={setStepSelected}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
            country={country}
            setCountry={setCountry}
            phone={phone}
            setPhone={setPhone}
            adultsCount={adultsCount}
            setAdultsCount={setAdultsCount}
            childrenCount={childrenCount}
            setChildrenCount={setChildrenCount}
            adultsNames={adultsNames}
            setAdultsNames={setAdultsNames}
            childrenNames={childrenNames}
            setChildrenNames={setChildrenNames}
          />
        ) : stepSelected === -1 ? (
          <ReservationSelection setStepSelected={setStepSelected} />
        ) : (
          <RoomSelection
            setStepSelected={setStepSelected}
            setChildrenAges={setChildrenAges}
          />
        )}
      </RightContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 10px;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex: 1;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0;
  justify-content: center;
  background-color: ${colors.ocean8};

  @media only screen and (min-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    flex: 3;
    background-color: transparent;
  }
`;

export default Reservation;
