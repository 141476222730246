import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  mainMenu: null,
  subMenu: null,
  secondaryMenu: null,
  loading: false,
};

export const getMainMenu = createAsyncThunk("menu/getMainMenu", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(
      ENDPOINT_URL + "/api/main-menus?populate=deep&locale=" + language
    );
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
});

export const getSubMenu = createAsyncThunk("menu/getSubMenu", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(
      ENDPOINT_URL + "/api/sub-menus?locale=" + language
    );
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
});

export const getSecondaryMenu = createAsyncThunk(
  "menu/getSecondaryMenu",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/secondary-menus?populate=deep&locale=" + language
      );
      console.log(res.data);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: {
    [getMainMenu.pending]: (state) => {
      state.loading = true;
    },
    [getMainMenu.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mainMenu = payload;
    },
    [getMainMenu.rejected]: (state) => {
      state.loading = false;
    },

    [getSubMenu.pending]: (state) => {
      state.loading = true;
    },
    [getSubMenu.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.subMenu = payload;
    },
    [getSubMenu.rejected]: (state) => {
      state.loading = false;
    },

    [getSecondaryMenu.pending]: (state) => {
      state.loading = true;
    },
    [getSecondaryMenu.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.secondaryMenu = payload;
    },
    [getSecondaryMenu.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default menuSlice.reducer;
