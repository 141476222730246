import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL, FILE_SERVER_URL } from "../../utils/constants";

const initialState = {
  imageCover: null,
  description: null,
  loading: false,
};

export const getInstagramFeed = createAsyncThunk(
  "socialMedia/getInstagramFeed",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/instagram?populate=deep&locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getFacebookFeed = createAsyncThunk(
  "socialMedia/getFacebookFeed",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/facebook?populate=deep&locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const socialMediaSlice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: {},
  extraReducers: {
    //Instagram
    [getInstagramFeed.pending]: (state) => {
      state.loading = true;
    },
    [getInstagramFeed.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.imageCover = FILE_SERVER_URL + payload.imageCover.data.attributes.url;
      state.description = payload.description;
    },
    [getInstagramFeed.rejected]: (state) => {
      state.loading = false;
    },

    //Facebook
    [getFacebookFeed.pending]: (state) => {
      state.loading = true;
    },
    [getFacebookFeed.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.imageCover = FILE_SERVER_URL + payload.imageCover.data.attributes.url;
      state.description = payload.description;
    },
    [getFacebookFeed.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default socialMediaSlice.reducer;
