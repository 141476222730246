import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
};

export const getWeather = createAsyncThunk(
  "weather/getWeather",
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetch(
        "https://api.open-meteo.com/v1/forecast?latitude=35.83&longitude=10.64&daily=temperature_2m_max&current_weather=true&timezone=auto"
      );
      return res.json();
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const weatherSlice = createSlice({
  name: "weather",
  initialState,
  reducers: {},
  extraReducers: {
    [getWeather.pending]: (state) => {
      state.loading = true;
    },
    [getWeather.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getWeather.rejected]: (state, payload) => {
      state.loading = false;
      state.data = payload;
    },
  },
});

export default weatherSlice.reducer;
