import { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import moment from "moment/moment";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ru";


import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { colors } from "../../../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateReservationInfos } from "../../../../services/Reservation/hotelReservationSlice";
import PeopleModal from "../../../Home/components/HomeHeader/components/PeopleModal";

const ReservationSelectionBar = ({ setStepSelected }) => {
  const { t } = useTranslation();
  const [openPeopleModal, setOpenPeopleModal] = useState(false);
  const language = useSelector((state) => state.language);

  const hotelReservation = useSelector((state) => state.hotelReservation);

  const arrivalDateInfo = moment(hotelReservation.arrivalDate, "DD/MM/YYYY")
    .locale(language.lang)
    .format("DD MMM YYYY");

  const departureDateInfo = moment(hotelReservation.departureDate, "DD/MM/YYYY")
    .locale(language.lang)
    .format("DD MMM YYYY");

  const [arrivalDate, setArrivalDate] = useState(  moment(arrivalDateInfo, "DD MMM YYYY" , language.lang));

  const [departureDate, setDepartureDate] = useState(moment(departureDateInfo, "DD MMM. YYYY" , language.lang));

  const dispatch = useDispatch();

  const [adults, setAdults] = useState();

  const [children, setChildren] = useState();
  const [rooms, setRooms] = useState();

  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const [roomIds, setRoomIds] = useState([
    {
      adult: 2,
      child: {
        age: 0,
        number: 0,
      },
    },
  ]);
  useEffect(() => {
    const roomIdsInfo = hotelReservation.roomIds;
    setRoomIds(roomIdsInfo);
  }, [hotelReservation.roomIds]);

  useEffect(() => {
    if (roomIds.length > 0) {
      setRooms(roomIds.length);
      const totalAdults = roomIds.reduce((acc, person) => {
        return acc + person.adult;
      }, 0);
      setAdults(totalAdults);
      const totalChildren = roomIds.reduce((acc, person) => {
        return acc + person.child.number;
      }, 0);
      setChildren(totalChildren);
    }
  }, [roomIds]);

  const minDate = arrivalDate.toDate();
  minDate.setDate(minDate.getDate() + 1);
  const maxDepartureDate = moment(arrivalDate).add(15, "days").toDate();

  return (
    <ReservationBarContainer>
      <PeopleModal
        openPeopleModal={openPeopleModal}
        setOpenPeopleModal={setOpenPeopleModal}
        roomIds={roomIds}
        setRoomIds={setRoomIds}
        adults={adults}
        setAdults={setAdults}
        children={children}
        setChildren={setChildren}
        rooms={rooms}
        setRooms={setRooms}
      />
      <ReservationDetailsContainer>
        <InputContainer onClick={() => setOpenStartDate(true)}>
          <span
            style={{
              fontSize: "12px",
              color: colors.black35,
            }}
          >
            {t("arrivalDateLabel")}
          </span>
          {moment(arrivalDate).locale(language.lang).format("dddd DD MMM YYYY")}
          <DatePickerCustom
            isOpen={openStartDate}
            minDate={new Date()}
            value={arrivalDate.toDate()}
            onCalendarClose={() => setOpenStartDate(false)}
            locale={language.lang}
            onChange={(e) => {
              const selectedDate = moment(e);
              if (selectedDate.toDate() > departureDate.toDate()) {
                setDepartureDate(moment(selectedDate).add(3, "days"));
              }
              setArrivalDate(selectedDate);
            }}
          />
        </InputContainer>
        <HrLine />
        <InputContainer onClick={() => setOpenEndDate(true)}>
          <span
            style={{
              fontSize: "12px",
              color: colors.black35,
            }}
          >
            {t("departureDateLabel")}
          </span>
          {moment(departureDate)
            .locale(language.lang)
            .format("dddd DD MMM YYYY")}
          <DatePickerCustom
            isOpen={openEndDate}
            minDate={minDate}
            maxDate={maxDepartureDate}
            value={departureDate.toDate()}
            onCalendarClose={() => setOpenEndDate(false)}
            locale={language.lang}
            onChange={(e) => {
              const selectedDate = moment(e);
              setDepartureDate(selectedDate);
            }}
          />
        </InputContainer>
        <HrLine />
        <InputContainer onClick={() => setOpenPeopleModal(true)}>
          <span
            style={{
              fontSize: "12px",
              color: colors.black35,
            }}
          >
            {t("detailsLabel")}
          </span>
          <span>
            <b>{adults} </b> {t("adults")},<b>{children}</b> {t("children")},{" "}
            <b>{rooms}</b> {t("roomsLabel")}
          </span>
        </InputContainer>
        <SearchBtn
          disabled={roomIds.length < 1}
          onClick={() => {
            dispatch(
              updateReservationInfos({
                arrivalDate,
                departureDate,
                roomIds,
              })
            );
            //setRoomIds([]);
            setStepSelected(0);
          }}
        >
          {t("search")}
        </SearchBtn>
      </ReservationDetailsContainer>
    </ReservationBarContainer>
  );
};

const ReservationBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReservationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid ${colors.black6};
  max-width: 500px;
  box-shadow: 10px 20px 30px ${colors.black2};
  background-color: ${colors.white};
  border-radius: 20px;
  color: ${colors.white};
  font-size: 16px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.black};
  cursor: pointer;
`;

const SearchBtn = styled.button`
  background-color: ${colors.pink};
  color: ${colors.white};
  border-radius: 10px;
  padding: 14px 26px;
  border: none;
  font-size: 15px;
  margin: 20px 0 0 0;
  text-decoration: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const HrLine = styled.div`
  border-bottom: 1px solid ${colors.black8};
  border-left: 0;
  width: 100%;
  margin: 10px 0;
`;

const DatePickerCustom = styled(DatePicker)`
  && .react-date-picker__wrapper {
    display: none;
  }

  && .react-date-picker__calendar--open {
    margin-top: 20px;
    z-index: 3;
    position: absolute;
  }

  && .react-date-picker__calendar--closed {
    position: absolute;
  }

  && .react-calendar {
    border: 1px solid ${colors.black8};
    border-radius: 10px;
  }
`;

export default ReservationSelectionBar;
