import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  signInData: null,
  signUpData: null,
  loading: false,
};

export const getSignIn = createAsyncThunk("signInUp/getSignIn", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(ENDPOINT_URL + "/api/sign-in?locale=" + language);
    return res.data.data.attributes;
  } catch (err) {
    console.log(err);
  }
});

export const getSignUp = createAsyncThunk("signInUp/getSignUp", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(ENDPOINT_URL + "/api/sign-up?locale=" + language);
    return res.data.data.attributes;
  } catch (err) {
    console.log(err);
  }
});

export const signInUpSlice = createSlice({
  name: "signInUp",
  initialState,
  reducers: {},
  extraReducers: {
    [getSignIn.pending]: (state) => {
      state.loading = true;
    },
    [getSignIn.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.signInData = payload;
    },
    [getSignIn.rejected]: (state) => {
      state.loading = false;
    },

    [getSignUp.pending]: (state) => {
      state.loading = true;
    },
    [getSignUp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.signUpData = payload;
    },
    [getSignUp.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default signInUpSlice.reducer;
