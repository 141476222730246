import toast from "react-hot-toast";

export const handleToast = (msg, color) => {
  return toast(msg, {
    style: {
      borderRadius: "10px",
      background: color ?? "#333",
      color: "#fff",
    },
  });
};
