import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getFooter } from "../../services/Footer/footerSlice";
import { getWeather } from "../../services/Weather/weatherSlice";
import { colors } from "../../utils/colors";
import { FILE_SERVER_URL } from "../../utils/constants";
import { motion } from "framer-motion";

const Footer = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const footer = useSelector((state) => state.footer.data);
  const language = useSelector((state) => state.language);
  const weather = useSelector((state) => state.weather.data);

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getWeather());
  }, [dispatch, language]);

  return (
    <>
      {pathname !== "/user-space" &&
      pathname !== "/api/auth/google/callback" ? (
        <>
          {footer && (
            <Container>
              <FooterContainer>
                <SectionContainer>
                  <>
                    {footer.footerPartners.data.map((partner) => (
                      <motion.div
                        key={partner.id}
                        transition={{ delay: 0.2 }}
                        whileHover={{
                         
                          scale: 1.1,
                          
                        }}
                      >
                        <img
                          src={partner.attributes.url}
                          alt={partner.attributes.name}
                          height="34px"
                          style={{ marginBottom: 10 }}
                          key={partner.id}
                        />
                      </motion.div>
                    ))}
                  </>
                </SectionContainer>
                <SectionContainer>
                  {footer.footerLinks.map((page) => (
                    <LinkPage key={page.name} to={page.link}>
                      {page.name}
                    </LinkPage>
                  ))}
                </SectionContainer>
                {weather && (
                  <SectionContainer>
                    <SectionTitle>
                      {footer.footerWeather.weatherLabel}
                    </SectionTitle>
                    <SectionContent>
                      {footer.footerWeather.weatherContent}
                      {weather.current_weather.temperature}°C
                    </SectionContent>
                    <span style={{ fontWeight: 600, color: colors.green }}>
                      High {weather.daily.temperature_2m_max[0]}°C
                    </span>
                  </SectionContainer>
                )}
                <SectionContainer>
                  <SectionTitle>
                    {footer.footerContact.contactLabel}
                  </SectionTitle>
                  <SectionContent>
                    {footer.footerContact.contactAddress}
                  </SectionContent>
                  <SectionContent>
                    {footer.footerContact.contactEmail}
                  </SectionContent>
                  <SectionContent>
                    {footer.footerContact.contactPhone}
                  </SectionContent>
                </SectionContainer>
              </FooterContainer>
              <CopyRight>
                Copyright © {new Date().getFullYear()} Riadh Palms | Powered by{" "}
                <a
                  href="https://apeiron-tech.com/en/apeiron-technologies/"
                  target="_blank"
                >
                  <b>Apeiron Technologies</b>{" "}
                </a>
              </CopyRight>
            </Container>
          )}
        </>
      ) : null}
    </>
  );
};

const Container = styled.div`
  position: relative;
  flex-direction: column;
  text-align: center;
  margin: 10px;
`;

const CopyRight = styled.h5`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.black};
  margin: 28px 0;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${colors.black2};
  border-top: 1px solid ${colors.black4};
  margin: 12px 0;
  padding: 40px 50px;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
`;

const LinkPage = styled(Link)`
  text-decoration: none;
  color: ${colors.black};
`;

const SectionTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 16px 0;
`;

const SectionContent = styled.h3`
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  text-align: start;
`;

export default Footer;
