export const currOptions = [
  { value: "1", label: "Dinar Tunisien", code: "TND" },
  { value: "2", label: "Euro", code: "EUR" },
  { value: "3", label: "Dollar Américain", code: "USD" },
  { value: "4", label: "Dirham Marocain", code: "MAD" },
  { value: "5", label: "Couronne Suédoise", code: "SEK" },
  { value: "6", label: "Couronne Islandaise", code: "ISK" },
  { value: "7", label: "Franc Suisse", code: "CHF" },
  { value: "8", label: "Couronne Danoise", code: "DKK" },
  { value: "9", label: "Livre Sterling", code: "GBP" },
  { value: "10", label: "Rouble Russe", code: "RUB" },
  { value: "11", label: "Couronne Slovaque", code: "SKK" },
  { value: "12", label: "Couronne Tchèque", code: "CZK" },
  { value: "14", label: "Dollar Canadien", code: "CAD" },
  { value: "15", label: "Zloty Polonais", code: "PLN" },
  { value: "17", label: "Forint Hongrois", code: "HUF" },
  { value: "18", label: "Dinar Algérien", code: "DZD" },
  { value: "20", label: "Couronne Norvégienne", code: "NOK" },
  { value: "21", label: "Yen japonais", code: "JPY" },
  { value: "22", label: "Yuan Chinois", code: "CNY" },
  { value: "23", label: "Livre Libanaise", code: "LBP" },
  { value: "24", label: "Livre Egyptienne", code: "EGP" },
];

export const getClickToPayCurrency = (currencyCode) => {
  if (currencyCode === "USD") {
    return 840;
  } else if (currencyCode === "EUR") {
    return 978;
  } else {
    return 788;
  }
};
