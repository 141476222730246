import { ReactComponent as DateIcon } from "../../assets/svg/clock.svg";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import { ReactComponent as DateIconBlack } from "../../assets/svg/blackClock.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/twitter.svg";
import { ReactComponent as TriangleSvg } from "../../assets/svg/triangle.svg";
import { ReactComponent as BigArrow } from "../../assets/svg/bigArrow.svg";
import { ReactComponent as BigCircle } from "../../assets/svg/bigCircle.svg";
import { ReactComponent as FacebookLogin } from "../../assets/svg/facebookLogin.svg";
import { ReactComponent as GoogleLogin } from "../../assets/svg/googleLogin.svg";
import { ReactComponent as SignUpTriangle } from "../../assets/svg/signUpTriangle.svg";
import { ReactComponent as Evening } from "../../assets/svg/evening.svg";
import { ReactComponent as Entertainment } from "../../assets/svg/entertainment.svg";
import { ReactComponent as Stars } from "../../assets/svg/stars.svg";
import { ReactComponent as CoverBlogIcon } from "../../assets/svg/coverIcons/blogIcon.svg";
import { ReactComponent as CoverInstagramIcon } from "../../assets/svg/coverIcons/instagramIcon.svg";
import { ReactComponent as CoverFacebookIcon } from "../../assets/svg/coverIcons/facebookIcon.svg";
import { ReactComponent as CoverYoutubeIcon } from "../../assets/svg/coverIcons/youtubeIcon.svg";
import { ReactComponent as BedIcon } from "../../assets/svg/homeIcons/bedIcon.svg";
import { ReactComponent as SafeBoxIcon } from "../../assets/svg/homeIcons/safeBoxIcon.svg";
import { ReactComponent as BarIcon } from "../../assets/svg/homeIcons/barIcon.svg";
import { ReactComponent as ClimIcon } from "../../assets/svg/homeIcons/climIcon.svg";
import { ReactComponent as WifiIcon } from "../../assets/svg/homeIcons/wifiIcon.svg";
import { ReactComponent as Meeting } from "../../assets/svg/homeIcons/meeting.svg";
import { ReactComponent as PlayIcon } from "../../assets/svg/homeIcons/playIcon.svg";
import { ReactComponent as TelephoneIcon } from "../../assets/svg/homeIcons/telephoneIcon.svg";
import { ReactComponent as VerificationIcon } from "../../assets/svg/verification.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";
import { ReactComponent as SemiCircle } from "../../assets/svg/semiCircle.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menuIcon.svg";
import { ReactComponent as NightIcon } from "../../assets/svg/nightIcon.svg";
import { ReactComponent as PriceIcon } from "../../assets/svg/priceIcon.svg";
import { ReactComponent as CircleOutlineDecoration } from "../../assets/svg/circleOutlineDecoration.svg";
import { ReactComponent as BookIcon } from "../../assets/svg/bookIcon.svg";
import { ReactComponent as PalmIcon } from "../../assets/svg/palmIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/svg/sendIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/closeIcon.svg";
import { ReactComponent as QuotesIcon } from "../../assets/svg/quotes.svg";
import { ReactComponent as ConfettiIcon } from "../../assets/svg/confetti.svg";

export const ENDPOINT_URL =
  process.env.NODE_ENV == "production"
    ? "https://riadh-palms-back.apeiron-tech.dev"
    : "http://localhost:1337";
export const FILE_SERVER_URL =
  process.env.NODE_ENV == "production" ? "" : "http://localhost:1337";

export const VOUCHER_URL =
  process.env.NODE_ENV == "production"
    ? "https://www.hotelriadhpalms.com/voucher"
    : "http://localhost:3000/voucher";

export const ACCESS_TOKEN =
  "d4bbdf5ac37ab79d957040188ff974e29f33caa8593cc48fd102b12e554ab50b16bbf98e8c843a8a4c00fc49a52ae16bb29a01efcf0da3f0a8bef4cdddc9d37bbaae72b55dd9399a7a2b33849075b6b19cb80993089a5d0885bba8099ce7639ba9d1fc519e9a24b8e1efdd6195ce2bee1712024ed4ccd81ff3e227c748728131";
//export const ACCESS_TOKEN = f50bab02fb1e04048dfd258824ca1759faf6d7dcfd90366ea6f78c77c6861725dc8f575c935a35a0718f1b73a7722fc6984ddbf8fbb1fbf6060e59f0922b80b541aece6e1f01e16512619b8d3412f5729f9675aa3bbb734a75180bbce36b0df09dee3ed1ce655f2505c8bb6772087d88df89239f750df3e3fd6724b831fb5eda

export const MAP_EMBED =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12937.327382989646!2d10.6292466!3d35.8408657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13027584c6ffd8b9%3A0xeeb2b770842bea13!2sHotel%20Riadh%20Palms!5e0!3m2!1sfr!2stn!4v1669218109007!5m2!1sfr!2stn";

export const images = {
  riadhPalmsDarkLogo: require("../../assets/brand/logo_palm_dark.png"),
  riadhPalmsLightLogo: require("../../assets/brand/logo_palm_light.png"),
  instagramPalmLogo: require("../../assets/brand/instagram_palm_logo.png"),
  brewHouse: require("../../assets/images/brewhouse.png"),
  riadhPalmsCard: require("../../assets/images/riadhPalmsCard.png"),
  faqImg: require("../../assets/images/FAQs.png"),
  eDinarIcon: require("../../assets/images/cards/eDinar.png"),
  masterCardIcon: require("../../assets/images/cards/masterCard.png"),
  otherCardIcon: require("../../assets/images/cards/otherCard.png"),
  posteCardIcon: require("../../assets/images/cards/posteCard.png"),
  primeCardIcon: require("../../assets/images/cards/primeCard.png"),
  visaCardIcon: require("../../assets/images/cards/visaCard.png"),
  expiredIcon: require("../../assets/images/expired.png"),
  errorImg: require("../../assets/images/error-404.png"),
};

export const svg = {
  clock: DateIcon,
  eye: EyeIcon,
  blackClock: DateIconBlack,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  triangle: TriangleSvg,
  bigArrow: BigArrow,
  bigCircle: BigCircle,
  facebookLogin: FacebookLogin,
  googleLogin: GoogleLogin,
  signUpTriangle: SignUpTriangle,
  evening: Evening,
  entertainment: Entertainment,
  stars: Stars,
  coverBlogIcon: CoverBlogIcon,
  coverInstagramIcon: CoverInstagramIcon,
  coverFacebookIcon: CoverFacebookIcon,
  coverYoutubeIcon: CoverYoutubeIcon,
  bedIcon: BedIcon,
  safeBoxIcon: SafeBoxIcon,
  barIcon: BarIcon,
  climIcon: ClimIcon,
  wifiIcon: WifiIcon,
  meeting: Meeting,
  playIcon: PlayIcon,
  telephoneIcon: TelephoneIcon,
  verificationIcon: VerificationIcon,
  semiCircle: SemiCircle,
  menuIcon: MenuIcon,
  infoIcon: InfoIcon,
  nightIcon: NightIcon,
  priceIcon: PriceIcon,
  circleOutlineDecoration: CircleOutlineDecoration,
  bookIcon: BookIcon,
  palmIcon: PalmIcon,
  sendIcon: SendIcon,
  closeIcon: CloseIcon,
  quotesIcon: QuotesIcon,
  confettiIcon: ConfettiIcon,
};

export const getLanguageId = (language) => {
  if (language === "fr") {
    return 1;
  } else if (language === "en") {
    return 2;
  } else if (language === "de") {
    return 4;
  } else {
    return 2;
  }
};
