import React from "react";

const MenuIcon = ({ fillColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="17"
      viewBox="0 0 26 17"
    >
      <g id="menuIcon" transform="translate(-35 -36)">
        <rect
          id="Rectangle_72"
          data-name="Rectangle 72"
          width="26"
          height="3"
          rx="1.5"
          transform="translate(35 36)"
          fill={fillColor}
          opacity="0.65"
        />
        <rect
          id="Rectangle_73"
          data-name="Rectangle 73"
          width="26"
          height="3"
          rx="1.5"
          transform="translate(35 43)"
          fill={fillColor}
          opacity="0.65"
        />
        <rect
          id="Rectangle_74"
          data-name="Rectangle 74"
          width="26"
          height="3"
          rx="1.5"
          transform="translate(35 50)"
          fill={fillColor}
          opacity="0.65"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
