import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { colors } from "../../../../utils/colors";
import {
  VOUCHER_URL,
  getLanguageId,
  images,
  svg,
} from "../../../../utils/constants";
import { ContinueButton } from "../../../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { handleToast } from "../../../../utils/toast/toast";
import {
  getHotelBooking,
  initializeHotelBooking,
  updateBookingDetails,
} from "../../../../services/Reservation/hotelReservationSlice";
import { getClickToPayCurrency } from "../Currencies/CurrenciesOptions";

const Payment = ({
  firstName,
  lastName,
  emailAddress,
  country,
  phone,
  adultsCount,
  childrenCount,
  adultsNames,
  childrenNames,
  childrenAges,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const [radioBoxChecked, setRadioBoxChecked] = useState();
  const [refundable, setRefundable] = useState(true);
  const [total, setTotal] = useState(0.0);
  const [checkbox1, setChexbox1] = useState(false);
  const [checkbox2, setChexbox2] = useState(false);

  const [orderId, setOrderId] = useState("");
  const [formUrl, setFormUrl] = useState("");

  const hotelQuotes = useSelector(
    (state) => state.hotelReservation.hotelQuotes
  );
  const rooms = useSelector((state) => state.hotelReservation.quoteRoomIds);
  const reservationData = useSelector((state) => state.hotelReservation);

  const onChangeAttribute = (e) => {
    setRadioBoxChecked(e.target.value);
  };

  const generateRandomIntWithSameLength = () => {
    const min = 1000000000;
    const max = 9999999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const expandAges = (agesArray) => {
    const expandedAges = [];
    agesArray.forEach((age) => {
      const range = age.replaceAll("|", "-").split("-");
      if (range.length === 1) {
        expandedAges.push(range[0]);
      } else {
        range.forEach((num) => {
          expandedAges.push(num);
        });
      }
    });
    //console.log("expandedAges", expandedAges);
    return expandedAges;
  };

  const handleContinueButton = async () => {
    const bookingRooms = [];

    if (checkbox1 === true && checkbox2 === true) {
      const orderNumber = generateRandomIntWithSameLength();

      const amount =
        radioBoxChecked === "total"
          ? parseInt(total * 1000, 10)
          : parseInt((total / 2).toFixed(3) * 1000, 10);

      const apiUrl = `https://ipay.clictopay.com/payment/rest/register.do?amount=${amount}&currency=788&language=${
        language.lang == "fr" ? language.lang : "en"
      }&orderNumber=${orderNumber}&password=Ua8zEu5N5&returnUrl=${VOUCHER_URL}&userName=0362616518&pageView=DESKTOP`;

      //console.log("abc", childrenAges);

      await rooms.forEach((room) => {
        const customers = [];
        for (var i = 0; i <= room.customers.details.length - 1; i++) {
          if (room.customers.details[i][2] == "adult")
            customers.push({
              civility: "",
              firstName: room.customers.details[i][0],
              lastName: room.customers.details[i][1],
              age: "",
            });
          else {
            //console.log(expandAges(childrenAges)[i]);
            customers.push({
              civility: "",
              firstName: room.customers.details[i][0],
              lastName: room.customers.details[i][1],
              age: expandAges(childrenAges)[i] ?? 2,
            });
          }
        }

        // for (var i = 0; i <= childrenNames.length - 1; i++) {
        //   console.log("childrenName:", childrenNames);
        //   console.log("room", room);
        //   if (
        //     room.id === parseInt(childrenNames[j][2]) &&
        //     room.boardingId === parseInt(childrenNames[j][3])
        //   )
        //     customers.push({
        //       civility: "",
        //       firstName: childrenNames[j][0],
        //       lastName: childrenNames[j][1],
        //       age: expandAges(childrenAges)[j],
        //     });
        // }

        bookingRooms.push({
          id: parseInt(room.id),
          adult: parseInt(room.adult),
          child: parseInt(room.child.number),
          boardingId: parseInt(room.boardingId),
          customers: customers,
        });
      });

      // await rooms.forEach((room) => {
      //   const customers = [];
      //   console.log("room:", room);
      //   for (var i = 0; i <= adultsNames.length - 1 ; i++) {
      //     if (
      //       room.id === parseInt(adultsNames[i][2]) &&
      //       room.boardingId === parseInt(adultsNames[i][3])
      //     )
      //       customers.push({
      //         civility: "",
      //         firstName: adultsNames[i][0],
      //         lastName: adultsNames[i][1],
      //         age: "",
      //       });
      //   }

      //   for (var j = 0; j <= childrenNames.length - 1; j++) {
      //     console.log("childrenName:", childrenNames);
      //     console.log("room", room);
      //     if (
      //       room.id === parseInt(childrenNames[j][2]) &&
      //       room.boardingId === parseInt(childrenNames[j][3])
      //     )
      //       customers.push({
      //         civility: "",
      //         firstName: childrenNames[j][0],
      //         lastName: childrenNames[j][1],
      //         age: expandAges(childrenAges)[j],
      //       });
      //   }
      //   console.log(customers);

      //   bookingRooms.push({
      //     id: parseInt(room.id),
      //     adult: parseInt(room.adult),
      //     child: parseInt(room.child.number),
      //     boardingId: parseInt(room.boardingId),
      //     customers: customers,
      //   });
      // });

      const client = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: emailAddress,
      };

      dispatch(initializeHotelBooking());
      dispatch(
        updateBookingDetails({
          bookingDetails: {
            total: total,
            amountDue:
              radioBoxChecked === "total" ? 0 : total - (total / 2).toFixed(3),
            rooms: bookingRooms,
            client: client,
          },
        })
      );

      // dispatch(
      //   getHotelBooking({
      //     fromDate: hotelQuotes.HotelQuoteRES.FromDate,
      //     toDate: hotelQuotes.HotelQuoteRES.ToDate,
      //     quoteId: hotelQuotes.HotelQuoteRES.Hotel.QuoteId,
      //     rooms: bookingRooms,
      //     client: client,
      //     language: getLanguageId(language.lang.toString()),
      //   })
      // );

      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const { orderId, formUrl } = data;
          setOrderId(orderId);
          setFormUrl(formUrl);
          window.location.href = formUrl;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      handleToast(t("acceptTerms"));
    }
  };

  useEffect(() => {
    if (refundable === true) {
      setRadioBoxChecked("partial");
    } else {
      setRadioBoxChecked("total");
    }
  }, [refundable]);

  useEffect(() => {
    if (hotelQuotes) {
      // const rooms = Array.isArray(hotelQuotes.HotelQuoteRES.Hotel.rooms.room)
      //   ? hotelQuotes.HotelQuoteRES.Hotel.rooms.room
      //   : [hotelQuotes.HotelQuoteRES.Hotel.rooms.room];
      //console.log(rooms);

      let totalRate = 0;
      rooms.forEach((room) => {
        const roomRate = parseFloat(room.boarding.rate);
        totalRate += roomRate;
        if (room.boarding.nonRefundable !== "False") {
          setRefundable(false);
        }
      });
      setTotal(totalRate.toFixed(3));
    }
  }, [hotelQuotes]);

  return (
    <PaymentContainer>
      <CircleOutlineDecoration />
      <BackgroundContainer />
      <PaymentTitle>{t("payment")}</PaymentTitle>
      <PaymentCard>
        <RectangleDecoration />
        <CardTitle>{t("choosePaymentMethod")}</CardTitle>
        <CardContent>
          <CardItem>
            <ItemCards>
              <Image src={images.primeCardIcon} />
              <Image src={images.visaCardIcon} />
              <Image src={images.masterCardIcon} />
            </ItemCards>
            <ItemRadioBoxContainer>
              <RadioBox
                name="payment"
                checked={radioBoxChecked === "total"}
                value="total"
                onChange={onChangeAttribute}
                type="radio"
              />
              <ItemTitle>
                CIB/VISA/MasterCard:{" "}
                <span style={{ color: colors.pink }}>
                  [{t("totalToPay")}:{" "}
                  {(total * reservationData.currencyValue).toFixed(3)}{" "}
                  {reservationData.defaultCurrency.code}]
                </span>
              </ItemTitle>
            </ItemRadioBoxContainer>
            {refundable === true && (
              <ItemRadioBoxContainer>
                <RadioBox
                  name="payment"
                  checked={radioBoxChecked === "partial"}
                  value="partial"
                  onChange={onChangeAttribute}
                  type="radio"
                />
                <ItemTitle>
                  CIB/VISA/MasterCard:{" "}
                  <span style={{ color: colors.pink }}>
                    [{t("downPayment")} (50%):{" "}
                    {((total * reservationData.currencyValue) / 2).toFixed(3)}{" "}
                    {reservationData.defaultCurrency.code}]
                  </span>
                </ItemTitle>
              </ItemRadioBoxContainer>
            )}
          </CardItem>
          <label>
            <CheckBox
              type="checkbox"
              checked={checkbox1}
              onChange={() => setChexbox1(!checkbox1)}
            />
            <CheckBoxText>{t("checkBoxText1")}</CheckBoxText>
          </label>
          <label>
            <CheckBox
              type="checkbox"
              checked={checkbox2}
              onChange={() => setChexbox2(!checkbox2)}
            />
            <CheckBoxText>{t("checkBoxText2")}</CheckBoxText>
          </label>
          <ConditionsLink to={"/conditions"} target="_blank">
            {t("conditions")}
          </ConditionsLink>
        </CardContent>
      </PaymentCard>
      <ContinueButton onClick={handleContinueButton}>
        {t("continue")}
      </ContinueButton>
    </PaymentContainer>
  );
};

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 75px 35px;
  max-width: 620px;
  gap: 40px;
  align-self: center;
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: auto;
    align-self: auto;
    padding: 75px 80px;
  }
`;

const PaymentTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

const PaymentCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${colors.white};
  padding: 40px;
  border: 1px solid ${colors.black4};
  border-radius: 20px;
  box-shadow: 0px 10px 20px ${colors.black6};
  gap: 40px;
`;

const RectangleDecoration = styled.div`
  position: absolute;
  top: 0px;
  left: 40px;
  width: 50px;
  height: 6px;
  background-color: ${colors.pink};
`;

const CardTitle = styled.p`
  font-size: 22px;
  margin: 0;
  font-weight: 500;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.p`
  font-size: 16px;
  margin: 0;
`;

const ItemCards = styled.div`
  display: flex;
  position: relative;
  background-color: ${colors.black3};
  padding: 13px 20px;
  border: 1px solid ${colors.black6};
  border-radius: 10px;
  box-shadow: 0px 10px 20px ${colors.black6};
  gap: 10px;
  width: min-content;
`;

const Image = styled.img`
  height: 24px;
`;

const ItemRadioBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const RadioBox = styled.input`
  border: none;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  display: none;
  z-index: -1;
  top: 0;
  right: 0;
  width: 650px;
  height: 321px;
  background-color: ${colors.ocean8};

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`;

const CircleOutlineDecoration = styled(svg.circleOutlineDecoration)`
  position: absolute;
  top: 300px;
  right: 0;
`;

const CheckBox = styled.input`
  margin-right: 10px;
`;

const CheckBoxText = styled.label`
  font-size: 13px;
  color: ${colors.black75};
`;

const ConditionsLink = styled(Link)`
  width: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: ${colors.orange10};
  color: ${colors.orange};
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
`;

export default Payment;
