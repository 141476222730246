import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const postReservation = createAsyncThunk(
  "reservation/postReservation",
  async (reservationDetails) => {
    var language = JSON.parse(localStorage.getItem("language"));

    // Prepare the data to be sent in the POST request
    const reservationData = {
      data: {
        title: reservationDetails.title,
        subTitle: reservationDetails.subTitle,
        reservationDate: {
          dateLabel: reservationDetails.reservationDate.dateLabel,
          dateValue: reservationDetails.reservationDate.dateValue,
        },
        reservationDetail: [
          {
            title: reservationDetails.reservationDetail.total,
          },
          {
            title: reservationDetails.reservationDetail.duration,
          },
        ],
        status: reservationDetails.status,
        roomPicture: reservationDetails.roomPicture,
        user: reservationDetails.user,
      },
    };
    console.log("reserationData", reservationData);
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/reservations?populate=deep&locale=" + language,
        reservationData
      );
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getReservation = createAsyncThunk(
  "reservation/getReservation",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("userToken");
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/users/me?populate[0]=reservations.reservationDate,reservations.reservationDetail"
      );
      console.log(res.data.reservations);
      return res.data.reservations;
    } catch (err) {
      console.log(err);
    }
  }
);

export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {},
  extraReducers: {
    [getReservation.pending]: (state) => {
      state.loading = true;
    },
    [getReservation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getReservation.rejected]: (state) => {
      state.loading = false;
    },
    [postReservation.pending]: (state) => {
      state.loading = true;
    },
    [postReservation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [postReservation.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default reservationSlice.reducer;
