import { Navigate } from "react-router-dom";

export const ProtectedRouteWithUser = ({ destination, children }) => {
  if (localStorage.getItem("userToken")) {
    return <Navigate to={destination} replace />;
  }
  return children;
};

export const ProtectedRoute = ({ destination, children }) => {
  if (!localStorage.getItem("userToken")) {
    return <Navigate to={destination} replace />;
  }
  return children;
};
