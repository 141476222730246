import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";

import { getSecondaryMenu } from "../../../services/Menu/menuSlice";
import { colors } from "../../../utils/colors";
import { Link, useNavigate } from "react-router-dom";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import { useTranslation } from "react-i18next";
import { FILE_SERVER_URL, svg } from "../../../utils/constants";

const BodyHeader = ({ executeScroll, firstRef, secondRef, thirdRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const secondaryMenu = useSelector((state) => state.menu.secondaryMenu);

  const language = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getSecondaryMenu());
  }, [dispatch, language]);

  return (
    <NavShadow shadowColor={colors.black4}>
      <Nav>
        <motion.div
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <NavMenu>
            <ReservationButton to={"/reservation"}>
              {t("reservationTitle")}
            </ReservationButton>
            {secondaryMenu &&
              secondaryMenu
                .slice()
                .sort((a, b) => a.attributes.customId - b.attributes.customId)
                .map((item) =>
                  item.attributes.customId == 1 ? (
                    <NavLinkOffer
                      key={item.id}
                      colorOne={item.attributes.colorOne}
                      colorTwo={item.attributes.colorTwo}
                      onClick={() => navigate(item.attributes.url)}
                    >
                      {/* <svg.confettiIcon/> */}
                      {item.attributes.title}
                    </NavLinkOffer>
                  ) : (
                    <NavLink
                      key={item.id}
                      onClick={() => navigate(item.attributes.url)}
                    >
                      <Image
                        src={
                          FILE_SERVER_URL +
                          item.attributes.icon.data.attributes.url
                        }
                      />
                      <LinkStyle
                        colorOne={item.attributes.colorOne}
                        colorTwo={item.attributes.colorTwo}
                      >
                        {item.attributes.title}
                      </LinkStyle>
                    </NavLink>
                  )
                )}
          </NavMenu>
        </motion.div>
      </Nav>
    </NavShadow>
  );
};

const NavShadow = styled.div`
  background-color: ${colors.white};
  height: 90px;
  display: flex;
  flex: 1;
  box-shadow: 0px 6px 30px ${(props) => props.shadowColor};

  @media screen and (max-width: 1300px) {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const Nav = styled.nav`
  position: absolute;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  height: 90px;
  display: flex;
  flex: 1;
  margin: 0 10px;
  padding: 0 35px;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow-x: auto;

  @media screen and (min-width: 768px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 1300px) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
`;

const NavLink = styled.p`
  font-size: 15px;
  color: ${(props) => (props.activestyle ? colors.black : colors.lightBlack)};
  font-weight: ${(props) => (props.activestyle ? 600 : 400)};
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 20px;
  gap: 10px;
  cursor: pointer;
`;

const NavLinkOffer = styled.p`
  display: flex;
  align-items: center;
  font-size: 15px;
  border-radius: 10px;
  background: linear-gradient(
    to right,
    ${(props) => props.colorOne},
    ${(props) => props.colorTwo}
  );
 
  padding: 12px 26px;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 20px;
  gap: 10px;
`;

const NavMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const NavInstaLink = styled(Link)`
  font-size: 15px;
  font-weight: ${(props) => (props.activestyle ? 600 : 400)};
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 20px;
  gap: 10px;
  cursor: pointer;
`;

const NavInstaHref = styled.a`
  font-size: 15px;
  font-weight: ${(props) => (props.activestyle ? 600 : 400)};
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 20px;
  gap: 10px;
  cursor: pointer;
`;

const LinkStyle = styled.p`
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;

const ReservationButton = styled(Link)`
  font-size: 15px;
  border-radius: 10px;
  background: ${colors.gold};
  padding: 12px 26px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 20px;

  @media only screen and (min-width: 1300px) {
    background: #3fbbae;
  }
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

export default BodyHeader;
