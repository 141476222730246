import React from "react";

const ProfileIcon = ({ fillColor }) => {
  return (
    <svg
      id="Profile"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 20"
    >
      <path
        id="Path_28424"
        data-name="Path 28424"
        d="M16,16.33C16,19.619,11.434,20,8,20q-.519,0-1.022-.015a.717.717,0,0,1-.7-.738.737.737,0,0,1,.742-.7q.483.014.981.013c4.349,0,6.555-.751,6.555-2.233S12.349,14.075,8,14.075s-6.555.752-6.555,2.235c0,.485.173,1.188,1.675,1.694a.718.718,0,1,1-.464,1.359C.461,18.624,0,17.3,0,16.31c0-3.223,4.381-3.654,7.788-3.67h.592C10.653,12.651,16,12.852,16,16.33ZM8,0A5.353,5.353,0,1,1,2.612,5.354,5.376,5.376,0,0,1,8,0ZM8,1.437a3.916,3.916,0,1,0,3.942,3.917A3.934,3.934,0,0,0,8,1.437Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ProfileIcon;
