import { configureStore } from "@reduxjs/toolkit";
import allInclusiveSlice from "./AllInclusive/allInclusiveSlice";
import blogSlice from "./Blog/blogSlice";
import chatbotSlice from "./ChatBot/chatbotSlice";
import contactSlice from "./Contact/contactSlice";
import evaluationSlice from "./Evaluation/evaluationSlice";
import faqSlice from "./Faq/faqSlice";
import footerSlice from "./Footer/footerSlice";
import forgotResetPasswordSlice from "./ForgotResetPassword/forgotResetPasswordSlice";
import galleriesSlice from "./Gallery/gallerySlice";
import videosSlice from "./Gallery/videoSlice";
import genericPageSlice from "./GenericPage/genericPageSlice";
import homeSlice from "./Home/homeSlice";
import languageSlice from "./Language/languageSlice";
import menuSlice from "./Menu/menuSlice";
import hotelReservationSlice from "./Reservation/hotelReservationSlice";
import reservationSlice from "./Reservation/reservationSlice";
import restaurantsSlice from "./Restaurants/restaurantsSlice";
import roomSlice from "./Rooms/roomSlice";
import pagesSlice from "./Routes";
import sideBarMenuSlice from "./SideBarMenu/sideBarMenuSlice";
import signInUpSlice from "./SignInUp/signInUpSlice";
import situationSlice from "./Situation/situationSlice";
import socialMediaSlice from "./SocialMedia/socialMediaSlice";
import sportAndRecreationSlice from "./SportAndRecreation/sportAndRecreationSlice";
import testimonialSlice from "./Testimonial/testimonialSlice";
import userSlice from "./User/userSlice";
import vipCardSlice from "./VipCard/vipCardSlice";
import weatherSlice from "./Weather/weatherSlice";
import welcomeModalSlice from "./WelcomeModal/welcomeModalSlice";
import proSpaceSlice from "./ProSpace/proSpaceSlice";
import bestOffersSlice from "./BestOffers/bestOffersSlice";
import barsSlice from "./Bars/barsSlice";
import firstVisitModalSlice from "./FirstVisitModal/firstVisitModalSlice";
import installButtonSlice from "./InstallButton/installButtonSlice";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedHotelReservation = persistReducer(persistConfig, hotelReservationSlice);

export const store = configureStore({
  reducer: {
    faq: faqSlice,
    home: homeSlice,
    language: languageSlice,
    genericPage: genericPageSlice,
    pages: pagesSlice,
    socialMedia: socialMediaSlice,
    gallery: galleriesSlice,
    videos: videosSlice,
    sportAndRecreation: sportAndRecreationSlice,
    user: userSlice,
    reservation: reservationSlice,
    hotelReservation: persistedHotelReservation,
    evaluation: evaluationSlice,
    vipCard: vipCardSlice,
    sideBarMenu: sideBarMenuSlice,
    blog: blogSlice,
    restaurants: restaurantsSlice,
    bars: barsSlice,
    situation: situationSlice,
    contact: contactSlice,
    allInclusive: allInclusiveSlice,
    room: roomSlice,
    menu: menuSlice,
    weather: weatherSlice,
    signInUp: signInUpSlice,
    forgotResetPassword: forgotResetPasswordSlice,
    footer: footerSlice,
    testimonial: testimonialSlice,
    chatbot: chatbotSlice,
    welcomeModal: welcomeModalSlice,
    firstVisitModal: firstVisitModalSlice,
    proSpace: proSpaceSlice,
    bestOffers: bestOffersSlice,
    installButton: installButtonSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
