import styled from "styled-components";
import { colors } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const CancellationTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <thead>
        <TableHeader>
          <HeaderItem>{t("voucherSpecialConditions")} :</HeaderItem>
        </TableHeader>
      </thead>
      <tbody>
        <BodyTr>
          <BodyTd>{t("voucherCancellation", { data })}</BodyTd>
        </BodyTr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border: 1px solid ${colors.black15};
  margin: 10px 0;
  font-size: 14px;
`;

const TableHeader = styled.tr`
  color: white;
`;

const BodyTr = styled.tr`
  border-bottom: 1px solid ${colors.black15};
`;

const HeaderItem = styled.th`
  background-color: ${colors.black35};
  padding: 10px 20px;
  flex: 1;
`;

const BodyTd = styled.td`
  padding: 10px 20px;
`;

export default CancellationTable;
