import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        userSpace: "User Space",
        logout: "Log out",
        tapImage: "Tap the image to see the next one",
        minRead: "Min Read",
        contactUs: "Contact us at",
        shareOn: "Share on",
        all: "All",
        reservationTitle: "Reservation",
        reservationDescription:
          "Reservation History is the complete record of a customer's reservations, past and current.",
        vipTitle: "VIP Card",
        vipDescription:
          "provides you with its loyalty card, Riadh Palms Card. Accumulate points following each purchase in order to benefit from exceptional discounts for each order made.",
        congratulations: "Congratulations",
        youHave: "you have",
        points: "points",
        pointsDescription:
          "The points received are relative to the amount of each Reservation or each order.",
        evaluation: "Evaluation",
        goal: "Our goal: 100% authentic reviews",
        evaluationSubTitle: "What did you think of your recent accommodation ?",
        evaluations: "Evaluations",
        noEvaluations: "You have no evaluation..",
        noReservations: "You have no reservation",
        chooseOptionBeforeContinue:
          "Please choose an option before continuing.",
        paymentToast:
          "Please fill out all the information before confirming the payment.",
        welcomeBack: "Welcome Back ",
        acceptTerms: "Please accept the terms and conditions.",
        passwordDoNotMatch: "Passwords do not match",
        pleaseConfirmThisStepToContinue: "Please confirm this step to continue",
        newPasswordIsRequired: "New Password is required.",
        confirmNewPasswordIsRequired: "Confirm New Password is required.",
        emailAddressIsRequired: "Email address is required.",
        passwordIsRequired: "Password is required.",
        welcome: "Welcome ",
        toRiadhPalmsWebsite: " to Riadh Palms website.",
        pleaseAcceptTheTerms: "Please accept the Terms of Service to continue",
        mustFillInAll: "You must fill in all of the fields.",
        enterEvaluationTitle: "Please enter a title for this evaluation",
        enterCorrectPasswordInformation: "Enter correct password information..",
        successfullyUpdated: "🎉 Successfully updated!!",
        successfullyAdded: "🎉 Successfully added!!",
        successfullyDeleted: "🎉 Successfully deleted!!",
        emailSent:
          "An email has been sent to this email address, please check it",
        registrationPointsPart1: "Congratulations, you earned ",
        registrationPointsPart2: " Points for your registration",
        loggedOut: "You have been logged out!",
        testimonial: "Testimonial",
        testimonialSubtitle: "What our customers say",
        fabulous: "Fabulous",
        good: "Good",
        bad: "Bad",
        quiteWell: "Quite well",
        arrivalDate: "Of course! What is the arrival date?",
        departureDate: "What is the departure date?",
        people: "How many adults in the room",
        kids: "How many children in the room",
        rooms: "How many rooms do you want?",
        childrenAgeInfo: "We need to know how old your children are",
        done: "Confirm",
        roomSelection: "Choose your preferred room",
        personalInformation: "Fill in your personal details",
        confirmation: "Confirm your booking",
        detailsOfYourReservation: "Details of your reservation",
        chooseYourBookingDetails: "Choose Your Booking Details",
        bookNow: "Book now",
        enterYourDetails: "Enter your details",
        firstName: "First Name",
        lastName: "Last Name",
        emailAddress: "Email Address",
        enterValidEmail: "Please enter a valid email address",
        country: "Country",
        phoneNumber: "Phone Number",
        enterYourFirstName: "Enter your First Name",
        enterYourLastName: "Enter your Last Name",
        enterYourEmail: "Enter your Email Address",
        enterYourPhoneNumber: "Enter your Phone Number",
        continue: "Continue",
        arrivalLabel: "Arrival",
        leavingLabel: "Leaving",
        peopleLabel: "People",
        roomLabel: "Room",
        roomsLabel: "Rooms",
        totalToPay: "Total to pay",
        downPayment: "Down payment",
        total: "Total",
        payment: "Payment",
        choosePaymentMethod: "Choose payment method",
        checkBoxText1:
          "I agree to present the credit card used for payment if necessary.",
        checkBoxText2:
          "I accept the specific conditions of this reservation and the general conditions of sale and I adhere to them without any reservation.",
        conditions: "Conditions",
        listOfPeople: "List of people",
        adult: "Adult",
        child: "Child",
        adults: "Adults",
        children: "Children",
        enterFirstName: "Enter First Name",
        enterLastName: "Enter Last Name",
        recommandations: "Recommandations",
        arrivalTime: "Arrival Time",
        departureTime: "Departure Time",
        checkAvailability: "Check Availability",
        arrivalDateLabel: "Arrival Date",
        departureDateLabel: "Departure Date",
        detailsLabel: "Details",
        nights: "nights",
        for: "For",
        cancellationPolicy: "Free cancellation until",
        nonAnnulable: "Non-cancellable, non-refundable",
        noRoomsAvailable: "Sorry, no rooms available at the moment.",
        reservationSelection: "Choose your preferred dates",
        reservationBarAddress: "Sousse, Tunisia",
        search: "Search",
        avatarsTitle: "Your Avatar!",
        avatarsDesc: "Please select an avatar for your profile picture.",
        chambreIndisponible:
          "This room is no longer available during this period.",
        voucherCheckInFrom: "Check-in online from",
        voucherHotelCategory: "stars, Sousse, Tunisia",
        paymentRef: "Payment reference:",
        amountDue: "Amount due:",
        voucherFrom: "From :",
        voucherTo: "To :",
        voucherReference: "Affiliate reference :",
        voucherRefInfo:
          "Riadh Palms Web App (Affiliate: 1066 | Email: commercial2@hotelriadhpalms.com | Phone: )",
        voucherConfidentialCode: "Confidential code :",
        voucherPeopleList: "List of people / Rooming list:",
        voucherRooms: "Room(s) :",
        voucherAdults: "Adults :",
        voucherChildren: "Children :",
        voucherWith: "With :",
        voucherCustomerNames: "Customer names :",
        voucherRoomType: "Room types :",
        voucherDiscounts: "Discounts :",
        voucherArrangements: "Arrangements :",
        voucherSpecialConditions: "Special booking conditions",
        voucherCancellation: `You get FREE cancellation before {{data}}. Beyond this date, in case of cancellation or noshow, the hotel can charge up to the total amount of the reservation.`,
        voucherAdresse: "Address",
        voucherTelephone: "Phone",
        voucherFax: "Fax",
        voucherWebsite: "Website",
        voucherEmail: "Email",
        voucherPaimentMode: "Payment method",
        voucherBankCard: "Bank card (VISA | MASTERCARD | CIB)",
        voucherMerci:
          "Please plan to present the bank card used for payment if necessary.",
        print: "Print",
        selectCountry: "Select your country",
        errorMessage: "An error has occurred, please try again later..",
        goBackHome: "Return to home page",
      },
    },
    fr: {
      translation: {
        userSpace: "Espace Utilisateur",
        logout: "Se déconnecter",
        tapImage: "Appuyez sur l'image pour voir la suivante",
        minRead: "Min de lecture",
        contactUs: "Contactez-nous à",
        shareOn: "Partager sur",
        all: "Tout",
        reservationTitle: "Réservation",
        reservationDescription:
          "L'historique des réservations est l'enregistrement complet des réservations passées et actuelles d'un client.",
        vipTitle: "Carte VIP",
        vipDescription:
          "met à votre disposition sa carte de fidélité, Riadh Palms Card. Cumulez des points suite à chaque achat afin de bénéficier de remises exceptionnelles pour chaque commande effectuée.",
        congratulations: "Félicitations",
        youHave: "vous avez",
        points: "points",
        pointsDescription:
          "Les points reçus sont relatifs au montant de chaque Réservation ou de chaque commande.",
        evaluation: "Évaluation",
        goal: "Notre objectif : 100% d'avis authentiques",
        evaluationSubTitle: "Qu'avez-vous pensé(e) de votre logement récent ?",
        evaluations: "Évaluations",
        noEvaluations: "Vous n'avez pas d'évaluation..",
        noReservations: "Vous n'avez pas de réservation",
        chooseOptionBeforeContinue:
          "Veuillez choisir une option avant de continuer.",
        paymentToast:
          "Veuillez remplir toutes les informations avant de confirmer le paiement.",
        welcomeBack: "Content de te revoir ",
        acceptTerms: "Veuillez accepter les termes et conditions.",
        passwordDoNotMatch: "Les mots de passe ne correspondent pas",
        pleaseConfirmThisStepToContinue:
          "Veuillez confirmer cette étape pour continuer",
        newPasswordIsRequired: "Un nouveau mot de passe est requis.",
        confirmNewPasswordIsRequired:
          "La confirmation du nouveau mot de passe est requise.",
        emailAddressIsRequired: "Adresse e-mail est nécessaire.",
        passwordIsRequired: "Mot de passe requis.",
        welcome: "Bienvenu(e) ",
        toRiadhPalmsWebsite: " sur le site Web de Riadh Palms.",
        pleaseAcceptTheTerms:
          "Veuillez accepter les conditions d'utilisation pour continuer",
        mustFillInAll: "Vous devez remplir tous les champs.",
        enterEvaluationTitle: "Veuillez entrer un titre pour cette évaluation",
        enterCorrectPasswordInformation:
          "Entrez les informations de mot de passe correctes..",
        successfullyUpdated: "🎉 Mis à jour avec succés!!",
        successfullyAdded: "🎉 Ajouté avec succès!!",
        successfullyDeleted: "🎉 Supprimé avec succès!!",
        emailSent:
          "Un e-mail a été envoyé à cette adresse e-mail, veuillez le vérifier",
        registrationPointsPart1: "Félicitations, vous avez gagné(e) ",
        registrationPointsPart2: " points pour votre inscription",
        loggedOut: "Vous avez déconnecté(e)!",
        testimonial: "Témoignage",
        testimonialSubtitle: "Ce que disent nos clients",
        fabulous: "Fabuleux",
        good: "Bien",
        bad: "Mauvais",
        quiteWell: "Assez bien",
        arrivalDate: "Bien sûr! Quelle est la date d'arrivée ?",
        departureDate: "Quelle est la date de départ?",
        people: "Combien d'adultes dans la chambre",
        kids: "Combien d'enfants dans la chambre",
        rooms: "Combien de chambres voulez vous ?",
        childrenAgeInfo: "Nous devons savoir quel âge ont vos enfants",
        done: "Confirmer",
        roomSelection: "Choisissez votre chambre",
        personalInformation: "Ajoutez vos informations personnelles",
        confirmation: "Confirmez votre réservation",
        detailsOfYourReservation: "Détails de votre réservation",
        chooseYourBookingDetails: "Choisissez votre réservation",
        bookNow: "Réserver",
        enterYourDetails: "Entrez vos coordonnées",
        firstName: "Prénom",
        lastName: "Nom",
        emailAddress: "Adresse e-mail",
        enterValidEmail: "Veuillez entrer une adresse e-mail valide",
        country: "Pays",
        phoneNumber: "Numéro de téléphone",
        enterYourFirstName: "Entrez votre prénom",
        enterYourLastName: "Entrez votre nom",
        enterYourEmail: "Entrez votre adresse email",
        enterYourPhoneNumber: "Entez votre numéro de téléphone",
        continue: "Continuer",
        arrivalLabel: "Arrivée",
        leavingLabel: "Départ",
        peopleLabel: "Personnes",
        roomLabel: "Chambre",
        roomsLabel: "Chambres",
        totalToPay: "Total à payer",
        downPayment: "Acompte à payer",
        total: "Totale",
        payment: "Paiement",
        choosePaymentMethod: "Choisissez le mode de paiement",
        checkBoxText1:
          "J'accepte de présenter la carte bancaire ayant servi au paiement en cas de besoin.",
        checkBoxText2:
          "J'accepte les conditions spécifiques de cette réservation et les conditions générales de vente et j'y adhère sans aucune réserve.",
        conditions: "Conditions",
        listOfPeople: "Liste des personnes",
        adult: "Adulte",
        child: "Enfant",
        adults: "Adultes",
        children: "Enfants",
        enterFirstName: "Entrez prénom",
        enterLastName: "Entrez nom",
        recommandations: "Recommandations",
        arrivalTime: "Heure d'arrivée",
        departureTime: "Heure de départ",
        checkAvailability: "Voir Disponibilité",
        arrivalDateLabel: "Date d'arrivée",
        departureDateLabel: "Date de départ",
        detailsLabel: "Détails",
        nights: "nuits",
        for: "Pour",
        cancellationPolicy: "Annulation gratuite jusqu'au",
        nonAnnulable: "Non annulable, non remboursable",
        noRoomsAvailable: "Désolé, pas de chambres disponibles pour le moment.",
        reservationSelection: "Choisissez vos dates",
        reservationBarAddress: "Sousse, Tunisie",
        search: "Chercher",
        avatarsTitle: "Votre Avatar!",
        avatarsDesc:
          "Veuillez sélectionner un avatar pour votre photo de profil",
        chambreIndisponible:
          "Cette chambre n'est plus disponible pendant cette période.",
        voucherCheckInFrom: "Check-in en ligne à partir de",
        voucherHotelCategory: "étoiles, Sousse, Tunisie",
        paymentRef: "Référence de paiement:",
        amountDue: "Reste à payer:",
        voucherFrom: "A partir de :",
        voucherTo: "Jusqu'au :",
        voucherReference: "Référence affilié :",
        voucherRefInfo:
          "Riadh Palms Web App (Affilié : 1066 | Email : commercial2@hotelriadhpalms.com | Téléphone : )",
        voucherConfidentialCode: "Code confidentiel :",
        voucherPeopleList: "Liste des personnes / Rooming list:",
        voucherRooms: "Chambre(s) :",
        voucherAdults: "Adultes :",
        voucherChildren: "Enfants :",
        voucherWith: "Avec :",
        voucherCustomerNames: "Noms des clients :",
        voucherRoomType: "Types de chambres :",
        voucherDiscounts: "Réductions :",
        voucherArrangements: "Arrangements :",
        voucherSpecialConditions: "Conditions spéciales de réservation",
        voucherCancellation: `Vous bénéficiez de l'annulation GRATUITE avant le {{data}}. Au delà de cette date, en cas d'annulation ou de noshow, l'hôtel peut facturer jusqu'au montant total de la réservation.`,
        voucherAdresse: "Adresse",
        voucherTelephone: "Téléphone",
        voucherFax: "Fax",
        voucherWebsite: "Site Web",
        voucherEmail: "Email",
        voucherPaimentMode: "Mode de paiement",
        voucherBankCard: "Carte Bancaire (VISA | MASTERCARD | CIB)",
        voucherMerci:
          "Merci de prévoir de présenter la carte bancaire ayant servi aupaiement en cas de besoin.",
        print: "Imprimer",
        selectCountry: "Choisissez votre pays",
        errorMessage: "Une erreur est survenue merci de réessayer plus tard..",
        goBackHome: "Revenir à la page d'accueil",
      },
    },
    de: {
      translation: {
        userSpace: "Benutzerbereich",
        logout: "Ausloggen",
        tapImage: "Tippen Sie auf das Bild, um das nächste zu sehen",
        minRead: "Min. Lektüre",
        contactUs: "Kontaktiere uns unter",
        shareOn: "Teilen auf",
        all: "Alle",
        reservationTitle: "Reservierung",
        reservationDescription:
          "Der Reservierungsverlauf ist die vollständige Aufzeichnung vergangener und aktueller Reservierungen eines Kunden.",
        vipTitle: "VIP-Karte",
        vipDescription:
          "stellt Ihnen seine Treuekarte Riadh Palms Card zur Verfügung. Sammeln Sie nach jedem Einkauf Punkte, um bei jeder Bestellung von außergewöhnlichen Rabatten zu profitieren.",
        congratulations: "Glückwunsch",
        youHave: "du hast",
        points: "Punkte",
        pointsDescription:
          "Die erhaltenen Punkte beziehen sich auf den Betrag jeder Reservierung oder Bestellung.",
        evaluation: "Bewertung",
        goal: "Unser Ziel: 100 % authentische Bewertungen",
        evaluationSubTitle: "Wie fanden Sie Ihre letzte Unterkunft?",
        evaluations: "Bewertungen",
        noEvaluations: "Du hast keine Bewertung..",
        noReservations: "Sie haben keine Reservierung",
        chooseOptionBeforeContinue:
          "Bitte wählen Sie eine Option, bevor Sie fortfahren.",
        paymentToast:
          "Bitte füllen Sie alle Informationen aus, bevor Sie die Zahlung bestätigen.",
        welcomeBack: "Ich freue mich, Sie wiederzusehen ",
        acceptTerms:
          "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen.",
        passwordDoNotMatch: "Passwörter stimmen nicht überein",
        pleaseConfirmThisStepToContinue:
          "Bitte bestätigen Sie diesen Schritt, um fortzufahren",
        newPasswordIsRequired: "Ein neues Passwort ist erforderlich.",
        confirmNewPasswordIsRequired:
          "Eine Bestätigung des neuen Passworts ist erforderlich.",
        emailAddressIsRequired: "E-Mailadresse wird benötigt.",
        passwordIsRequired: "Passwort erforderlich.",
        welcome: "Willkommen",
        toRiadhPalmsWebsite: " auf der Website von Riadh Palms.",
        pleaseAcceptTheTerms:
          "Bitte akzeptieren Sie die Nutzungsbedingungen, um fortzufahren",
        mustFillInAll: "Sie müssen alle Felder ausfüllen.",
        enterEvaluationTitle:
          "Bitte geben Sie einen Titel für diese Bewertung ein",
        enterCorrectPasswordInformation:
          "Geben Sie die korrekten Passwortinformationen ein.",
        successfullyUpdated: "🎉 Erfolgreich geupdated!!",
        successfullyAdded: "🎉 Erfolgreich hinzugefügt!!",
        successfullyDeleted: "🎉 Erfolgreich gelöscht!!",
        emailSent:
          "An diese E-Mail-Adresse wurde eine E-Mail gesendet. Bitte überprüfen Sie diese",
        registrationPointsPart1: "Glückwunsch! Sie haben gewonnen",
        registrationPointsPart2: " Punkte für Ihre Anmeldung",
        loggedOut: "Sie haben die Leitung unterbrochen!",
        testimonial: "Zeugnis",
        testimonialSubtitle: "Was unsere Kunden sagen",
        fabulous: "Fabelhaft",
        good: "GUT",
        bad: "Schlecht",
        quiteWell: "Ziemlich gut",
        arrivalDate: "Natürlich! Was ist das Ankunftsdatum?",
        departureDate: "Was ist das Abreisedatum?",
        people: "Wie viele Erwachsene im Raum?",
        kids: "Wie viele Kinder im Zimmer?",
        rooms: "Wie viele Schlafzimmer möchten Sie?",
        childrenAgeInfo: "Wir müssen wissen, wie alt Ihre Kinder sind",
        done: "Bestätigen",
        roomSelection: "Wählen Sie Ihr Zimmer",
        personalInformation: "Fügen Sie Ihre persönlichen Daten hinzu",
        confirmation: "Bestätigen Sie Ihre Reservierung",
        detailsOfYourReservation: "Einzelheiten zu Ihrer Reservierung",
        chooseYourBookingDetails: "Wählen Sie Ihre Reservierung",
        bookNow: "Buchen",
        enterYourDetails: "Geben Sie Ihre Daten ein",
        firstName: "Vorname",
        lastName: "Nachname",
        emailAddress: "E-Mail-Adresse",
        enterValidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        country: "Land",
        phoneNumber: "Telefonnummer",
        enterYourFirstName: "Geben Sie Ihren Vornamen ein",
        enterYourLastName: "Geben Sie Ihren Nachnamen ein",
        enterYourEmail: "Geben Sie Ihre E-Mail-Adresse ein",
        enterYourPhoneNumber: "Geben Sie Ihre Telefonnummer ein",
        continue: "Weiter",
        arrivalLabel: "Ankunft",
        leavingLabel: "Abreise",
        peopleLabel: "Personen",
        roomLabel: "Zimmer",
        roomsLabel: "Zimmer",
        totalToPay: "Gesamtbetrag zu zahlen",
        downPayment: "Anzahlung zu zahlen",
        total: "Gesamt",
        payment: "Zahlung",
        choosePaymentMethod: "Zahlungsmethode auswählen",
        checkBoxText1:
          "Ich akzeptiere die Vorlage der Kreditkarte, die für die Zahlung verwendet wurde, falls erforderlich.",
        checkBoxText2:
          "Ich akzeptiere die speziellen Bedingungen dieser Reservierung sowie die Allgemeinen Geschäftsbedingungen und akzeptiere sie ohne Vorbehalt.",
        conditions: "Bedingungen",
        listOfPeople: "Liste der Personen",
        adult: "Erwachsener",
        child: "Kind",
        adults: "Erwachsene",
        children: "Kinder",
        enterFirstName: "Geben Sie den Vornamen ein",
        enterLastName: "Geben Sie den Nachnamen ein",
        recommandations: "Empfehlungen",
        arrivalTime: "Ankunftszeit",
        departureTime: "Abfahrtszeit",
        checkAvailability: "Verfügbarkeit prüfen",
        arrivalDateLabel: "Ankunftsdatum",
        departureDateLabel: "Abreisedatum",
        detailsLabel: "Details",
        nights: "Nächte",
        for: "Für",
        cancellationPolicy: "Kostenlose Stornierung bis zum",
        nonAnnulable: "Nicht stornierbar, nicht erstattbar",
        noRoomsAvailable: "Entschuldigung, derzeit keine Zimmer verfügbar.",
        reservationSelection: "Wählen Sie Ihre Buchung",
        reservationBarAddress: "Sousse, Tunesien",
        search: "Suchen",
        avatarsTitle: "Ihr Avatar!",
        avatarsDesc: "Bitte wählen Sie einen Avatar für Ihr Profilbild",
        chambreIndisponible:
          "Dieses Zimmer ist in diesem Zeitraum nicht verfügbar.",
        voucherCheckInFrom: "Online Check-in ab",
        voucherHotelCategory: "Sterne, Sousse, Tunesien",
        paymentRef: "Zahlungsreferenz:",
        amountDue: "Zu zahlender Betrag:",
        voucherFrom: "Von:",
        voucherTo: "Bis:",
        voucherReference: "Affiliate-Referenz:",
        voucherRefInfo:
          "Riadh Palms Web App (Affiliate: 1066 | E-Mail: commercial2@hotelriadhpalms.com | Telefon:)",
        voucherConfidentialCode: "Vertraulicher Code:",
        voucherPeopleList: "Liste der Personen / Zimmerliste:",
        voucherRooms: "Zimmer:",
        voucherAdults: "Erwachsene:",
        voucherChildren: "Kinder:",
        voucherWith: "Mit:",
        voucherCustomerNames: "Kundennamen:",
        voucherRoomType: "Zimmertypen:",
        voucherDiscounts: "Rabatte:",
        voucherArrangements: "Vereinbarungen:",
        voucherSpecialConditions: "Besondere Buchungsbedingungen",
        voucherCancellation:
          "Sie haben bis {{data}} kostenlos storniert. Danach kann das Hotel den Gesamtbetrag der Buchung berechnen, wenn Sie stornieren oder nicht erscheinen.",
        voucherAdresse: "Adresse",
        voucherTelephone: "Telefon",
        voucherFax: "Fax",
        voucherWebsite: "Website",
        voucherEmail: "E-Mail",
        voucherPaimentMode: "Zahlungsart",
        voucherBankCard: "Kreditkarte (VISA | MASTERCARD | CIB)",
        voucherMerci:
          "Bitte stellen Sie sicher, die für die Zahlung verwendete Kreditkarte bei Bedarf vorzulegen.",
        print: "Drucken",
        selectCountry: "Wählen Sie Ihr Land",
        errorMessage:
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
        goBackHome: "Zurück zur Startseite",
      },
    },
    ru: {
      translation: {
        userSpace: "Личный кабинет",
        logout: "Выйти",
        tapImage: "Нажмите на изображение, чтобы увидеть следующее",
        minRead: "Мин чтения",
        contactUs: "Свяжитесь с нами по адресу",
        shareOn: "Поделиться на",
        all: "Все",
        reservationTitle: "Бронирование",
        reservationDescription:
          "История бронирований - это полная запись о прошлых и текущих бронированиях клиента.",
        vipTitle: "VIP-карта",
        vipDescription:
          "Предоставляет вам нашу карту лояльности Riadh Palms Card. Накапливайте баллы за каждую покупку, чтобы получать уникальные скидки на каждый заказ.",
        congratulations: "Поздравляем",
        youHave: "у вас есть",
        points: "баллов",
        pointsDescription:
          "Баллы начисляются в зависимости от суммы каждого бронирования или заказа.",
        evaluation: "Оценка",
        goal: "Наша цель: 100% аутентичных отзывов",
        evaluationSubTitle: "Что вы думаете о вашем недавнем проживании?",
        evaluations: "Отзывы",
        noEvaluations: "У вас нет отзывов...",
        noReservations: "У вас нет бронирований",
        chooseOptionBeforeContinue:
          "Пожалуйста, выберите опцию, прежде чем продолжить.",
        paymentToast:
          "Пожалуйста, заполните все данные перед подтверждением оплаты.",
        welcomeBack: "Рады видеть вас снова,",
        acceptTerms: "Пожалуйста, примите условия и положения.",
        passwordDoNotMatch: "Пароли не совпадают",
        pleaseConfirmThisStepToContinue:
          "Пожалуйста, подтвердите этот шаг, чтобы продолжить.",
        newPasswordIsRequired: "Требуется новый пароль.",
        confirmNewPasswordIsRequired: "Требуется подтверждение нового пароля.",
        emailAddressIsRequired: "Требуется адрес электронной почты.",
        passwordIsRequired: "Требуется пароль.",
        welcome: "Добро пожаловать,",
        toRiadhPalmsWebsite: "на веб-сайт Riadh Palms.",
        pleaseAcceptTheTerms:
          "Пожалуйста, примите условия использования, чтобы продолжить.",
        mustFillInAll: "Вы должны заполнить все поля.",
        enterEvaluationTitle: "Пожалуйста, введите заголовок для этой оценки",
        enterCorrectPasswordInformation:
          "Введите правильную информацию о пароле.",
        successfullyUpdated: "🎉 Успешно обновлено!",
        successfullyAdded: "🎉 Успешно добавлено!",
        successfullyDeleted: "🎉 Успешно удалено!",
        emailSent:
          "На этот адрес электронной почты было отправлено сообщение. Пожалуйста, проверьте его.",
        registrationPointsPart1: "Поздравляем, вы заработали",
        registrationPointsPart2: " баллов за регистрацию",
        loggedOut: "Вы вышли из системы!",
        testimonial: "Отзыв",
        testimonialSubtitle: "Что говорят наши клиенты",
        fabulous: "Замечательно",
        good: "Хорошо",
        bad: "Плохо",
        quiteWell: "Довольно хорошо",
        arrivalDate: "Конечно! Какая дата прибытия?",
        departureDate: "Какая дата отъезда?",
        people: "Сколько взрослых в комнате?",
        kids: "Сколько детей в комнате?",
        rooms: "Сколько комнат вы хотите забронировать?",
        childrenAgeInfo: "Нам нужно знать возраст ваших детей.",
        done: "Подтвердить",
        roomSelection: "Выберите свою комнату",
        personalInformation: "Добавьте свою личную информацию",
        confirmation: "Подтвердите вашу бронь",
        detailsOfYourReservation: "Детали вашего бронирования",
        chooseYourBookingDetails: "Выберите детали вашего бронирования",
        bookNow: "Забронировать",
        enterYourDetails: "Введите ваши данные",
        firstName: "Имя",
        lastName: "Фамилия",
        emailAddress: "Адрес электронной почты",
        enterValidEmail:
          "Пожалуйста, введите действительный адрес электронной почты",
        country: "Страна",
        phoneNumber: "Номер телефона",
        enterYourFirstName: "Введите ваше имя",
        enterYourLastName: "Введите вашу фамилию",
        enterYourEmail: "Введите ваш адрес электронной почты",
        enterYourPhoneNumber: "Введите ваш номер телефона",
        continue: "Продолжить",
        arrivalLabel: "Прибытие",
        leavingLabel: "Отъезд",
        peopleLabel: "Люди",
        roomLabel: "Комната",
        roomsLabel: "Комнаты",
        totalToPay: "Итого к оплате",
        downPayment: "Аванс к оплате",
        total: "Итого",
        payment: "Оплата",
        choosePaymentMethod: "Выберите способ оплаты",
        checkBoxText1:
          "Я соглашаюсь предоставить кредитную карту, использованную для оплаты, при необходимости.",
        checkBoxText2:
          "Я соглашаюсь с конкретными условиями этого бронирования и общими условиями продажи и безусловно принимаю их.",
        conditions: "Условия",
        listOfPeople: "Список людей",
        adult: "Взрослый",
        child: "Ребенок",
        adults: "Взрослые",
        children: "Дети",
        enterFirstName: "Введите имя",
        enterLastName: "Введите фамилию",
        recommandations: "Рекомендации",
        arrivalTime: "Время прибытия",
        departureTime: "Время отъезда",
        checkAvailability: "Проверить наличие",
        arrivalDateLabel: "Дата прибытия",
        departureDateLabel: "Дата отъезда",
        detailsLabel: "Детали",
        nights: "ночи",
        for: "Для",
        cancellationPolicy: "Бесплатная отмена до",
        nonAnnulable: "Не подлежит отмене, не возвращается",
        noRoomsAvailable: "Извините, в данный момент нет доступных номеров.",
        reservationSelection: "Выберите ваши даты",
        reservationBarAddress: "Сусс, Тунис",
        search: "Поиск",
        avatarsTitle: "Ваш аватар!",
        avatarsDesc: "Пожалуйста, выберите аватар для вашей фотографии профиля",
        chambreIndisponible: "Этот номер больше не доступен на этот период.",
        voucherCheckInFrom: "Регистрация онлайн с",
        voucherHotelCategory: "звезды, Сусс, Тунис",
        paymentRef: "Номер платежа:",
        amountDue: "Сумма к оплате:",
        voucherFrom: "От:",
        voucherTo: "До:",
        voucherReference: "Аффилированный номер:",
        voucherRefInfo:
          "Веб-приложение Riadh Palms (Аффилированный: 1066 | Электронная почта: commercial2@hotelriadhpalms.com | Телефон:)",
        voucherConfidentialCode: "Конфиденциальный код:",
        voucherPeopleList: "Список людей / Список комнат:",
        voucherRooms: "Номер(а):",
        voucherAdults: "Взрослые:",
        voucherChildren: "Дети:",
        voucherWith: "С:",
        voucherCustomerNames: "Имена клиентов:",
        voucherRoomType: "Типы номеров:",
        voucherDiscounts: "Скидки:",
        voucherArrangements: "Договоренности:",
        voucherSpecialConditions: "Специальные условия бронирования",
        voucherCancellation:
          "Вы можете бесплатно отменить до {{data}}. После этой даты отмена или неприход могут повлечь выставление счета за полную стоимость бронирования.",
        voucherAdresse: "Адрес",
        voucherTelephone: "Телефон",
        voucherFax: "Факс",
        voucherWebsite: "Веб-сайт",
        voucherEmail: "Электронная почта",
        voucherPaimentMode: "Способ оплаты",
        voucherBankCard: "Банковская карта (VISA | MASTERCARD | CIB)",
        voucherMerci:
          "Пожалуйста, подготовьте карту, использованную для оплаты, при необходимости.",
        print: "Печать",
        selectCountry: "Выберите вашу страну",
        errorMessage:
          "Произошла ошибка, пожалуйста, повторите попытку позже...",
        goBackHome: "Вернуться на главную страницу",
      },
    },
  },
  lng: JSON.parse(localStorage.getItem("language")) || "fr",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
