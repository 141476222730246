import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getBestOffers = createAsyncThunk(
  "bestOffers/getBestOffers",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/best-offer?populate=deep&locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const bestOffersSlice = createSlice({
  name: "bestOffers",
  initialState,
  reducers: {},
  extraReducers: {
    [getBestOffers.pending]: (state) => {
      state.loading = true;
    },
    [getBestOffers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getBestOffers.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default bestOffersSlice.reducer;
