import { Helmet } from "react-helmet-async";
import { FILE_SERVER_URL } from "../constants";

const SeoHelmet = ({ props, url }) => {
  return (
    <Helmet>
      <title>{props.seo.metaTitle}</title>
      <meta name="description" content={props.seo.metaDescription} />
      <meta name="keywords" content={props.seo.keywords} />
      {/* Facebook tags */}
      {props.seo.metaSocial &&
        props.seo.metaSocial[0] &&
        props.seo.metaSocial[0].image.data && (
          <meta
            property="og:image"
            content={
              FILE_SERVER_URL + props.seo.metaSocial[0].image.data.attributes.url
            }
          />
        )}
      {props.seo.metaSocial && props.seo.metaSocial[0] && (
        <meta property="og:title" content={props.seo.metaSocial[0].title} />
      )}
      {props.seo.metaSocial && props.seo.metaSocial[0] && (
        <meta property="og:url" content={FILE_SERVER_URL + url} />
      )}

      {props.seo.metaSocial && props.seo.metaSocial[0] && (
        <meta
          property="og:description"
          content={props.seo.metaSocial[0].description}
        />
      )}
      {/* Twitter tags */}

      <meta name="twitter:creator" content="Riadh Palms Hotel" />
      {props.seo.metaSocial && props.seo.metaSocial[1] && (
        <meta name="twitter:title" content={props.seo.metaSocial[1].title} />
      )}
      {props.seo.metaSocial && props.seo.metaSocial[1] && (
        <meta
          name="twitter:description"
          content={props.seo.metaSocial[1].description}
        />
      )}
    </Helmet>
  );
};

export default SeoHelmet;
