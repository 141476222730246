import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "../../i18n";

import { updateLanguage } from "../../services/Language/languageSlice";
import { colors } from "../colors";

const LanguagesChatBot = ({ languageColor }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenu = (value) => {
    setOpen(false);
    localStorage.setItem("language", JSON.stringify(value));
    dispatch(updateLanguage(value));
    i18n.changeLanguage(value);
  };

  const options = [
    ["fr", "Français", "France"],
    ["en", "English", "United-Kingdom"],
    ["de", "German", "Germany"],
    ["ru", "Russian", "Russia"]
  ];

  return (
    <Dropdown languageColor={languageColor}>
      
      <button style={{marginBottom: "5px", height: "15px"}} onClick={handleOpen}>
        
        <MenuItemFlag
                src={require(`../../assets/flags/${JSON.parse(localStorage.getItem("language"))}.png`)}
                alt="Language Flag"
              />
      </button>
      {open ? (
        <Menu>
          {options.map((value, index) => (
            <MenuItem key={index} onClick={() => handleMenu(value[0])}>
              <MenuItemFlag
                src={require(`../../assets/flags/${value[0]}.png`)}
                alt="Language Flag"
              />
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </Dropdown>
  );
};

const Dropdown = styled.div`
  position: relative;

  && button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
`;

const Menu = styled.ul`
  position: absolute;
  right: 0px;
  list-style-type: none;
  border-radius: 8px;
  border: 1px solid ${colors.black4};
  box-shadow: none;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black75};
  background-color: white;
  padding: 0;
  z-index: 4;
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  gap: 10px;
  padding: 12px 20px;

  &:hover {
    background-color: ${colors.black3};
  }
`;

const MenuItemFlag = styled.img`
  top: 15px;
  height: 18px;
  width: 24px;
  border-radius: 4px;
`;

export default LanguagesChatBot;
