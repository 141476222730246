import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";

import { getMainMenu } from "../../services/Menu/menuSlice";
import { colors } from "../../utils/colors";
import { images, svg } from "../../utils/constants";
import Languages from "../../utils/languages";
import ProfileSettings from "../../utils/settings";
import PrimaryMenu from "./PrimaryMenu";
import { useState } from "react";
import PrimaryMobileMenu from "./PrimaryMobileMenu";

const PrimaryHeader = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  
  const language = useSelector((state) => state.language);
  const mainMenuSlice = useSelector((state) => state.menu.mainMenu);
  const [mainMenu, setMainMenu] = useState(mainMenuSlice)
  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  

  useEffect(() => {
    dispatch(getMainMenu());
  }, [dispatch, language]); 
  
  
  useEffect(() => {
    //console.log('mainMenu', mainMenuSlice)
    setMainMenu(mainMenuSlice)
  }, [mainMenuSlice, language]);
  
  
  return (
    <>
      {pathname !== "/" &&
      pathname !== "/user-space" &&
      pathname !== "/api/auth/google/callback" ? (
        <>
          {mainMenu && (
            <Menu
              right
              width={"264px"}
              isOpen={responsiveMenuVisible}
              onClose={() => setResponsiveMenuVisible(false)}
              customBurgerIcon={false}
              disableAutoFocus
            >
              <PrimaryMobileMenu
                pathname={pathname}
                mainMenu={mainMenu}
                mainColorValue={colors.black}
                secondaryColorValue={colors.lightBlack}
              ></PrimaryMobileMenu>
            </Menu>
          )}
          <NavShadow shadowColor={colors.black4}>
            <Nav>
              <Link to={"/"}>
                <NavLogo src={images.riadhPalmsDarkLogo} alt="Riadh Palms" />
              </Link>
              {mainMenu && (
                <NavElements>
                  <MenuMobileContainer>
                    <MenuIcon onClick={() => setResponsiveMenuVisible(true)}>
                      <svg.menuIcon />
                    </MenuIcon>
                  </MenuMobileContainer>
                  <MenuDesktopContainer>
                    <PrimaryMenu
                      pathname={pathname}
                      mainMenu={mainMenu}
                      mainColorValue={colors.black}
                      secondaryColorValue={colors.lightBlack}
                    ></PrimaryMenu>
                  </MenuDesktopContainer>
                  <Languages languageColor={colors.lightBlack50} />
                  <ProfileSettings />
                </NavElements>
              )}
            </Nav>
          </NavShadow>
        </>
      ) : null}
    </>
  );
};

const NavShadow = styled.div`
  background-color: ${colors.white};
  height: 90px;
  display: flex;
  flex: 1;
  margin: 0 50px;
  box-shadow: 0px 6px 30px ${(props) => props.shadowColor};
`;

const Nav = styled.nav`
  position: absolute;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  height: 90px;
  display: flex;
  flex: 1;
  margin: 0 10px;
  padding: 0 35px;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding: 0 50px;
  }
`;

const NavLogo = styled.img`
  height: 30px;
  @media screen and (min-width: 520px) {
    height: 45px;
  }
`;

const NavElements = styled.div`
  display: flex;
  align-items: center;
`;

const MenuIcon = styled.div`
  cursor: pointer;
  margin-top: 4px;
`;

const MenuMobileContainer = styled.div`
  display: flex;
  margin-right: 20px;
  @media screen and (min-width: 1300px) {
    display: none;
  }
`;

const MenuDesktopContainer = styled.div`
  display: none;
  @media screen and (min-width: 1300px) {
    display: flex;
  }
`;

export default PrimaryHeader;
