import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "../../../../utils/colors";
import { svg } from "../../../../utils/constants";
import ReservationSelectionBar from "./ReservationSelectionBar";

const ReservationSelection = ({ setStepSelected }) => {
  const { t } = useTranslation();

  return (
    <RoomSelectionContainer>
      <CircleOutlineDecoration />
      <BackgroundContainer />
      <RoomSelectionTitleRow>
        <RoomSelectionTitle> {t("reservationSelection")}</RoomSelectionTitle>
      </RoomSelectionTitleRow>
      <ReservationSelectionBar setStepSelected={setStepSelected} />
    </RoomSelectionContainer>
  );
};

const RoomSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 75px 35px;
  max-width: 620px;
  gap: 40px;
  align-self: center;
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: auto;
    align-self: auto;
    padding: 75px 80px;
  }
`;

const RoomSelectionTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  display: none;
  z-index: -1;
  top: 0;
  right: 0;
  width: 650px;
  height: 321px;
  background-color: ${colors.ocean8};

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`;

const CircleOutlineDecoration = styled(svg.circleOutlineDecoration)`
  position: absolute;
  top: 300px;
  right: 0;
`;

const RoomSelectionTitleRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default ReservationSelection;
