import React from "react";
import styled from "styled-components";
import { colors } from "../../../../utils/colors";
import { images } from "../../../../utils/constants";
import { motion } from "framer-motion";

const InitialAnimation = ({ isLoading }) => {
  return (
    <div>
      <motion.div
        initial={{ y: 0 }}
        animate={{ y: !isLoading ? "-100vh" : 0 }}
        transition={{ duration: 1 }}
        style={{ zIndex: 200, position: "relative" }}
      >
        <LoaderPage>
          <motion.div
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: !isLoading ? 30 : 0, opacity: !isLoading ? 0 : 1 }}
            transition={{ duration: 1 }}
          >
            <img src={images.riadhPalmsDarkLogo} height={65} alt="Logo" />
          </motion.div>
        </LoaderPage>
      </motion.div>
    </div>
  );
};

const LoaderPage = styled.div`
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
`;

export default InitialAnimation;
