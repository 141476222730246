import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import moment from "moment/moment";
import { motion } from "framer-motion";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ru";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { colors } from "../../../../utils/colors";
import PeopleModal from "./components/PeopleModal";
import { useDispatch, useSelector } from "react-redux";
import { updateReservationInfos } from "../../../../services/Reservation/hotelReservationSlice";
import { useTranslation } from "react-i18next";

const ReservationBarWeb = ({
  roomIds,
  adults,
  children,
  rooms,
  arrivalDate,
  departureDate,
  setRoomIds,
  setAdults,
  setChildren,
  setRooms,
  setArrivalDate,
  setDepartureDate,
}) => {
  const { t } = useTranslation();
  const [showContainer, setShowContainer] = useState(false);
  const [openPeopleModal, setOpenPeopleModal] = useState(false);

  const [openStartDate, setOpenStartDate] = useState(false);

  const [openEndDate, setOpenEndDate] = useState(false);

  const minDate = arrivalDate.toDate();
  minDate.setDate(minDate.getDate() + 1);

  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  useEffect(() => {
    if (roomIds.length > 0) {
      setRooms(roomIds.length);
      const totalAdults = roomIds.reduce((acc, person) => {
        return acc + person.adult;
      }, 0);
      setAdults(totalAdults);
      const totalChildren = roomIds.reduce((acc, person) => {
        return acc + person.child.number;
      }, 0);
      setChildren(totalChildren);
    }
  }, [roomIds]);

  useEffect(() => {
    const handleScroll = () => {
      const isAtTop = window.scrollY >= 800;
      setShowContainer(isAtTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const maxDepartureDate = moment(arrivalDate).add(15, "days").toDate();
  

  return (
    <>
      {showContainer && (
        <ReservationBarContainer shadowColor={colors.black4}>
          <PeopleModal
            openPeopleModal={openPeopleModal}
            setOpenPeopleModal={setOpenPeopleModal}
            roomIds={roomIds}
            setRoomIds={setRoomIds}
            adults={adults}
            setAdults={setAdults}
            children={children}
            setChildren={setChildren}
            rooms={rooms}
            setRooms={setRooms}
          />
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ReservationDetailsContainer>
              <InputContainer onClick={() => setOpenStartDate(true)}>
                <span
                  style={{
                    fontSize: "12px",
                    color: colors.black35,
                  }}
                >
                  {t("arrivalDateLabel")}
                </span>
                {moment(arrivalDate)
                  .locale(language.lang)
                  .format("dddd DD MMM YYYY")}
                <DatePickerCustom
                  isOpen={openStartDate}
                  minDate={new Date()}
                  value={arrivalDate.toDate()}
                  onCalendarClose={() => setOpenStartDate(false)}
                  locale={language.lang}
                  onChange={(e) => {
                    const selectedDate = moment(e);
                    if (selectedDate.isSameOrBefore(arrivalDate)) {
                      setDepartureDate(moment(arrivalDate).add(1, "day"));
                    }
                    setArrivalDate(selectedDate);
                  }}
                />
              </InputContainer>
              <HrLine />
              <InputContainer onClick={() => setOpenEndDate(true)}>
                <span
                  style={{
                    fontSize: "12px",
                    color: colors.black35,
                  }}
                >
                  {t("departureDateLabel")}
                </span>
                {moment(departureDate)
                  .locale(language.lang)
                  .format("dddd DD MMM YYYY")}
                <DatePickerCustom
                  isOpen={openEndDate}
                  minDate={minDate}
                  maxDate={maxDepartureDate}
                  value={departureDate.toDate()}
                  onCalendarClose={() => setOpenEndDate(false)}
                  locale={language.lang}
                  onChange={(e) => setDepartureDate(moment(e))}
                />
              </InputContainer>
              <HrLine />
              <InputContainer onClick={() => setOpenPeopleModal(true)}>
                <span
                  style={{
                    fontSize: "12px",
                    color: colors.black35,
                  }}
                >
                  {t("detailsLabel")}
                </span>
                <span>
                  <b>{adults}</b> {t("adults")}, <b>{children}</b>{" "}
                  {t("children")}, <b>{rooms}</b> {t("roomsLabel")}
                </span>
              </InputContainer>
              <SearchBtn
                disabled={roomIds.length < 1}
                onClick={() => {
                  
                  dispatch(
                    updateReservationInfos({
                      arrivalDate,
                      departureDate,
                      roomIds,
                    })
                  );
                  //setRoomIds([]);

                  navigate("/reservation");
                }}
              >
                {t("search")}
              </SearchBtn>
            </ReservationDetailsContainer>
          </motion.div>
        </ReservationBarContainer>
      )}
    </>
  );
};

const ReservationBarContainer = styled.div`
  display: none;
  justify-content: center;
  background-color: ${colors.white};
  box-shadow: 0px 6px 30px ${(props) => props.shadowColor};
  position: sticky;
  top: 0;
  z-index: 1;

  /* @media only screen and (min-width: 768px) { */
  @media only screen and (min-width: 1300px) {
    flex-direction: row;
    display: flex;
  }
`;

const ReservationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: ${colors.white};
  font-size: 16px;

  @media only screen and (min-width: 1300px) {
    flex-direction: row;
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.black};
  cursor: pointer;

  @media only screen and (min-width: 1300px) {
    align-items: flex-start;
  }
`;

const SearchBtn = styled.button`
  background-color: ${colors.pink};
  color: ${colors.white};
  border-radius: 10px;
  padding: 14px 26px;
  border: none;
  font-size: 15px;
  margin: 20px 0 0 0;
  text-decoration: none;
  cursor: pointer;

  @media only screen and (min-width: 1300px) {
    margin: 0 0 0 30px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const HrLine = styled.div`
  border-bottom: 1px solid ${colors.black8};
  border-left: 0;
  width: 100%;
  margin: 10px 0;

  @media only screen and (min-width: 1300px) {
    border-left: 1px solid ${colors.black8};
    border-bottom: 0;
    height: 26px;
    width: 0;
    margin: 0 25px;
  }
`;

const DatePickerCustom = styled(DatePicker)`
  && .react-date-picker__wrapper {
    display: none;
  }

  && .react-date-picker__calendar--open {
    margin-top: 20px;
    z-index: 3;
    position: absolute;
  }

  && .react-date-picker__calendar--closed {
    position: absolute;
  }

  && .react-calendar {
    border: 1px solid ${colors.black8};
    border-radius: 10px;
  }
  && .react-datepicker__day {
    &__monday {
      color: ${colors.green} !important;
    }
  }
`;

export default ReservationBarWeb;
