import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { colors } from "../../../utils/colors";
import { FILE_SERVER_URL, svg } from "../../../utils/constants";
import HomeGallery from "./HomeGallery";

const BeginExperience = ({ myRef, data }) => {
  //============= Animation ============//

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const animXY = {
    visible: { x: 0, opacity: 1, transition: { duration: 1.5 } },
    hidden: { x: -90, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  //============= End Animation ============//

  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  const openModal = () => {
    setModal(true);
  };

  const customStyles = {
    overlay: {
      zIndex: 3,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <>
      <Container ref={myRef}>
        <BeginContainer>
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={animXY}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <BeginSubTitle>
              {data.gallerySection.brandingMessage} <GreenLine />
            </BeginSubTitle>
            <BeginTitle>{data.gallerySection.message}</BeginTitle>
          </motion.div>
          <VideoAndImagesContainer>
            <VideoContainer
              url={
                FILE_SERVER_URL +
                data.gallerySection.videoPlaceholder.data.attributes.url
              }
            >
              <VideoFadeContainer onClick={openModal}>
                <svg.playIcon />
              </VideoFadeContainer>
            </VideoContainer>
            <ImagesContainer>
              <HomeGallery data={data.gallerySection} />
            </ImagesContainer>
          </VideoAndImagesContainer>
        </BeginContainer>
      </Container>
      <Modal
        isOpen={modal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <IFrame
          loading="lazy"
          src={data.gallerySection.videoUrl+'?autoplay=1&start=11'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          autoPlay
        ></IFrame>
      </Modal>
    </>
  );
};

const Container = styled.div`
  position: relative;
`;
const BeginContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  margin: 0 35px;

  @media only screen and (min-width: 768px) {
    padding: 0;
    margin: 50px 150px;
  }
`;

const GreenLine = styled.div`
  width: 0;
  height: 6px;
  background-color: ${colors.green};
  margin: 4px 16px;

  @media only screen and (min-width: 768px) {
    width: 70px;
  }
`;

const BeginSubTitle = styled.h1`
  display: flex;
  font-size: 20px;
  font-weight: 500;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const BeginTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin: 0;
`;

const VideoAndImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  align-items: center;
  justify-content: center;
  
  @media only screen and (min-width: 768px) {
    margin: 80px 0;
    flex-direction: row;
    align-items: initial;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
`;
const VideoContainer = styled.div`
  position: relative;
  display: flex;
  object-fit: cover;
  background-image: url(${(props) => props.url});
  background-position-y: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 284px;
  height: 180px;
  margin-bottom: 4px;
  border-radius: 10px;

  @media only screen and (min-width: 768px) {
    width: 690px;
    height: auto;
    margin-right: 4px;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 315px) {
    width: 246px;
  }
`;

const VideoFadeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${colors.black35};
  cursor: pointer;
  border-radius: 10px;
`;

const IFrame = styled.iframe`
  width: 300px;
  height: 320px;
  @media only screen and (min-width: 768px) {
    width: 620px;
    height: 360px;
  }
  @media only screen and (min-width: 992px) {
    width: 920px;
    height: 560px;
  }
`;

export default BeginExperience;
