import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  data: null,
  loading: false,
};

export const getTestimonials = createAsyncThunk(
  "testimonial/getTestimonials",
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        ENDPOINT_URL +
          "/api/evaluation-items?populate[0]=user.profile.picture&filters[isPublic][$eq]=true"
      );
      return res.data.data;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Testimonials
    [getTestimonials.pending]: (state) => {
      state.loading = true;
    },
    [getTestimonials.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getTestimonials.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default testimonialSlice.reducer;
