import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "react-dropdown/style.css";
import { useDispatch, useSelector } from "react-redux";

import { colors } from "../../../utils/colors";
import { images } from "../../../utils/constants";

import Languages from "../../../utils/languages";
import ProfileSettings from "../../../utils/settings";
import { getMainMenu } from "../../../services/Menu/menuSlice";
import PrimaryMenu from "../../../components/header/PrimaryMenu";
import MenuIcon from "./MenuIcon";
import InstallButton from "../../../components/installButton/InstallButton";

const CoverHeader = ({ setResponsiveMenuVisible }) => {
  const dispatch = useDispatch();
  const mainMenu = useSelector((state) => state.menu.mainMenu);
  const language = useSelector((state) => state.language);
  const deferredPrompt = useSelector((state) => state.installButton.deferredPrompt)

  useEffect(() => {
    dispatch(getMainMenu());
  }, [dispatch, language]);

  //============= Animation ============//

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const animXY = {
    visible: { y: 0, opacity: 1, transition: { duration: 1.5 } },
    hidden: { y: -90, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  //============= End Animation ============//

  return (
    <Nav>
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={animXY}
      >
        <Link to={"/"}>
          <NavLogo src={images.riadhPalmsLightLogo} alt="Riadh Palms" />
        </Link>
      </motion.div>
      {mainMenu && (
        <NavElements>
          <MenuMobileContainer>
            <MenuIconContainer onClick={() => setResponsiveMenuVisible(true)}>
              <MenuIcon fillColor={colors.white} />
            </MenuIconContainer>
          </MenuMobileContainer>
          <MenuDesktopContainer>
            <PrimaryMenu
              pathname={null}
              mainMenu={mainMenu}
              mainColorValue={colors.white}
              secondaryColorValue={colors.white}
            ></PrimaryMenu>
          </MenuDesktopContainer>
          <Languages languageColor={colors.white50} />
          <ProfileSettings />
          {deferredPrompt && ( <InstallButton deferredPrompt={deferredPrompt}/>)} 
        </NavElements>
      )}
    </Nav>
  );
};

const Nav = styled.nav`
  position: absolute;
  height: 90px;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;

  @media screen and (min-width: 768px) {
  }
`;

const NavLogo = styled.img`
  height: 25px;

  @media screen and (min-width: 400px) {
    height: 30px;
  }
  @media screen and (min-width: 520px) {
    height: 45px;
  }
`;

const NavElements = styled.div`
  display: flex;
  align-items: center;
`;

const MenuIconContainer = styled.div`
  cursor: pointer;
  margin-top: 4px;
`;

const MenuMobileContainer = styled.div`
  display: flex;
  margin-right: 20px;
  @media screen and (min-width: 1300px) {
    display: none;
  }
`;

const MenuDesktopContainer = styled.div`
  display: none;
  @media screen and (min-width: 1300px) {
    display: flex;
  }
`;

export default CoverHeader;
