import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL } from "../../utils/constants";

const initialState = {
  sideBarMenuData: null,
  logoutData: null,
  loading: false,
};

export const getSideBarMenu = createAsyncThunk(
  "sideBarMenu/getSideBarMenu",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/user-space-menu?locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getLogout = createAsyncThunk("sideBarMenu/getLogout", async () => {
  var language = JSON.parse(localStorage.getItem("language"));
  axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  try {
    const res = await axios.get(ENDPOINT_URL + "/api/log-out?locale=" + language);
    return res.data.data.attributes;
  } catch (err) {
    console.log(err);
  }
});

export const sideBarMenuSlice = createSlice({
  name: "sideBarMenu",
  initialState,
  reducers: {},
  extraReducers: {
    [getSideBarMenu.pending]: (state) => {
      state.loading = true;
    },
    [getSideBarMenu.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sideBarMenuData = payload;
    },
    [getSideBarMenu.rejected]: (state) => {
      state.loading = false;
    },

    [getLogout.pending]: (state) => {
      state.loading = true;
    },
    [getLogout.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.logoutData = payload;
    },
    [getLogout.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default sideBarMenuSlice.reducer;
