import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, ENDPOINT_URL} from "../../utils/constants";

const initialState = {
  resetLabels: null,
  forgotLabels: null,
  forgotData: null,
  loading: false,
};

export const getResetPasswordLabels = createAsyncThunk(
  "forgotResetPassword/getResetPasswordLabels",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/reset-password?locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getForgotPasswordLabels = createAsyncThunk(
  "forgotResetPassword/getForgotPasswordLabels",
  async () => {
    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.get(
        ENDPOINT_URL + "/api/forgot-password?locale=" + language
      );
      return res.data.data.attributes;
    } catch (err) {
      console.log(err);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotResetPassword/forgotPassword",
  async (data, { rejectWithValue }) => {
    const email = {
      email: data.email,
    };

    var language = JSON.parse(localStorage.getItem("language"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
    try {
      const res = await axios.post(
        ENDPOINT_URL + "/api/auth/forgot-password?locale=" + language,
        email
      );
      return res.data.data.attributes;
    } catch (err) {
      if (err.response && err.response.data.error) {
        return rejectWithValue(err.response.data.error.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const forgotResetPasswordSlice = createSlice({
  name: "forgotResetPassword",
  initialState,
  reducers: {},
  extraReducers: {
    //getResetPasswordLabels
    [getResetPasswordLabels.pending]: (state) => {
      state.loading = true;
    },
    [getResetPasswordLabels.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resetLabels = payload;
    },
    [getResetPasswordLabels.rejected]: (state) => {
      state.loading = false;
    },

    //getForgotPasswordLabels
    [getForgotPasswordLabels.pending]: (state) => {
      state.loading = true;
    },
    [getForgotPasswordLabels.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.forgotLabels = payload;
    },
    [getForgotPasswordLabels.rejected]: (state) => {
      state.loading = false;
    },

    //getForgotPassword
    [forgotPassword.pending]: (state) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.forgotData = payload;
    },
    [forgotPassword.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default forgotResetPasswordSlice.reducer;
